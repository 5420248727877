<xui-icon
  class="info-flag per-part"
  iconKey="Tooltip.Information"
  [size]="IconSize.S"
  [xuiTooltip]="createFixtureHintTooltip"
></xui-icon>

<xui-tooltip #createFixtureHintTooltip>
  <div>
    <p>{{boxDimensions.name}}:</p>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_LENGTH | translate}}: <strong>{{boxDimensions.lengthX}} mm</strong><br>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_WIDTH | translate}}: <strong>{{boxDimensions.widthY}} mm</strong><br>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_HEIGHT | translate}}: <strong>{{boxDimensions.heightZ}} mm</strong><br>
    {{translations.FIXTURE_MATE.BOX_DIMENSIONS.FLANGE_WIDTH_A | translate}}: <strong>{{boxDimensions.flangeWidthA}} mm</strong><br>
    {{translations.FIXTURE_MATE.BOX_DIMENSIONS.FLANGE_WIDTH_B | translate}}: <strong>{{boxDimensions.flangeWidthB}} mm</strong><br>
    {{translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL | translate}}: <strong>{{material}}</strong><br>
    {{translations.FIXTURE_MATE.BOX_DIMENSIONS.THICKNESS | translate}}: <strong>{{boxDimensions.thickness}}</strong><br>
  </div>
</xui-tooltip>
