import { Component, EventEmitter, Output } from '@angular/core';
import { DialogHostedView, DialogResult, DialogReturnType } from '@trumpf-xguide/xguide';
import { Part } from '../../../../../../shared';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-add-example-part-dialog',
  templateUrl: './add-example-part-dialog.component.html',
  styleUrls: ['./add-example-part-dialog.component.scss'],
})
export class AddExamplePartDialogComponent implements DialogHostedView<Part[], Part> {
  public readonly uiElementIds = UiElementIds;
  public parts: Part[] = [];
  public selectedPart?: Part;

  @Output() closeDialog = new EventEmitter<DialogReturnType<Part>>();

  constructor(public translations: TranslationHelper) {}

  public initDialog(parts: Part[]): void {
    this.parts = parts;
  }

  public selectPart(part: Part): void {
    this.selectedPart = part;
  }

  public resetSelection(): void {
    this.selectedPart = undefined;
  }

  public create(): void {
    this.closeDialog.emit({ result: DialogResult.Ok, payload: this.selectedPart });
  }

  public cancel(): void {
    this.closeDialog.emit({ result: DialogResult.Cancel });
  }
}
