import { Component, EventEmitter } from '@angular/core';
import { PartCalculation, WeldingTechnology } from '@shared/types';
import { DialogHostedView, DialogResult, DialogReturnType, WizardResult } from '@trumpf-xguide/xguide';
import { Tab } from '../../../shared/components/tabs/types';
import { TranslationHelper } from '../../../shared/helpers/translation.helpers';
import { PreviewFeaturesService } from '../../../shared/services/preview-features/preview-features.service';
import { createEmptyPartCalculation } from '../../utility/factories';

@Component({
  templateUrl: './calculation-wizard.component.html',
  styleUrls: ['./calculation-wizard.component.scss'],
})
export class CalculationWizardComponent
  implements DialogHostedView<PartCalculation, PartCalculation>
{
  closeDialog = new EventEmitter<DialogReturnType<PartCalculation>>();

  public WeldingTechnologies: Record<string, WeldingTechnology> = {
    arc: 'arc',
    laser: 'laser',
  };

  public readonly tabs: Tab[] = [
    { label: this.translations.CALCULATOR.WIZARD.TAB_GENERAL.TITLE },
    { label: this.translations.CALCULATOR.WIZARD.TAB_LASER.TITLE },
    { label: this.translations.CALCULATOR.WIZARD.TAB_ARC.TITLE },
  ];
  public active: Tab = this.tabs[0];
  public calculation: PartCalculation = createEmptyPartCalculation('');

  constructor(private translations: TranslationHelper, public flags: PreviewFeaturesService) {}

  public async initDialog(initialData: PartCalculation): Promise<void> {
    this.calculation = initialData;
  }

  public setActive(tab: Tab): void {
    this.active = tab;
  }

  public onFinishWizard(result: WizardResult): void {
    const dialogResult = result === 'finished' ? DialogResult.Ok : DialogResult.Cancel;
    this.closeDialog.emit({ result: dialogResult, payload: this.calculation });
  }
}
