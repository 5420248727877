import { ComponentShortTag, MachineConfigComponentItem } from '../../../types';

export function getComponentItem(
  shortTag: ComponentShortTag,
  items: MachineConfigComponentItem[],
): MachineConfigComponentItem {
  const item = items.find((i) => getShortComponentItemTag(i.tag) === shortTag);

  if (!item) {
    throw new Error(`Could not find component with short-tag "${shortTag}"`);
  }

  return item;
}

/** Returns a short configuration item name from long */
export function getShortComponentItemTag(longTag: string): string {
  // Long tag contains string like 'first.second.optionName'. We need only last 'optionName'
  const tagsPath = longTag.split('.');
  if (tagsPath.length === 0) {
    return longTag;
  }

  return tagsPath[tagsPath.length - 1].toLowerCase();
}
