<xui-dialog-container [closable]='true' class="container" (cancel)="cancel()">
  <div class="content">
    <span class="title">{{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_TITLE | translate }}</span>

    <span class="message">{{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_PARAGRAPH_ONE | translate }}
      <a [href]="translations.FIXTURE_MATE.UPLOAD_AGREEMENT_CONDITIONS_URL | translate" target="_blank">
        {{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_CONDITIONS | translate }}
      </a>

      {{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_PARAGRAPH_TWO | translate }}

      <a [href]="translations.FIXTURE_MATE.UPLOAD_AGREEMENT_POLICY_URL | translate" target="_blank">
        {{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_POLICY | translate }}
      </a>
    </span>
    <span class="message">{{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_PARAGRAPH_THREE | translate }}</span>
    <xui-button
      data-ngtx="privacy-upload-agreement-button"
      class="close-button"
      xuiClickUsageTracking="{{ uiElementIds.FIXTURE_MATE_UPLOAD_AGREEMENT_OK }}"
      (click)="ok()"
    >{{ translations.FIXTURE_MATE.UPLOAD_AGREEMENT_BUTTON | translate }}</xui-button>
  </div>
</xui-dialog-container>

