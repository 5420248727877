import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BoxDimensions, FixtureMateDto, Media, MediaType } from '@shared';
import { DialogRef, DialogResult, DialogService, LocalStorageService } from '@trumpf-xguide/xguide';
import * as FileSaver from 'file-saver';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDropZoneComponent } from '../../../shared/file-drop/components/file-drop-zone/file-drop-zone.component';
import { TranslationHelper } from '../../../shared/helpers';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { BoxDimensionsComponent } from '../../components/box-dimensions/box-dimensions.component';
import { DataPrivacyUploadAgreementComponent } from '../../components/data-privacy-upload-agreement/data-privacy-upload-agreement.component';
import { FixtureMateService } from '../../services/fixture-mate.service';

const USER_DATA_PRIVACY_CONSENT_KEY = 'weldguide:fixture-mate-data-privacy-consent';

@Component({
  templateUrl: './fixture-mate-view.component.html',
  styleUrls: ['./fixture-mate-view.component.scss'],
})
export class FixtureMateViewComponent implements OnInit, OnDestroy {
  // TODO: While we have some problems with the drop zone, we just not show it
  public ShowDropZone = false;
  public readonly uiElementIds = UiElementIds;
  public fixtures$: Observable<FixtureMateDto[] | undefined>;
  public backendError$ = this.fixtureMateService.backendError$;
  public fixtureMateVideo$: Observable<Media | null | undefined> = of(undefined);
  @ViewChild('dropZone') dropZoneRef?: FileDropZoneComponent;
  private downloadSubscription: Subscription | undefined = undefined;
  private dataPrivacyRef: DialogRef<any>;
  private boxDimensionsRef: DialogRef<BoxDimensions>;
  private isUserAuthenticated: boolean;
  private authenticationSubscription: Subscription | undefined = undefined;
  private selectedFixtureSubject = new Subject<FixtureMateDto>();
  public selectedFixture$ = this.selectedFixtureSubject.asObservable();

  constructor(
    public translations: TranslationHelper,
    private fixtureMateService: FixtureMateService,
    private translate: TranslateService,
    private localStorage: LocalStorageService,
    private dialog: DialogService,
    private authService: AuthService,
  ) {
    this.authenticationSubscription = this.authService.isAuthenticated$.subscribe(
      (authenticated) => (this.isUserAuthenticated = authenticated),
    );
  }

  ngOnInit(): void {
    this.fixtures$ = this.isUserAuthenticated ? this.fixtureMateService.getAllFixtures() : of([]);
    this.fixtureMateVideo$ = this.translate.stream(this.translations.FIXTURE_MATE.VIDEO_URL).pipe(
      map((url) => ({
        type: MediaType.VIDEO,
        src: url,
      })),
    );
  }

  public async createNewFixture(): Promise<void> {
    if (this.isUserAuthenticated) {
      const userConsent = await this.isUserConsent();
      if (userConsent) {
        this.dropZoneRef?.showFileDialog();
      }
    } else {
      this.authService.login();
    }
  }

  public async createExampleBox(): Promise<void> {
    if (this.isUserAuthenticated) {
      this.boxDimensionsRef = this.dialog.openWithRef(BoxDimensionsComponent, undefined);

      const dialogResult = await this.boxDimensionsRef.result;

      if (dialogResult.result === DialogResult.Ok && dialogResult.payload) {
        this.fixtures$ = this.fixtureMateService.createNewBoxDimensions(dialogResult.payload);
      }
    } else {
      this.authService.login();
    }
  }

  public async createFromStepModel(stepFile: File): Promise<void> {
    if (this.isUserAuthenticated) {
      const userConsent = await this.isUserConsent();
      if (userConsent) {
        this.fixtures$ = this.fixtureMateService.createNewFixture(stepFile);
      }
    } else {
      this.authService.login();
    }
  }

  public async fixtureSelected(fixtureRow: FixtureMateDto) {
    this.selectedFixtureSubject.next(fixtureRow);
  }

  public async downloadCad(cadUrl: string) {
    const fileName = this.getFileNameFromDownloadUri(cadUrl);
    this.downloadSubscription = this.fixtureMateService
      .downloadCad(cadUrl)
      .subscribe((data: Blob) => {
        FileSaver.saveAs(data, fileName);
      });
  }

  public async deleteCad(partName: string) {
    this.fixtures$ = this.fixtureMateService.deleteFixture(partName);
  }

  ngOnDestroy(): void {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
    }
    this.dataPrivacyRef?.close({ result: DialogResult.Cancel });
    this.boxDimensionsRef?.close({ result: DialogResult.Cancel });
  }

  private async isUserConsent(): Promise<boolean> {
    const localStorageValue = this.localStorage.get(USER_DATA_PRIVACY_CONSENT_KEY);
    // If user already gave his consent, there should be a parameter in the local storage
    if (localStorageValue) {
      return true;
    }

    // If not, let's ask user
    const userConsent = await this.isUserConsentDataPrivacy();
    if (userConsent) {
      // Store the consent in the Local storage
      this.localStorage.set(USER_DATA_PRIVACY_CONSENT_KEY, 'true');
      return true;
    }

    return false;
  }

  private async isUserConsentDataPrivacy(): Promise<boolean> {
    this.dataPrivacyRef = this.dialog.openWithRef(DataPrivacyUploadAgreementComponent, undefined);

    const dialogResult = await this.dataPrivacyRef.result;

    return dialogResult.result !== DialogResult.Cancel;
  }

  private getFileNameFromDownloadUri(downloadUri: string): string {
    const fileNameParamName = 'fileName=';
    const queryParams = downloadUri
      .substring(downloadUri.lastIndexOf('/') + 1)
      .replace('?', '')
      .split('&');
    const fileNameParam = queryParams.find((p) => p.includes(fileNameParamName));
    if (fileNameParam) {
      return decodeURIComponent(fileNameParam.replace(fileNameParamName, ''));
    }
    return 'unknown.step';
  }
}
