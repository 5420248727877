<div class="item-header">
  <div class="headline">
    <span>{{ machineConfiguration?.machineModelName }}</span>
    <span>&nbsp;</span>
    <span>|</span>
    <span>{{ machineConfiguration?.id }}</span>
  </div>
  <div class="actions">
    <xui-button
      *ngIf="machineConfiguration.machineModelName === 'TruLaser Weld 5000'"
      class="edit-button"
      xuiClickUsageTracking="{{ uiElementIds.CONFIGURATOR_EDIT }}"
      iconKey="Pencil"
      type="secondary"
      data-ngtx="edit-button"
      (click)="edit.emit(machineConfiguration?.id)"
    ></xui-button>
    <xui-button
      class="remove-button"
      xuiClickUsageTracking="{{ uiElementIds.CONFIGURATOR_REMOVE }}"
      iconKey="Paperbasket"
      type="secondary"
      (click)="remove.emit(machineConfiguration?.id)"
    ></xui-button>
  </div>
</div>
<hr class="small" />

<lsb-configurator-list-item-container
  [captionLabels]="layoutOptionLabels"
  [resolveResourceUrl]="layoutResourceUrl.bind(this)"
  [isAuthenticated]="isAuthenticated$ | async"
  [isConfirmedCustomer]="isConfirmedCustomer$ | async"
  (requireAuthentication)="login()"
></lsb-configurator-list-item-container>
<hr class="small" />

<lsb-configurator-list-item-container
  [captionLabels]="opticCaptionLabels"
  [resolveResourceUrl]="opticResourceUrl.bind(this)"
  [isAuthenticated]="isAuthenticated$ | async"
  [isConfirmedCustomer]="isConfirmedCustomer$ | async"
  (requireAuthentication)="login()"
></lsb-configurator-list-item-container>
<hr />
