import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CalculatorSettings,
  PartCalculation,
  PartCalculationChartPoint,
  PartCalculationParameters,
  WeldingTechnology,
} from '@shared/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TranslationHelper } from '../../../../shared/helpers';
import { PreviewFeaturesService } from '../../../../shared/services/preview-features/preview-features.service';
import { PartCalculationRow } from '../../../components/part-calculation-table/part-calculation-table.component';
import { CalculationUpdateNotification, UpdatedCalculationField } from '../../../types';

@Component({
  selector: 'lsb-calculation-details',
  templateUrl: './calculation-details.component.html',
  styleUrls: ['./calculation-details.component.scss'],
})
export class CalculationDetailsComponent implements OnInit {
  @Input() calculationResult: Observable<PartCalculationRow>;
  @Input() currentSettings: Observable<CalculatorSettings>;
  @Output() calculationChange = new EventEmitter<CalculationUpdateNotification>();
  @Output() previewClick = new EventEmitter<PartCalculation>();

  public parameters$: Observable<PartCalculationParameters>;
  public calculation$: Observable<PartCalculation>;
  public totalParts$: Observable<string>;

  public arcLineColor = '#687FC3';
  public laserColor = '#90B15F';

  constructor(public translations: TranslationHelper, public flags: PreviewFeaturesService) {}

  ngOnInit() {
    this.parameters$ = this.calculationResult.pipe(
      filter((result): result is PartCalculationRow => result != null),
      map((result) => result.calculation.parameters),
    );

    this.calculation$ = this.calculationResult.pipe(map((result) => result?.calculation));

    this.totalParts$ = this.calculation$.pipe(
      map((calc) => calc?.configuration?.basics?.totalPieces?.toString() ?? '0'),
    );
  }

  select(method: WeldingTechnology, result: PartCalculationRow): void {
    result.calculation.parameters = {
      ...result.calculation.parameters,
      selectedTechnology: method,
    };
    this.notifyUpdate(result, 'SelectedWeldingTechnologyAndAmount');
  }

  updateMtpLoading(method: WeldingTechnology, result: PartCalculationRow, state: boolean): void {
    const objectToUpdate = result.calculation.parameters[method];
    result.calculation.parameters[method] = { ...objectToUpdate, parallelLoading: state };

    this.notifyUpdate(result, 'MtpLoading');
  }

  updateRobotWelding(state: boolean, result: PartCalculationRow): void {
    result.calculation.parameters.arc = {
      ...result.calculation.parameters.arc,
      robotWelding: state,
    };

    this.notifyUpdate(result, 'RobotWelding');
  }

  handleChartSelectPoint(result: PartCalculationRow, point: PartCalculationChartPoint) {
    result.calculation.configuration.basics = {
      ...result.calculation.configuration.basics,
      totalPieces: point.selectedPartsCount,
    };

    this.select(point.selectedTechnology, result);
  }

  private notifyUpdate({ calculation }: PartCalculationRow, updatedField: UpdatedCalculationField) {
    this.calculationChange.emit({ calculation, updatedField });
  }
}
