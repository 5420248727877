<ng-container *ngIf="calculation$ | async as calculation">
  <div class="horizontal title">
    <div class="part-info">
      <!-- 3d model viewing is a preview-feature, remove ngIf when the feature is considered stable: -->
      <ng-container *ngIf="flags.enablePreviewFeatures$ | async as enablePreviewFeatures">
        <img
          *ngIf="enablePreviewFeatures && calculation.configuration.basics.cadModelPreview"
          class="model-preview non-draggable"
          [src]="calculation.configuration.basics.cadModelPreview"
          (click)="previewClick.emit(calculation)"
        />
      </ng-container>
      <h2 class="name">{{ calculation.name }}</h2>
    </div>

    <xui-caption-label
      [caption]="translations.CALCULATOR.TOTAL_PARTS | translate"
      [label]="totalParts$ | async"
      [highlightOnUpdate]="true"
      highlightDuration="1s"
      class="total-parts"
    ></xui-caption-label>
  </div>

  <div class="horizontal content">
    <ng-container *ngIf="calculationResult | async as result">
      <ng-container *ngIf="parameters$ | async as parameters">
        <ng-container *ngIf="currentSettings | async as settings">
          <div class="chart">
            <lsb-part-calculation-chart
              [laserLineColor]="laserColor"
              [arcLineColor]="arcLineColor"
              [method]="parameters.selectedTechnology"
              [currentPartsCount]="calculation.configuration.basics.totalPieces"
              [calculation]="calculation"
              [currentSettings]="settings"
              (selectPoint)="handleChartSelectPoint(result, $event)"
            ></lsb-part-calculation-chart>
          </div>

          <div class="stacked welding-methods">
            <lsb-radio-group (selectionChange)="select($event, result)">
              <lsb-welding-method-costs-summary-item
                numberFormat
                method="laser"
                [lineColor]="laserColor"
                [title]="translations.CALCULATOR.CALCULATION_DETAILS.LASER_WELDING | translate"
                [calculationResult]="calculationResult"
                [checked]="parameters.selectedTechnology === 'laser'"
                [parameters]="parameters.laser"
                (mainTimeParallelLoadingChange)="updateMtpLoading('laser', result, $event)"
              ></lsb-welding-method-costs-summary-item>
              <lsb-welding-method-costs-summary-item
                numberFormat
                method="arc"
                [lineColor]="arcLineColor"
                [title]="translations.CALCULATOR.CALCULATION_DETAILS.ARC_WELDING | translate"
                [calculationResult]="calculationResult"
                [checked]="parameters.selectedTechnology === 'arc'"
                [parameters]="parameters.arc"
                (mainTimeParallelLoadingChange)="updateMtpLoading('arc', result, $event)"
                (robotWeldingChange)="updateRobotWelding($event, result)"
              ></lsb-welding-method-costs-summary-item>
            </lsb-radio-group>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
