<div class="option-content">
  <div class="checkbox-and-icon">
    <xui-checkbox
      [checked]="componentItem.isChecked"
      (checkedChange)="componentItemCheckedChanged($event)"
      [disabled]="!componentItem.isEnabled"
      ><div class="checkbox-row">
        <div class="item-image">
          <img class="image non-draggable" [src]="componentItem.tag | componentTagToImage" />
        </div>
        {{ componentItem.tag | translate }}
      </div>
    </xui-checkbox>
    <xui-icon iconKey="Open.Dialog" class="link" (click)="navigateOption($event)"></xui-icon>
  </div>
  <lsb-switch
    *ngIf="componentItem.position"
    [checked]="componentItem.position === MachineConfigPosition.RIGHT"
    [disabled]="!componentItem.isEnabled || !componentItem.isChecked"
    (checkedChange)="switcherCheckedChanged($event)"
  ></lsb-switch>
</div>
<hr />
