import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { capitalCase } from 'capital-case';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { FileReaderService } from '../../../file-drop/services/file-reader/file-reader.service';
import { CadApiConversionResult, StlModel } from '../../types';
import { convertBase64ToBlob } from '../../utility/base64-to-blob';
import { CadModelServiceInterface } from './cad-model-service.interface';

@Injectable({ providedIn: 'root' })
export class CadModelService implements CadModelServiceInterface {
  constructor(private http: HttpClient, private fileReader: FileReaderService) {}

  /**
   *  TODO: Use backend `/cad-model-converter` instead of `environment.cadApiUrl + '/convert'`
   *  Work Item 73724:  https://dev.azure.com/TrumpfCorp/WeldGuide/_boards/board/t/WeldGuide%20Team/Backlog%20items/?workitem=73724
   */
  public convertStepToStl(stepFile: File): Observable<StlModel> {
    return this.fileReader.readBinary(stepFile).pipe(
      switchMap((fileContent) =>
        this.http.post<CadApiConversionResult>(
          environment.cadApiUrl + '/convert',
          {
            file: {
              name: 'result.stp',
              data: btoa(fileContent),
            },
            format: '.STL',
          },
          {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
            },
          },
        ),
      ),
      map((response) => convertBase64ToBlob(response.data, 'model/stl')),
      switchMap((blob) => this.fileReader.getDataUri(blob)),
      map((dataUrl) => ({ dataUrl, name: this.tryExtractName(stepFile) })),
    );
  }

  private tryExtractName(stepFile: File): string | undefined {
    if (!stepFile.name) {
      return undefined;
    }
    const name = stepFile.name.trim();
    const withoutExtension = name.replace(/\.ste?p/i, '');
    return capitalCase(withoutExtension);
  }
}
