import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslationHelper } from '../../helpers/translation.helpers';

@Component({
  selector: 'lsb-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnChanges {
  @Input() checked = false;
  @Input() disabled = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  public text = this.computeText(this.checked);

  constructor(private translations: TranslationHelper) {}

  ngOnChanges() {
    this.text = this.computeText(this.checked);
  }

  public onChange() {
    if (this.disabled) {
      return;
    }

    const checkedState = !this.checked;
    this.checked = checkedState;
    this.text = this.computeText(checkedState);
    this.checkedChange.emit(checkedState);
  }

  private computeText(checked: boolean) {
    return checked
      ? this.translations.CONFIGURATOR.LIST_ITEM.RIGHT
      : this.translations.CONFIGURATOR.LIST_ITEM.LEFT;
  }
}
