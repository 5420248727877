import {
  DeskRef,
  MachineConfigComponentItem,
  MachineConfigDesk,
  MachineConfigPosition,
  RobotType,
} from '../../../types';
import { CabinPlateState } from './cabin-plate-state';
import { OptionActionType } from './option-action-type';
import { OptionRule } from './option-rule';

export function getDefaultValueSetterForTinyCabinsWithShortBack(robotType: RobotType) {
  return (
    dkp: MachineConfigComponentItem,
    dt: MachineConfigComponentItem,
    rc: MachineConfigComponentItem,
    rw: MachineConfigComponentItem,
    wp: MachineConfigComponentItem,
    desk: DeskRef,
    cabinPlateState: CabinPlateState,
  ) => {
    dkp.isEnabled = true;
    dkp.isChecked = false;
    dkp.position = MachineConfigPosition.LEFT;

    dt.isEnabled = true;
    dt.isChecked = false;
    dt.position = MachineConfigPosition.RIGHT;

    rc.isEnabled = robotType !== 'REGULAR';
    rc.isChecked = false;
    rc.position = MachineConfigPosition.RIGHT;

    rw.isEnabled = false;
    rw.isChecked = false;
    rw.position = MachineConfigPosition.RIGHT;

    wp.isEnabled = false;
    wp.isChecked = false;
    wp.position = undefined;

    desk.value = MachineConfigDesk.DemellerPlate;
    cabinPlateState.demellerPlateEnabled = true;
    cabinPlateState.zeroPointClampingSystemEnabled = false;
  };
}

export const getRulesForTinyCabins = (robotType: RobotType): OptionRule[] => [
  {
    tag: 'dkp',
    actions: [
      {
        action: OptionActionType.MoveLeft,
        affects: [
          { tag: 'dt', actionToApply: OptionActionType.MoveRight },
          { tag: 'rc', actionToApply: OptionActionType.MoveRight },
          { tag: 'rw', actionToApply: OptionActionType.MoveRight },
        ],
      },
      {
        action: OptionActionType.MoveRight,
        affects: [
          { tag: 'dt', actionToApply: OptionActionType.MoveLeft },
          { tag: 'rc', actionToApply: OptionActionType.MoveLeft },
          { tag: 'rw', actionToApply: OptionActionType.MoveLeft },
        ],
      },
      {
        action: OptionActionType.Check,
        affects: [
          { tag: 'dt', actionToApply: OptionActionType.Disable },
          { tag: 'dt', actionToApply: OptionActionType.Uncheck },
          { tag: 'rc', actionToApply: OptionActionType.Disable },
          { tag: 'rc', actionToApply: OptionActionType.Uncheck },
          { radioButton: 'zeroPoint-clamping-system', actionToApply: OptionActionType.Enable },
        ],
      },
      {
        action: OptionActionType.Uncheck,
        affects: [
          { tag: 'dt', actionToApply: OptionActionType.Enable },
          {
            tag: 'rc',
            actionToApply: OptionActionType.Enable,
            blockedBy: [{ condition: () => robotType === 'REGULAR' }],
          },
          {
            radioButton: 'zeroPoint-clamping-system',
            actionToApply: OptionActionType.Disable,
            blockedBy: [
              { tag: 'dt', optionState: OptionActionType.Check },
              { tag: 'rc', optionState: OptionActionType.Check },
              { tag: 'rw', optionState: OptionActionType.Check },
            ],
          },
        ],
      },
    ],
  },
  {
    tag: 'dt',
    actions: [
      {
        action: OptionActionType.MoveLeft,
        affects: [
          { tag: 'dkp', actionToApply: OptionActionType.MoveRight },
          { tag: 'rw', actionToApply: OptionActionType.MoveLeft },
          { tag: 'rc', actionToApply: OptionActionType.MoveLeft },
        ],
      },
      {
        action: OptionActionType.MoveRight,
        affects: [
          { tag: 'dkp', actionToApply: OptionActionType.MoveLeft },
          { tag: 'rw', actionToApply: OptionActionType.MoveRight },
          { tag: 'rc', actionToApply: OptionActionType.MoveRight },
        ],
      },
      {
        action: OptionActionType.Check,
        affects: [
          { tag: 'rc', actionToApply: OptionActionType.Uncheck },
          { tag: 'rc', actionToApply: OptionActionType.Disable },
          { tag: 'dkp', actionToApply: OptionActionType.Uncheck },
          { tag: 'dkp', actionToApply: OptionActionType.Disable },
          { radioButton: 'zeroPoint-clamping-system', actionToApply: OptionActionType.Enable },
        ],
      },
      {
        action: OptionActionType.Uncheck,
        affects: [
          {
            tag: 'rc',
            actionToApply: OptionActionType.Enable,
            blockedBy: [{ condition: () => robotType === 'REGULAR' }],
          },
          { tag: 'dkp', actionToApply: OptionActionType.Enable },
          {
            radioButton: 'zeroPoint-clamping-system',
            actionToApply: OptionActionType.Disable,
            blockedBy: [
              { tag: 'dkp', optionState: OptionActionType.Check },
              { tag: 'rw', optionState: OptionActionType.Check },
              { tag: 'rc', optionState: OptionActionType.Check },
            ],
          },
        ],
      },
    ],
  },
  {
    tag: 'rc',
    actions: [
      {
        action: OptionActionType.MoveLeft,
        affects: [
          { tag: 'dkp', actionToApply: OptionActionType.MoveRight },
          { tag: 'rw', actionToApply: OptionActionType.MoveLeft },
          { tag: 'dt', actionToApply: OptionActionType.MoveLeft },
        ],
      },
      {
        action: OptionActionType.MoveRight,
        affects: [
          { tag: 'dkp', actionToApply: OptionActionType.MoveLeft },
          { tag: 'rw', actionToApply: OptionActionType.MoveRight },
          { tag: 'dt', actionToApply: OptionActionType.MoveRight },
        ],
      },
      {
        action: OptionActionType.Check,
        affects: [
          { tag: 'dkp', actionToApply: OptionActionType.Uncheck },
          { tag: 'dkp', actionToApply: OptionActionType.Disable },
          { tag: 'dt', actionToApply: OptionActionType.Uncheck },
          { tag: 'dt', actionToApply: OptionActionType.Disable },
          { radioButton: 'zeroPoint-clamping-system', actionToApply: OptionActionType.Enable },
        ],
      },
      {
        action: OptionActionType.Uncheck,
        affects: [
          { tag: 'dkp', actionToApply: OptionActionType.Enable },
          {
            tag: 'dt',
            actionToApply: OptionActionType.Enable,
          },
          {
            radioButton: 'zeroPoint-clamping-system',
            actionToApply: OptionActionType.Disable,
            blockedBy: [
              { tag: 'dkp', optionState: OptionActionType.Check },
              { tag: 'rw', optionState: OptionActionType.Check },
            ],
          },
        ],
      },
    ],
  },
];
