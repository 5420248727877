import { WeldingTechnology } from '@shared/types';
import { Storable } from '../../shared/data-persistence/types';
import { TranslationKeys } from '../../shared/helpers';
import { NozzleType } from '../mapper/nozzle-type';
import { OpticalType } from '../mapper/optical-type';
import {
  MachineConfigDesk,
  MachineConfigPosition,
  MachineConfiguration,
  WeldingMachineModel,
} from '../types';

const COMPONENT_TAGS = TranslationKeys.CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM;

/**
 * Copies the given machine configuration object deeply and returns the copy.
 * @param machine The machine configuration to return a copy of.
 */
export function copyOfMachine(machine: MachineConfiguration): MachineConfiguration;
export function copyOfMachine(
  machine: Omit<MachineConfiguration, 'id'>,
): Omit<MachineConfiguration, 'id'>;
export function copyOfMachine(
  machine: Storable<MachineConfiguration>,
): Storable<MachineConfiguration> {
  const copy = { ...machine };
  copy.cabinOptions = { ...machine.cabinOptions };
  copy.componentOptions = {
    ...machine.componentOptions,
    componentItems: machine.componentOptions.componentItems.map((c) => ({ ...c })),
  };
  copy.opticNozzleOptions = {
    ...machine.opticNozzleOptions,
    opticOptions: { ...machine.opticNozzleOptions.opticOptions },
  };

  return copy;
}

export function createConfiguration(
  machineModelName: WeldingMachineModel,
  weldingTechnology: WeldingTechnology,
): Omit<MachineConfiguration, 'id'>;
export function createConfiguration(
  machineModelName: WeldingMachineModel,
  weldingTechnology: WeldingTechnology,
  id: string,
): MachineConfiguration;
export function createConfiguration(
  machineModelName: WeldingMachineModel,
  weldingTechnology: WeldingTechnology,
  id?: string,
): Storable<MachineConfiguration> {
  switch (machineModelName) {
    case 'TruArc Weld 1000':
    case 'TruLaser Weld 1000': {
      const technology: WeldingTechnology =
        machineModelName === 'TruArc Weld 1000' ? 'arc' : 'laser';
      const opticalType = weldingTechnology === 'arc' ? 'ARC_WIRE' : 'BEO_D35';

      return {
        id,
        machineModelName,
        weldingTechnology: technology,
        cabinOptions: {
          cabinLayout: 'STANDARD',
          cabinSize: '1200 x 3000',
          robotLinearAxis: MachineConfigPosition.UNSET,
          robotType: 'REGULAR',
        },
        componentOptions: {
          deskRef: { value: MachineConfigDesk.DemellerPlate },
          componentItems: [
            {
              // hint: this tag does NOT match with any translation key (yet, maybe in future if needed):
              tag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.ROT',
              // '',
              isChecked: true,
              position: MachineConfigPosition.UNSET,
              isEnabled: false,
            },
          ],
        },
        opticNozzleOptions: {
          opticalType,
          nozzleType: NozzleType.Coaxial,
          opticOptions: {
            rotaryModule: false,
            teachLine: false,
            fusionLine: false,
            wire: false,
          },
        },
      };
    }
    case 'TruLaser Weld 5000': {
      return {
        id,
        machineModelName: 'TruLaser Weld 5000',
        weldingTechnology,
        cabinOptions: {
          robotLinearAxis: MachineConfigPosition.LEFT,
          cabinLayout: '4x3',
          cabinSize: '4800 x 3700',
          robotType: 'ION',
        },
        componentOptions: {
          deskRef: { value: MachineConfigDesk.DemellerPlate },
          componentItems: [
            {
              tag: COMPONENT_TAGS.DKP,
              // ,
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: COMPONENT_TAGS.DT,
              // ,
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: COMPONENT_TAGS.RC,
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: COMPONENT_TAGS.RW,
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: COMPONENT_TAGS.WP,
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
          ],
        },
        opticNozzleOptions: {
          opticalType: OpticalType.PFO_SF20,
          nozzleType: NozzleType.LinearNozzle,
          opticOptions: {
            rotaryModule: true,
            teachLine: false,
            fusionLine: false,
            wire: false,
          },
        },
      };
    }
    default:
      throw new Error(`Unknown machine model "${machineModelName}"`);
  }
}
