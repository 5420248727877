import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    DefaultPartCalculationParameters,
    WeldingMethodDefaultPartCalculationParameters,
    WeldingMethodParameters,
    WeldingParameters,
    WeldingTechnology,
} from '@shared/types';
import { SettingsService } from '@trumpf-xguide/xguide';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../../../shared/constants';
import { TranslationHelper } from '../../../../shared/helpers';
import { AppSettings, IconSize, SystemOfUnits } from '../../../../shared/types';
import { DefaultCalculationParameters } from '../../../constants';
import { createEmptyWeldingParameters } from '../../../utility/factories';

/** The second and third page of the `CalculationWizard` presenting form-fields to adjust calculation parameters. */
@Component({
  selector: 'lsb-calculation-parameters-tab',
  templateUrl: './calculation-parameters-tab.component.html',
  styleUrls: ['./calculation-parameters-tab.component.scss'],
})
export class CalculationParametersTabComponent {
  @Input() defaultParameters: DefaultPartCalculationParameters = DefaultCalculationParameters;
  @Input() weldingTechnology: WeldingTechnology;

  // hint: langju: just to avoid breaking initial bindings, should be overridden by @Input() value:
  @Input() parameters: WeldingParameters = createEmptyWeldingParameters('arc');
  @Output() parametersChange = new EventEmitter<WeldingParameters>();

  @Input() weldingMethodParameters?: WeldingMethodParameters;
  @Output() weldingMethodParametersChange = new EventEmitter<WeldingMethodParameters>();

  readonly IconSize = IconSize;
  public isImperialUnitSystem = false;

  // referenced in template
  public parameterNames: Record<keyof WeldingParameters, keyof WeldingParameters> = {
    fixtureCostsInEur: 'fixtureCostsInEur',
    loadingTimeInSec: 'loadingTimeInSec',
    measureTimeInSec: 'measureTimeInSec',
    programmingTimeInSec: 'programmingTimeInSec',
    reworkTimeInSec: 'reworkTimeInSec',
    setupTimePerBatchInSec: 'setupTimePerBatchInSec',
    tackingTimeInSec: 'tackingTimeInSec',
    weldingSpeedInMillimetersPerSec: 'weldingSpeedInMillimetersPerSec',
    advancedSettings: 'advancedSettings',
  };

  public get methodSpecificParameters(): WeldingMethodDefaultPartCalculationParameters {
    return {
      ...this.defaultParameters.shared,
      ...this.defaultParameters[this.weldingTechnology],
    };
  }

  public get methodSpecificTranslations() {
    return this.weldingTechnology === 'arc'
      ? this.translations.CALCULATOR.WIZARD.TAB_ARC
      : this.translations.CALCULATOR.WIZARD.TAB_LASER;
  }

  constructor(public translations: TranslationHelper, settingsService: SettingsService) {
    const settings = settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );

    const currentUnitSystem = settings.get('unitSystem');
    this.isImperialUnitSystem = currentUnitSystem === SystemOfUnits.Imperial;
  }

  ngOnChanges() {
    const knownMethods: WeldingTechnology[] = ['arc', 'laser'];

    if (!knownMethods.includes(this.weldingTechnology)) {
      throw new Error(
        `Welding method "${this.weldingTechnology}" seems not to be valid. ` +
          `Wrong or undefined methods will cause providing incorrect default parameters.`,
      );
    }
  }

  public update(property: keyof WeldingParameters, value: any): void {
    const setValue = parseFloat(value);
    (this.parameters as any)[property] = setValue;

    this.parametersChange.emit(this.parameters);
  }

  public updateRobotWelding(value: boolean) {
    if (this.weldingMethodParameters !== undefined) {
      this.weldingMethodParameters.robotWelding = value;
      this.weldingMethodParametersChange.emit(this.weldingMethodParameters);
    }
  }
}
