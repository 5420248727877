import { DataPersistenceServiceBase } from './data-persistence-service.base';
import { DataMigrationRule, DataWithVersion, PersistenceStrategy, StoredEntity } from './types';

/**
 * DataPersistenceService that adds data versioning and migration to the base implementation.
 *
 * This is needed for saved data (e.g. to local storage) with an interface that is evolving over time.
 * This service enables the persistence service to detect saved data in old format and automatically migrate
 * it to the newest version, using migration rules that can be passed to the `migrationRules` property.
 *
 * ---
 *
 * **Example:**
 *
 * ~~~ts
 * export class MyDataService extends VersionedDataPersistenceService<MyData> {
 *    protected migrationRules = [
 *      {
 *        // initial version is considered to be 0, so first change will migrate to v1:
 *        migrateToVersion: 1,
 *        migrate: (data: MyDataOldInterface): MyDataNewInterface => ({ ...data, additionalProp: 42 })
 *      }
 *    ];
 *
 *    constructor(localStorage: LocalStorageService) {
 *      super(new LocalStoragePersistenceStrategy(
 *        localStorage,
 *        LocalStorageKeys.calculator.database
 *      );
 *    }
 * }
 * ~~~
 */
export class VersionedDataPersistenceService<
  T extends object,
> extends DataPersistenceServiceBase<T> {
  /** Data migration rules. Override this field to add custom data migration rules to ensure being backwards compatible with your saved data. */
  protected migrationRules: DataMigrationRule[] = [];

  protected get latestDataVersion(): number {
    return this.migrationsSortedByVersionAsc[this.migrationRules.length - 1]?.migrateToVersion ?? 0;
  }

  private get migrationsSortedByVersionAsc() {
    return this.migrationRules.sort(ascendingByMigrationVersion);
  }

  constructor(strategy: PersistenceStrategy<T>) {
    super(strategy);
  }

  public async create(value: T): Promise<StoredEntity<T>> {
    (value as DataWithVersion<T>).__data_version__ = this.latestDataVersion;
    return super.create(value);
  }

  public async update(value: T | StoredEntity<T>): Promise<StoredEntity<T>> {
    (value as DataWithVersion<T>).__data_version__ = this.latestDataVersion;
    return super.update(value);
  }

  protected onInitialDataLoad(items: StoredEntity<T>[]): void {
    this.checkMigrationsComplete(this.migrationRules);

    const updatedData = this.migrate<StoredEntity<T>>(items, this.migrationsSortedByVersionAsc);

    // hint: migration rules change the data that has been initially read, so we need
    // to save the changed data back to the source:
    const persist = this.migrationRules.length > 0;
    this._data = updatedData;
    this.applyChanges(persist);
  }

  private migrate<NewVersion>(items: unknown[], migrations: DataMigrationRule[]): NewVersion[] {
    return items.map((entry) => this.migrateEntry(entry, migrations));
  }

  private migrateEntry<NewVersion>(entry: unknown, migrations: DataMigrationRule[]): NewVersion {
    let result: any = entry;
    let currentDataVersion = result.__data_version__ ?? 0;

    migrations
      .filter((migration) => migration.migrateToVersion > currentDataVersion)
      .forEach((rule) => {
        result = rule.migrate(result);
        result.__data_version__ = rule.migrateToVersion;
      });

    return result as unknown as NewVersion;
  }

  /**
   * Checks if the given migrations are starting with version 1 and then continue counting up without gaps.
   * @param migrations The migrations to check for completeness.
   */
  private checkMigrationsComplete(migrations: DataMigrationRule[]): void {
    const migrationVersions = migrations.map((m) => m.migrateToVersion);

    migrationVersions.forEach((version, index) => {
      const expectedVersion = index + 1;

      if (version !== expectedVersion) {
        const versionBefore = migrationVersions[index - 1] ?? '0';
        const version = migrationVersions[index];
        const nextVersion = migrationVersions[index + 1] ?? '';

        throw new Error(
          `Version history is incomplete at: ...${versionBefore}, ${version}, ${nextVersion}, ...; expected version ${
            index + 1
          } but got ${version}.`,
        );
      }
    });
  }
}

function ascendingByMigrationVersion(a: DataMigrationRule, b: DataMigrationRule) {
  return a.migrateToVersion - b.migrateToVersion;
}
