import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PartCalculation, PartCalculationResult, WeldingTechnology } from '@shared/types';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoredEntity } from '../../../shared/data-persistence/types';
import { RadioGroupProvider } from '../../../shared/directives/radio-group/radio-group.provider';
import { TranslationHelper, typeOf } from '../../../shared/helpers';
import { PreviewFeaturesService } from '../../../shared/services/preview-features/preview-features.service';
import { IconSize } from '../../../shared/types';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import {
  CalculationUpdateNotification,
  SortDirection,
  TableRow,
  TableSortOptions,
} from '../../types';

export interface PartCalculationRow {
  calculation: StoredEntity<PartCalculation>;
  result: PartCalculationResult;
}

@Component({
  selector: 'lsb-part-calculation-table',
  templateUrl: './part-calculation-table.component.html',
  styleUrls: ['./part-calculation-table.component.scss'],
  providers: [RadioGroupProvider],
})
export class PartCalculationTableComponent {
  public readonly uiElementIds = UiElementIds;
  public readonly IconSize = IconSize;

  @Input() calculationRows: Observable<PartCalculationRow[]> = of([]);
  @Input() selected?: PartCalculationRow;

  @Output() edit = new EventEmitter<PartCalculation>();
  @Output() delete = new EventEmitter<PartCalculation>();
  @Output() calculationChange = new EventEmitter<CalculationUpdateNotification>();
  @Output() selectionChange = new EventEmitter<PartCalculationRow>();

  public sortOptions$ = new BehaviorSubject<TableSortOptions>({ sortBy: 'name', direction: 'asc' });

  public sortedTableRows$: Observable<TableRow[]>;
  private tableRows$: Observable<TableRow[]>;

  constructor(public translations: TranslationHelper, public flags: PreviewFeaturesService) {}

  ngOnInit() {
    this.tableRows$ = this.calculationRows.pipe(
      map((calculationRows) => calculationRows.map(this.convertToTableRow.bind(this))),
    );

    this.sortedTableRows$ = combineLatest([this.tableRows$, this.sortOptions$]).pipe(
      map(([tableRows, sorting]) => this.sortTableRows(tableRows, sorting)),
    );
  }

  public onSelectionChange(calculation: PartCalculation, weldingTechnology: WeldingTechnology) {
    calculation.parameters.selectedTechnology = weldingTechnology;
    this.calculationChange.emit({
      calculation,
      updatedField: 'SelectedWeldingTechnologyAndAmount',
    });
  }

  public selectResult(row: PartCalculationRow): void {
    this.selected = row;
    this.selectionChange.emit(row);
  }

  public deleteResult(calculation: StoredEntity<PartCalculation>): void {
    this.delete.emit(calculation);
  }

  public onSortClick(propertyName: keyof TableRow, sortOpts: TableSortOptions): void {
    sortOpts.sortBy !== propertyName ? this.setSortBy(propertyName) : this.toggleSortDirection();
  }

  public getSortingState(
    propertyName: keyof TableRow,
    sortOpts: TableSortOptions,
  ): Maybe<SortDirection> {
    if (sortOpts.sortBy !== propertyName) {
      return undefined;
    }

    return sortOpts.direction;
  }

  private convertToTableRow(calcRow: PartCalculationRow): TableRow {
    const weldingTechnology = calcRow.calculation.parameters.selectedTechnology;

    return {
      rowRef: calcRow,
      name: calcRow.calculation.name,
      totalParts: calcRow.calculation.configuration.basics.totalPieces,
      arcCostsPerPart: calcRow.result.costsPerPartArc,
      laserCostsPerPart: calcRow.result.costsPerPartLaser,
      costsInfo: [
        {
          translationKey: this.translations.CALCULATOR.TOTAL_COSTS_LASER,
          value: calcRow.result.totalCostsLaser,
          unit: '€',
        },
        {
          translationKey: this.translations.CALCULATOR.TOTAL_COSTS_ARC,
          value: calcRow.result.totalCostsArc,
          unit: '€',
        },
      ],
      selectedTechnology: weldingTechnology,
      savings: this.calculateSavings(calcRow.result, weldingTechnology),
    };
  }

  public calculateSavings(
    result: PartCalculationResult,
    selectedWeldingTechnology: WeldingTechnology,
  ): number {
    const arc = result.totalCostsArc;
    const laser = result.totalCostsLaser;

    return selectedWeldingTechnology === 'laser' ? arc - laser : laser - arc;
  }

  private sortTableRows(rows: TableRow[], sortOpts: TableSortOptions): TableRow[] {
    return rows.sort((rowA: TableRow, rowB: TableRow) => {
      if (!sortOpts.sortBy) {
        return 0;
      }

      const propertyA = rowA[sortOpts.sortBy];
      const propertyB = rowB[sortOpts.sortBy];

      let comparisonResult = 0;

      if (typeOf(propertyA, 'number') && typeOf(propertyB, 'number')) {
        comparisonResult = propertyA - propertyB;
      }

      if (typeOf(propertyA, 'string') && typeOf(propertyB, 'string')) {
        comparisonResult = propertyA.localeCompare(propertyB);
      }

      return sortOpts.direction === 'asc' ? comparisonResult : -comparisonResult;
    });
  }

  private setSortBy(propertyName: keyof TableRow): void {
    this.sortOptions$.next({ sortBy: propertyName, direction: 'asc' });
  }

  private toggleSortDirection(): void {
    const current = this.sortOptions$.getValue();
    this.sortOptions$.next({ ...current, direction: current.direction === 'asc' ? 'desc' : 'asc' });
  }
}
