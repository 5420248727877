import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsageTrackingModule, DashboardModule as XGuideDashboardModule } from '@trumpf-xguide/xguide';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { CalculatedPartItemComponent } from './components/calculated-part-item/calculated-part-item.component';
import { CalculatedPartsTileComponent } from './components/calculated-parts-tile/calculated-parts-tile.component';
import { NewsTileComponent } from './components/news-tile/news-tile.component';
import { NewsService } from './services/news/news.service';
import { NewsServiceMock } from './services/news/news.service.mock';
import { DashboardComponent } from './views/dashboard/dashboard.component';

@NgModule({
  imports: [
    // <this comment forces items to be placed below each other>
    CommonModule,
    XGuideDashboardModule,
    SharedModule,
    UsageTrackingModule.forChild(),
  ],
  declarations: [
    DashboardComponent,
    CalculatedPartsTileComponent,
    CalculatedPartItemComponent,
    NewsTileComponent,
  ],
  providers: [
    { provide: NewsService, useClass: environment.production ? NewsService : NewsServiceMock },
  ],
  exports: [],
})
export class DashboardModule {}
