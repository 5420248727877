import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { CaptionLabel } from '../../../../shared/components/caption-label-list/types';
import { TranslationHelper } from '../../../../shared/helpers';
import { AuthService } from '../../../../shared/services/auth/auth.service';
import { UiElementIds } from '../../../../shared/usage-tracking/ui-element-ids';
import {
  MachineConfigDesk,
  MachineConfigPosition,
  MachineConfiguration,
  OpticOptions,
  ResourceType,
} from '../../../types';
import {
  composeLayoutResourceName,
  getLayoutResourceUrl,
  getOpticResourceUrl,
} from '../../../utility';
import { OPTIC_KEYS } from '../../optic-configuration/optic-configuration.component';

/** Renders a component presenting all relevant information about a specific `MachineConfiguration`. */
@Component({
  selector: 'lsb-configurator-list-item',
  templateUrl: './configurator-list-item.component.html',
  styleUrls: ['./configurator-list-item.component.scss'],
})
export class ConfiguratorListItemComponent {
  public readonly uiElementIds = UiElementIds;

  @Input() machineConfiguration: MachineConfiguration;
  @Output() edit = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();

  public isConfirmedCustomer$ = this.authService.userProfile$.pipe(
    map((user) => user?.confirmedCustomer),
  );
  public isAuthenticated$ = this.authService.isAuthenticated$;

  public get layoutOptionLabels(): CaptionLabel[] {
    return [
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.LAYOUT,
        label: composeLayoutResourceName(
          this.machineConfiguration?.machineModelName,
          this.machineConfiguration?.cabinOptions,
          this.machineConfiguration?.componentOptions,
        ),
        translate: 'caption',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.LAYOUT_SIZE,
        label: this.machineConfiguration?.cabinOptions?.cabinLayout,
        translate: 'caption',
      },
      {
        caption: this.translations.CONFIGURATOR.MECHANICAL_INTERFACE,
        label:
          this.machineConfiguration?.componentOptions?.deskRef.value ===
          MachineConfigDesk.DemellerPlate
            ? this.translations.CONFIGURATOR.DATA.MACHINE_CONFIG_DESK.DEMELLER_PALLETE
            : this.translations.CONFIGURATOR.DATA.MACHINE_CONFIG_DESK.ZERO_POINT_CLAMPING_SYSTEM,
        translate: 'all',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.TURN_TILT_POSITIONER,
        label: this.positionOf(
          this.translations.CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DKP,
        ),
        translate: 'all',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.MANUAL_TURN_TABLE,
        label: this.positionOf(
          this.translations.CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DT,
        ),
        translate: 'all',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.ROTARY_CHANGER,
        label: this.positionOf(
          this.translations.CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.RW,
        ),
        translate: 'all',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.TURNING_AXIS,
        label: this.positionOf(
          this.translations.CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.WP,
        ),
        translate: 'all',
      },
    ];
  }

  public get opticCaptionLabels(): CaptionLabel[] {
    return [
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.OPTICAL_TYPE,
        label: this.machineConfiguration?.opticNozzleOptions?.opticalType,
        translate: 'caption',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.GAS_NOZZLE,
        label: this.machineConfiguration?.opticNozzleOptions?.nozzleType,
        translate: 'all',
      },
      {
        caption: this.translations.CONFIGURATOR.LIST_ITEM.OPTIONS,
        label: this.selectedOpticNozzleOptions
          .map((option) => this.translateService.instant(option))
          .join(', '),
        translate: 'caption',
      },
    ];
  }

  public get selectedOpticNozzleOptions() {
    return (
      Object.entries(this.machineConfiguration?.opticNozzleOptions?.opticOptions ?? {})
        .filter(([, value]) => value === true)
        .map(([option]) => {
          switch (option as keyof OpticOptions) {
            case 'rotaryModule':
              return OPTIC_KEYS.rotaryModule;
            case 'teachLine':
              return OPTIC_KEYS.teachLine;
            case 'fusionLine':
              return OPTIC_KEYS.fusionLine;
            case 'wire':
              return OPTIC_KEYS.wire;
          }
        }) ?? []
    );
  }

  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    public translations: TranslationHelper,
  ) {}

  public login() {
    this.authService.login();
  }

  public layoutResourceUrl(type: ResourceType) {
    return getLayoutResourceUrl(
      this.machineConfiguration?.machineModelName,
      this.machineConfiguration?.cabinOptions,
      this.machineConfiguration?.componentOptions,
      type,
    );
  }

  public opticResourceUrl(type: ResourceType) {
    return getOpticResourceUrl(this.machineConfiguration?.opticNozzleOptions, type);
  }

  private positionOf(translateKey: string): string {
    const component = this.find(translateKey);
    const position =
      component?.position === MachineConfigPosition.LEFT
        ? this.translations.CONFIGURATOR.LIST_ITEM.LEFT
        : this.translations.CONFIGURATOR.LIST_ITEM.RIGHT;

    return component?.isChecked ? position : this.translations.CONFIGURATOR.LIST_ITEM.NOT_AVAILABLE;
  }

  private find(translateKey: string) {
    return this.machineConfiguration?.componentOptions?.componentItems.find(
      (component) => component.tag === translateKey,
    );
  }
}
