import { Material } from './material';
import { Thickness } from './thickness';

export interface BoxDimensions {
  name: string;
  lengthX: number;
  widthY: number;
  heightZ: number;
  flangeWidthA: number;
  flangeWidthB: number;
  material: Material;
  thickness: Thickness;
}
