<div class="wizard-overlay">
  <div class="wizard">
    <div class="wizard-top">
      <div class="title-container">
        <h2>
          {{ translations.CALCULATOR.CREATE_FROM_EXAMPLE_PART_DIALOG.EXAMPLE_PARTS | translate }}
        </h2>
      </div>
      <div class="hr"></div>
    </div>

    <div class="content">
      <lsb-part-filter-list
        [parts]="parts"
        [selectableItems]="true"
        [hideKeywords]="true"
        [selectedPart]="selectedPart"
        (selectionChange)="selectPart($event)"
        (filterChange)="resetSelection()"
      ></lsb-part-filter-list>
    </div>

    <div class="wizard-bottom">
      <div class="footer">
        <div class="footer-btn-container"></div>

        <div class="footer-btn-container">
          <div class="button-container">
            <!-- ATOM: Footer Button Secondary -->
            <button
              class="btn-footer-secondary"
              [xuiClickUsageTracking]="uiElementIds.CREATE_CALCULATION_FROM_EXAMPLE_PART_CANCEL"
              (click)="cancel()"
            >
              <span class="button-label">
                {{ translations.DIALOG.CANCEL | translate }}
              </span>
            </button>
          </div>

          <div class="button-container">
            <!-- ATOM: Footer Button Primary -->
            <button
              class="btn-footer-primary"
              [attr.disabled]="selectedPart == null ? true : null"
              [xuiClickUsageTracking]="uiElementIds.CREATE_CALCULATION_FROM_EXAMPLE_PART_OK"
              (click)="create()"
            >
              <span class="button-label">
                {{ translations.DIALOG.OK | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
