import { EventEmitter } from '@angular/core';
import { WeldingTechnology } from '@shared/types/calculation-types';

/** The interface for each view used within the configuration wizard. */
export interface ConfigurationWizardView {
  /** The current configuration, to be used as @Input property. */
  configuration: MachineConfiguration;
  /** Update event whenever the configuration changes, to be used as @Output property. */
  configurationChange: EventEmitter<MachineConfiguration>;
}

export type MachineConfigComponentItemConverterFn = (
  component: MachineConfigComponentItem,
) => string;

/** Converts a component to its corresponding file name part. */
export interface ComponentToFilenamePartConverter {
  /** The component tag that the converter can convert into a file name part. */
  componentTag: string;
  /** The converter function or value for the corresponding component. */
  convert: string | MachineConfigComponentItemConverterFn;
}

/** Determines resource types fetchable from api urls. */
export type ResourceType = 'image' | 'cad-model';

export type WeldingMachineModel = 'TruArc Weld 1000' | 'TruLaser Weld 1000' | 'TruLaser Weld 5000';

/**
 * The ConfigurationWizardInfo interface used in the Configuration Wizard.
 * It contains Machine Configuration data and meta data for Wizard itself
 */
export interface ConfigurationWizardInfo {
  configuration: MachineConfiguration;
  /** This flag needed for Wizard step 2. If wizard opens a new configuration, it should be true, if wizard edits saved configuration - to false */
  setComponentOptionsInitialState: boolean;
}

export interface MachineConfiguration {
  id: string;
  /**
   * The name of the machine's model that this configuration is targeting.
   */
  machineModelName: WeldingMachineModel;
  cabinOptions: MachineCabinOptions;
  componentOptions: ComponentOptions;
  opticNozzleOptions: OpticNozzleOptions;
  weldingTechnology: WeldingTechnology;
}

export interface MachineCabinOptions {
  // 1. Cabin
  robotLinearAxis: MachineConfigPosition;
  cabinLayout: string;
  cabinSize: string;
  robotType: RobotType;
}

export interface ComponentOptions {
  // 2. Components
  deskRef: DeskRef;
  componentItems: MachineConfigComponentItem[];
}

export interface OpticNozzleOptions {
  // 3. Optic config
  opticalType: string;
  nozzleType: string;
  opticOptions: OpticOptions;
}

export interface DeskRef {
  value: MachineConfigDesk;
}

export enum MachineConfigPosition {
  LEFT = 'Left',
  RIGHT = 'Right',
  UNSET = 'Unset',
}

export enum MachineConfigDesk {
  // This values have to match the Translation-Keys in lang.ts file
  ZeroPointClampingSystem = 'CONFIGURATOR.DATA.MACHINE_CONFIG_DESK.ZERO_POINT_CLAMPING_SYSTEM',
  DemellerPlate = 'CONFIGURATOR.DATA.MACHINE_CONFIG_DESK.DEMELLER_PALLETE',
}

export type ComponentShortTag = 'dkp' | 'dt' | 'rw' | 'wp' | 'rc';

export interface MachineConfigComponentItem {
  tag: string;
  isChecked: boolean;
  position?: MachineConfigPosition;
  isEnabled: boolean;
}

export interface OpticOptions {
  rotaryModule: boolean;
  teachLine: boolean;
  fusionLine: boolean;
  wire: boolean;
}

export type RobotType = 'REGULAR' | 'ION';
