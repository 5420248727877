/* istanbul ignore file */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MOCK_USERPROFILE } from '../../../data/mocks';
import { UserProfile } from '../../../types';

@Injectable()
export class AuthServiceMock {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private userProfileSubject$ = new ReplaySubject<UserProfile>();
  public userProfile$ = this.userProfileSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  constructor(private router: Router) {}

  public async runInitialLoginSequence(): Promise<void> {
    return this.isDoneLoadingSubject$.next(true);
  }

  public login(targetUrl?: string) {
    this.isAuthenticatedSubject$.next(true);
    this.userProfileSubject$.next(MOCK_USERPROFILE);
    if (targetUrl) {
      this.router.navigateByUrl(targetUrl);
    }
  }

  public logout() {
    this.isAuthenticatedSubject$.next(false);
    this.userProfileSubject$.next(undefined);
  }

  public refresh() {}

  public hasValidToken() {
    return this.isAuthenticatedSubject$.getValue();
  }
}
