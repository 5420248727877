import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PreviewFeaturesServiceInterface } from './preview-features-service.interface';

@Injectable()
export class PreviewFeaturesService implements PreviewFeaturesServiceInterface {
  public enablePreviewFeatures$: Observable<boolean>;

  constructor(route: ActivatedRoute) {
    this.enablePreviewFeatures$ = route.queryParams.pipe(
      map((params) => {
        const previewFlag = params.preview;
        return previewFlag != null && ['', 'true'].includes(previewFlag);
      }),
    );
  }

  public async previewFeaturesEnabled() {
    return this.enablePreviewFeatures$.pipe(take(1)).toPromise();
  }
}
