import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BoxDimensions, Thickness } from '@shared';
import { DialogHostedView, DialogResult, DialogReturnType } from '@trumpf-xguide/xguide';
import { TranslationHelper } from '../../../shared/helpers';
import { IconSize } from '../../../shared/types';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { MaterialType } from './types';

@Component({
  selector: 'lsb-box-dimensions',
  templateUrl: './box-dimensions.component.html',
  styleUrls: ['./box-dimensions.component.scss'],
})
export class BoxDimensionsComponent implements OnInit, DialogHostedView<any, BoxDimensions> {
  public readonly uiElementIds = UiElementIds;
  closeDialog = new EventEmitter<DialogReturnType<BoxDimensions>>();

  readonly IconSize = IconSize;
  public isValid = false;

  public box: BoxDimensions;
  public materials: MaterialType[];
  public selectedMaterial?: MaterialType;
  public thicknesses: Thickness[];
  public selectedThickness?: Thickness;

  constructor(public translations: TranslationHelper, private translateService: TranslateService) {
    this.box = {
      name: '',
      lengthX: 0,
      widthY: 0,
      heightZ: 0,
      flangeWidthA: 0,
      flangeWidthB: 0,
      material: 'va',
      thickness: 1,
    };
  }

  ngOnInit(): void {
    this.materials = [
      {
        material: 'va',
        materialName: this.translateService.instant(
          this.translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL_VALUE.VA,
        ),
      },
      {
        material: 'aluminum',
        materialName: this.translateService.instant(
          this.translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL_VALUE.ALUMINIUM,
        ),
      },
      {
        material: 'steel',
        materialName: this.translateService.instant(
          this.translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL_VALUE.STEEL,
        ),
      },
    ];

    this.thicknesses = [1, 1.5, 2];
  }

  ok() {
    this.closeDialog.emit({ result: DialogResult.Ok, payload: this.box });
  }

  cancel() {
    this.closeDialog.emit({ result: DialogResult.Cancel });
  }

  checkIsValid() {
    this.isValid =
      this.box.name.length > 0 &&
      this.selectedThickness !== undefined &&
      this.selectedMaterial !== undefined;
  }

  materialSelected(materialType: MaterialType) {
    this.selectedMaterial = materialType;
    this.box.material = materialType.material;
    this.checkIsValid();
  }

  thicknessSelected(thickness: Thickness) {
    this.selectedThickness = thickness;
    this.box.thickness = thickness;
    this.checkIsValid();
  }

  initDialog(initialData: Maybe<any>): void {}
}
