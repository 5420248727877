import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Part } from '../../../../../shared';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { PartsService } from '../../shared/services/parts/parts.service';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],
})
export class PartsComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  private partCategoryFilterSubject = new BehaviorSubject<Maybe<string>>(undefined);

  public selectedPartCategory$: Observable<
    Maybe<string>
  > = this.partCategoryFilterSubject.asObservable();

  public parts$: Observable<Part[]>;
  public partCategories$: Observable<string[]>;

  constructor(
    public translations: TranslationHelper,
    private partsService: PartsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const allParts$ = this.partsService.getAll();

    this.parts$ = combineLatest([allParts$, this.selectedPartCategory$]).pipe(
      map(([parts, selectedCategoryId]) => this.getPartsOfCategory(selectedCategoryId, parts)),
    );

    this.partCategories$ = allParts$.pipe(map((parts) => this.removeCategoryDuplicates(parts)));
  }

  /**
   * Navigates to the welding principle details page for this given id.
   * TODO: wuerfelda: When the id is a fixtureId, we may navigate to a fixture page.
   *
   * @param weldingPrincipleId The keyword id should be an available welding principle.
   */
  public navigateToWeldingPrinciple(weldingPrincipleId: string) {
    this.router.navigate(['welding-principles/details', weldingPrincipleId]);
  }

  public navigateToPartDetails(partId: string) {
    this.router.navigate(['parts', partId]);
  }

  public deselectPartCategory() {
    this.partCategoryFilterSubject.next(undefined);
  }

  public selectPartCategory(partTypeId: string) {
    this.partCategoryFilterSubject.next(partTypeId);
  }

  private removeCategoryDuplicates(parts: Part[]): string[] {
    return Array.from(new Set(parts.map((part) => part.category)));
  }

  private getPartsOfCategory(categoryId: Maybe<string>, parts: Part[]): Part[] {
    if (categoryId === undefined) {
      return parts;
    }

    return parts.filter((part) => part.category === categoryId);
  }
}
