import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { WeldingTechnology } from '../../../../../../shared';
import { Storable } from '../../../shared/data-persistence/types';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { MachineConfiguration, WeldingMachineModel } from '../../types';
import { createConfiguration } from '../../utility';

@Component({
  selector: 'lsb-create-configuration-view',
  templateUrl: './create-configuration-view.component.html',
  styleUrls: ['./create-configuration-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateConfigurationViewComponent {
  @Output() createConfiguration = new EventEmitter<Storable<MachineConfiguration>>();
  @Output() openConfigurationWizard = new EventEmitter<void>();

  public readonly uiElementIds = UiElementIds;

  constructor(public translations: TranslationHelper) {}

  public emitCreateConfiguration(
    machineModelName: WeldingMachineModel,
    technology: WeldingTechnology,
  ) {
    const config = createConfiguration(machineModelName, technology);
    this.createConfiguration.emit(config);
  }

  public emitOpenWizard() {
    this.openConfigurationWizard.emit();
  }
}
