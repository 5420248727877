import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfiguratorLayoutModel, CONFIGURATOR_LAYOUT_MODEL_MOCKS } from '../../../../../../shared';
import { ConfiguratorModellingServiceInterface } from './configurator-modelling.service.interface';

@Injectable()
export class ConfiguratorModellingServiceMock implements ConfiguratorModellingServiceInterface {
  getLayoutModels(): Observable<ConfiguratorLayoutModel[]> {
    return of(CONFIGURATOR_LAYOUT_MODEL_MOCKS);
  }
}
