import { CalculatorSettings, DefaultPartCalculationParameters } from '@shared/types';

/** The namespace to save calculator related settings to. */
export const CALCULATOR_SETTINGS_NAMESPACE = 'calculator';

export const DefaultCalculationParameters: DefaultPartCalculationParameters = {
  shared: {
    fixtureCostsInEur: [0, 1000, 3000, 5000, 10000, 20000, 50000],
    programmingTimeInSec: [900, 1800, 3600, 5400, 7200, 14400, 28800],
    setupTimePerBatchInSec: [0, 300, 600, 900, 1200, 1800, 3600, 7200],
    tackingTimeInSec: [0, 10, 20, 30, 45, 60, 90, 120],
    loadingTimeInSec: [15, 30, 45, 60, 90, 120, 240, 480],
    measureTimeInSec: [0, 10, 20, 30, 40, 50, 60, 90, 120],
  },
  arc: {
    weldingSpeedInMillimetersPerSec: [
      /*   m/min    value in mm/s */
      /* 0.15 m/min */ 2.5,
      /* 0.2 m/min */ 3.333333333333334,
      /* 0.3 m/min */ 5,
      /* 0.4 m/min */ 6.666666666666668,
      /* 0.5 m/min */ 8.333333333333334,
      /* 1 m/min */ 16.666666666666668,
      /* 1,5 m/min */ 25,
      /* 2 m/min */ 33.333333333333336,
    ],
  },
  laser: {
    weldingSpeedInMillimetersPerSec: [
      /*   m/min    value in mm/s */
      /* 1 m/min */ 16.666666666666668,
      /* 2 m/min */ 33.333333333333336,
      /* 3 m/min */ 50,
      /* 6 m/min */ 100,
      /* 10 m/min */ 166.66666666666669,
    ],
  },
};

export const CalculatorSettingsDefaultValues: CalculatorSettings = {
  entryExitTimePerSeamInSec: 5,
  hourlyRateLaserInEurPerHour: 0.030555555555555556,
  hourlyRateArcManualInEurPerHour: 0.016666666666666666,
  hourlyRateArcRoboterInEurPerHour: 0.022222222222222223,
};

export const CalculatorSettingsDefaultOptions = {
  hourlyRateLaserInEurPerHour: [0.027777777777777778, 0.030555555555555556, 0.033333333333333333],
  hourlyRateArcManualInEurPerHour: [
    0.011111111111111111,
    0.013888888888888889,
    0.016666666666666667,
  ],
  hourlyRateArcRoboterInEurPerHour: [
    0.016666666666666667,
    0.019444444444444444,
    0.022222222222222222,
  ],
  entryExitTimePerSeamInSec: [3, 4, 5, 6, 7],
};

export const CalculatorDefaultValues = {
  general: {
    amountSeams: 1,
    totalSeamLengthInMillimeters: 1000,
    totalPieces: 999,
    batchSize: 333,
  },
  laser: {
    fixtureCostsInEur: 0,
    programmingTimeInSec: 7200, // display as: 120 min
    setupTimePerBatchInSec: 600, // display as: 10 min
    tackingTimeInSec: 60,
    loadingTimeInSec: 30,
    measureTimeInSec: 10,
    weldingSpeedInMillimetersPerSec: 50, // display as: 3 m/min
    reworkTimeInSec: 0,
    advancedSettings: false,
  },
  arc: {
    robotWelding: false,
    fixtureCostsInEur: 0,
    programmingTimeInSec: 0,
    setupTimePerBatchInSec: 300, // display as: 5 min
    tackingTimeInSec: 60,
    loadingTimeInSec: 10,
    measureTimeInSec: 0,
    weldingSpeedInMillimetersPerSec: 5, // display as: 0.3 m/min
    reworkTimeInSec: 120,
    advancedSettings: false,
  },
};
