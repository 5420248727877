import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalculatorSettings } from '@shared/types';
import { TranslationHelper } from '../../../../shared/helpers';
import { IconSize } from '../../../../shared/types';
import { CalculatorSettingsDefaultOptions } from '../../../constants';

@Component({
  selector: 'lsb-calculation-settings-sidebar',
  templateUrl: './calculation-settings-sidebar.component.html',
  styleUrls: ['./calculation-settings-sidebar.component.scss'],
})
export class CalculationSettingsSidebarComponent {
  @Input() public settings: CalculatorSettings = {
    hourlyRateLaserInEurPerHour: 0,
    hourlyRateArcManualInEurPerHour: 0,
    hourlyRateArcRoboterInEurPerHour: 0,
    entryExitTimePerSeamInSec: 0,
  };
  @Output() public settingsChange = new EventEmitter<CalculatorSettings>();

  readonly IconSize = IconSize;

  public DefaultOptions = CalculatorSettingsDefaultOptions;

  constructor(public translations: TranslationHelper, private translate: TranslateService) {}

  hourlyRateString(worker: 'laser' | 'arc', type?: 'manual' | 'robot'): string {
    const hourlyRateKey = this.translations.CALCULATOR.HOURLY_RATE;
    const workerKey =
      worker === 'laser'
        ? this.translations.CALCULATOR.WIZARD.TAB_LASER.TITLE
        : this.translations.CALCULATOR.WIZARD.TAB_ARC.TITLE;
    const typeKey =
      type === undefined
        ? ''
        : type === 'manual'
        ? this.translations.CALCULATOR.WORK_TYPE.MANUAL
        : this.translations.CALCULATOR.WORK_TYPE.ROBOTER;

    const translated = {
      hourlyRate: this.translate.instant(hourlyRateKey),
      worker: this.translate.instant(workerKey),
      type: type ? this.translate.instant(typeKey) : '',
    };

    return `${translated.hourlyRate} ${translated.worker} ${translated.type}`;
  }

  notifyUpdate() {
    this.settingsChange.emit(this.settings);
  }
}
