<table class="table full-width">
  <tr class="table-header" *ngIf="sortOptions$ | async as sortOpts">
    <th>
      <div class="container preview-header non-sortable-header" data-ngtx="part-preview-column">
        <span>{{ translations.FIXTURE_MATE.TABLE_COLUMN_HEADER_PART_PREVIEW | translate }}</span>
      </div>
    </th>
    <th>
      <div class="container" (click)="onSortClick('name', sortOpts)" data-ngtx="name-column">
          <span
            class="filter"
            [class.filter-asc]="getSortingState('name', sortOpts) === 'asc'"
            [class.filter-desc]="getSortingState('name', sortOpts) === 'desc'"
          ></span>
        <span>{{ translations.FIXTURE_MATE.TABLE_COLUMN_HEADER_NAME | translate }}</span>
      </div>
    </th>
    <th>
      <div class="container non-sortable-header" data-ngtx="download-part-column">
        <span>{{ translations.FIXTURE_MATE.TABLE_COLUMN_HEADER_CAD_PART | translate }}</span>
      </div>
    </th>
    <th>
      <div class="container" (click)="onSortClick('status', sortOpts)" data-ngtx="status-column">
          <span
            class="filter"
            [class.filter-asc]="getSortingState('status', sortOpts) === 'asc'"
            [class.filter-desc]="getSortingState('status', sortOpts) === 'desc'"
          ></span>
        <span>{{ translations.FIXTURE_MATE.TABLE_COLUMN_HEADER_CAD_STATUS | translate }}</span>
      </div>
    </th>
    <th>
      <div class="container non-sortable-header" data-ngtx="fixture-preview-column">
        <span>{{ translations.FIXTURE_MATE.TABLE_COLUMN_HEADER_FIXTURE_PREVIEW | translate }}</span>
      </div>
    </th>
    <th>
      <div class="container non-sortable-header" data-ngtx="download-fixture-column">
        <span>{{ translations.FIXTURE_MATE.TABLE_COLUMN_HEADER_CAD_FIXTURE | translate }}</span>
      </div>
    </th>
  </tr>
  <tr
      *ngFor="let row of sortedTableRows$ | async"
      class="basic-atom table-entry"
      [class.selected]="row.name === selected?.name"
      (click)="selectResult(row)"
    >
    <td >
      <div
        data-ngtx="row-part-preview-column"
        class="content-container"
      >
        <img
          class="model-preview non-draggable"
          [attr.src]="row.previewUrl | securedImage | async"
         alt=''/>
      </div>
    </td>
    <td>
      <div class="content-container" data-ngtx="row-name-column">{{ row.name }} <lsb-box-dimensions-tooltip *ngIf="row.boxDimensions" [boxDimensions]="row.boxDimensions"></lsb-box-dimensions-tooltip></div>
    </td>
    <td>
      <xui-button
        type="primary"
        xuiClickUsageTracking="{{ uiElementIds.FIXTURE_MATE_PART_DOWNLOAD }}"
        (click)="downloadCad.emit(row.partModelUrl)"
        [disabled]="!row.partModelUrl"
        data-ngtx="row-part-download-button"
      >
        {{ translations.FIXTURE_MATE.DOWNLOAD_BUTTON | translate }}
      </xui-button>
    </td>
    <td>
      <div class="content-container" data-ngtx="row-status-column">{{ row.status | status | translate }}</div>
    </td>
    <td >
      <div
        data-ngtx="row-fixture-preview-column"
        class="content-container"
      >
        <img
          class="model-preview non-draggable"
          [attr.src]="row.previewFixtureUrl | securedImage | async"
          alt=''/>
      </div>
    </td>
    <td>
      <xui-button
        type="primary"
        xuiClickUsageTracking="{{ uiElementIds.FIXTURE_MATE_FIXTURE_DOWNLOAD }}"
        (click)="downloadCad.emit(row.fixtureUrl)"
        [disabled]="!row.fixtureUrl"
        data-ngtx="row-fixture-download-button"
      >
        {{ translations.FIXTURE_MATE.DOWNLOAD_BUTTON | translate }}
      </xui-button>
    </td>
    <td>
      <xui-button
        data-ngtx="row-fixture-delete-button"
        class="remove-button"
        iconKey="Paperbasket"
        type="secondary"
        [xuiClickUsageTracking]="uiElementIds.FIXTURE_MATE_FIXTURE_DELETE"
        [specificElementIdentifier]="row.name"
        (click)="deleteFixture.emit(row.name)"
      ></xui-button>
    </td>
  </tr>
</table>
