<ng-container *ngIf="currentRoute$ | async as currentRoute">
  <xui-sub-menu>
    <xui-sub-menu-tab
      [label]="translations.ABOUT.COPYRIGHT | translate"
      [selected]="currentRoute.endsWith('conditions-of-use')"
      routerLink="/about/conditions-of-use"
      xuiClickUsageTracking="{{ uiElementIds.COPYRIGHT }}"
    ></xui-sub-menu-tab>
    <xui-sub-menu-tab
      [label]="translations.ABOUT.SETTINGS | translate"
      [selected]="currentRoute.endsWith('settings')"
      routerLink="/about/settings"
      xuiClickUsageTracking="{{ uiElementIds.SETTINGS }}"
    ></xui-sub-menu-tab>
  </xui-sub-menu>
</ng-container>

<div class="content">
  <router-outlet></router-outlet>
</div>
