import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsageTrackingMessageType } from '@shared';
import { UsageTrackingService } from '@trumpf-xguide/xguide';
import { ClickedUsageTracking } from '@trumpf-xguide/xguide/lib/usage-tracking/types';
import { MenuItemType } from '@trumpf/ux.ui-community';
import { UiElementIds } from '../usage-tracking/ui-element-ids';
import { TranslationHelper } from './translation.helpers';

export enum NavigationSlugs {
  DASHBOARD = 'dashboard',
  WELDING_PRINCIPLES = 'welding-principles',
  PARTS = 'parts',
  CONFIGURATOR = 'configurator',
  CALCULATOR = 'calculator',
  FIXTURE_MATE = 'fixture-mate',
  MAIL_TO = 'mail-to',
  SETTINGS = 'settings',
  ABOUT = 'about',
  LOGIN = 'login',
}

export const generateNavigationMenu = (
  translateService: TranslateService,
  translations: TranslationHelper,
): MenuItemType[] => {
  const menu = [
    {
      label: translateService.instant(translations.MAIN_MENU.WELDING_PRINCIPLES),
      icon: 'weldguide-icons:BendingMachine.Frontal.Bulb.Lit',
      slug: NavigationSlugs.WELDING_PRINCIPLES,
    },
    {
      label: translateService.instant(translations.MAIN_MENU.PARTS),
      icon: 'weldguide-icons:Package',
      slug: NavigationSlugs.PARTS,
    },
    {
      label: translateService.instant(translations.MAIN_MENU.CONFIGURATOR),
      icon: 'weldguide-icons:Configuration',
      slug: NavigationSlugs.CONFIGURATOR,
    },
    {
      label: translateService.instant(translations.MAIN_MENU.CALCULATOR),
      icon: 'weldguide-icons:ProductionPlan.Gear',
      slug: NavigationSlugs.CALCULATOR,
    },
    {
      label: translateService.instant(translations.MAIN_MENU.FIXTURE_MATE),
      icon: 'weldguide-icons:AddBox',
      slug: NavigationSlugs.FIXTURE_MATE,
    },
    {
      label: translateService.instant(translations.XGUIDE.MENU.CONTACT_US_BUTTON),
      icon: 'weldguide-icons:Envelope',
      slug: NavigationSlugs.MAIL_TO,
    },
  ];

  return menu;
};

export const navigateTo = (
  slug: NavigationSlugs,
  router: Router,
  usageTrackingService: UsageTrackingService,
): void => {
  let url: string;
  let uiElementId: UiElementIds;
  switch (slug) {
    case NavigationSlugs.DASHBOARD:
      url = 'dashboard';
      uiElementId = UiElementIds.DASHBOARD;
      break;
    case NavigationSlugs.WELDING_PRINCIPLES:
      url = 'welding-principles';
      uiElementId = UiElementIds.WELDING_PRINCIPLES;
      break;
    case NavigationSlugs.PARTS:
      url = 'parts';
      uiElementId = UiElementIds.PARTS;
      break;
    case NavigationSlugs.CONFIGURATOR:
      url = 'configurator';
      uiElementId = UiElementIds.CONFIGURATOR;
      break;
    case NavigationSlugs.CALCULATOR:
      url = 'calculator';
      uiElementId = UiElementIds.CALCULATOR;
      break;
    case NavigationSlugs.FIXTURE_MATE:
      url = 'fixture-mate';
      uiElementId = UiElementIds.FIXTURE_MATE;
      break;
    case NavigationSlugs.SETTINGS:
      url = '/about/settings';
      uiElementId = UiElementIds.SETTINGS;
      break;
    case NavigationSlugs.ABOUT:
      url = '/about';
      uiElementId = UiElementIds.ABOUT;
      break;
    case NavigationSlugs.LOGIN:
    case NavigationSlugs.MAIL_TO:
    default:
      return;
  }

  const usageTracking: ClickedUsageTracking = {
    currentUrl: router.url,
    clickedElement: uiElementId,
  };
  usageTrackingService.send(UsageTrackingMessageType.ElementClicked, usageTracking);

  router.navigateByUrl(url);
};
