import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalculatorSettings, PartCalculation } from '@shared/types';
import {
    DialogRef,
    DialogResult,
    DialogService,
    SettingsAccessor,
    SettingsService,
    UsageTrackingService,
} from '@trumpf-xguide/xguide';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { PartDetails, UsageTrackingMessageType } from '../../../../../../shared';
import { CalculationUsageTracking } from '../../../../../../shared/types/usage-tracking/calculation-usage-tracking';
import { CadModelService } from '../../../shared/cad-viewer/services';
import { Storable, StoredEntity } from '../../../shared/data-persistence/types';
import { TranslationHelper } from '../../../shared/helpers';
import { PartsService } from '../../../shared/services/parts/parts.service';
import { PreviewFeaturesService } from '../../../shared/services/preview-features/preview-features.service';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { cloneDeep } from '../../../shared/utility';
import { PartCalculationRow } from '../../components/part-calculation-table/part-calculation-table.component';
import { CALCULATOR_SETTINGS_NAMESPACE, CalculatorSettingsDefaultValues } from '../../constants';
import { CalculationNameService } from '../../services/calculation-name.service';
import { CalculationDataService } from '../../services/calculation/calculation-data.service';
import { CalculationService } from '../../services/calculation/calculation.service';
import { UpdatedCalculationField } from '../../types';
import { createEmptyPartCalculation } from '../../utility/factories';
import { createCalculationFromPart } from '../../utility/helpers';
import { AddExamplePartDialogComponent } from '../add-example-part-dialog/add-example-part-dialog.component';
import { CalculationWizardComponent } from '../calculation-wizard/calculation-wizard.component';

@Component({
  templateUrl: './calculator-overview.component.html',
  styleUrls: ['./calculator-overview.component.scss'],
  providers: [PreviewFeaturesService],
})
export class CalculatorOverviewComponent implements OnInit {
  private static readonly ActionQueryParamNames = {
    addCalculation: 'add',
    selectCalculation: 'select:',
    editCalculation: 'edit:',
    createFromPartId: 'create-from-part:',
  };
  public static readonly ActionQueryParams = {
    addCalculation: () => CalculatorOverviewComponent.ActionQueryParamNames.addCalculation,
    selectCalculation: (calculationId: string) =>
      CalculatorOverviewComponent.ActionQueryParamNames.selectCalculation + calculationId,
    editCalculation: (calculationId: string) =>
      CalculatorOverviewComponent.ActionQueryParamNames.editCalculation + calculationId,
    createFromPartId: (partId: string) =>
      CalculatorOverviewComponent.ActionQueryParamNames.createFromPartId + partId,
  };

  public readonly uiElementIds = UiElementIds;
  public calculationResults$: Observable<Maybe<PartCalculationRow[]>> = of(undefined);
  public currentSettings$: Observable<CalculatorSettings>;
  public selected$: Observable<Maybe<PartCalculationRow>>;
  public hasResults$: Observable<boolean> = of(false);
  public selection = new BehaviorSubject<Maybe<PartCalculationRow>>(undefined);

  private subscriptions = new Subscription();
  private settings: SettingsAccessor<CalculatorSettings>;
  private wizardRef: DialogRef<any>;

  constructor(
    public translations: TranslationHelper,
    private calculationData: CalculationDataService,
    private calculationName: CalculationNameService,
    private dialog: DialogService,
    private settingsService: SettingsService,
    private calculationService: CalculationService,
    private partsService: PartsService,
    private usageTrackingService: UsageTrackingService,
    private cadModel: CadModelService,
    private route: ActivatedRoute,
    public flags: PreviewFeaturesService,
  ) {
    this.settings = this.settingsService.access<CalculatorSettings>(
      CALCULATOR_SETTINGS_NAMESPACE,
      CalculatorSettingsDefaultValues,
    );

    this.currentSettings$ = this.settings.changes$;

    this.calculationResults$ = combineLatest([
      this.calculationData.data$,
      this.currentSettings$,
    ]).pipe(
      map(([data]) => {
        return data.map((calculation) => {
          const calcRow: PartCalculationRow = {
            calculation,
            result: this.calculationService.calculate(calculation),
          };

          return calcRow;
        });
      }),
    );

    this.selected$ = combineLatest([this.calculationResults$, this.selection]).pipe(
      map(([results, selection]) => this.getSelection(selection, results)),
    );

    this.hasResults$ = this.calculationResults$.pipe(
      map((results) => !!results && results.length > 0),
    );
  }

  ngOnInit(): void {
    const initAction: string = this.route.snapshot.queryParams.action;

    if (initAction == null) {
      return;
    }

    const name = initAction.substring(initAction.indexOf(':') + 1);
    const actions = CalculatorOverviewComponent.ActionQueryParamNames;

    if (initAction === actions.addCalculation) {
      requestAnimationFrame(() => this.createCalculation());
    } else if (initAction.includes(actions.selectCalculation)) {
      this.selectCalculationRowByName(name);
    } else if (initAction.includes(actions.editCalculation)) {
      this.editCalculationByName(name);
    } else if (initAction.includes(actions.createFromPartId)) {
      this.createFromPartId(name);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.wizardRef?.close({ result: DialogResult.Cancel });
  }

  public saveSettings(settings: CalculatorSettings) {
    this.settings.update(settings);
  }

  public async createFromStepModel(stepFile: File): Promise<void> {
    const model = await this.cadModel
      .convertStepToStl(stepFile)
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(undefined);
        }),
      )
      .toPromise();

    await this.createCalculation(model?.dataUrl, model?.name);
  }

  async createFromPartId(id: string): Promise<void> {
    const partDetails = await this.partsService.getPartDetails(id).toPromise();
    await this.createCalculationFromExamplePart(partDetails);
  }

  async updateCalculation(
    calculation: PartCalculation,
    updatedField: UpdatedCalculationField = 'All',
  ): Promise<void> {
    if (
      updatedField !== 'SelectedWeldingTechnologyAndAmount' &&
      // Prevent calculations for incomplete PartCalculations
      Object.keys(calculation).length > 0
    ) {
      this.sendCalculationUsageTracking(calculation);
    }

    await this.calculationData.createOrUpdate(calculation);
  }

  async createCalculationFromExampleParts(): Promise<void> {
    const parts$ = this.partsService.getPartsAvailableForCalculation();
    const parts = await parts$.toPromise();
    this.wizardRef = this.dialog.openWithRef(AddExamplePartDialogComponent, parts);

    const dialogResult = await this.wizardRef.result;

    if (dialogResult.result === DialogResult.Cancel) {
      return;
    }

    if (dialogResult.payload) {
      const selectedPart = dialogResult.payload;
      await this.createCalculationFromExamplePart(selectedPart);
    }
  }

  private async createCalculationFromExamplePart(selectedPart: PartDetails) {
    const calculation = createCalculationFromPart(selectedPart);
    await this.editCalculation(calculation);
  }

  private getSelection(selection: Maybe<PartCalculationRow>, results: Maybe<PartCalculationRow[]>) {
    const withSameId = (r: PartCalculationRow): boolean =>
      r.calculation.id === selection?.calculation.id;

    const hasSelection = selection !== null && results?.some(withSameId);
    const selectedResult = hasSelection ? results!.find(withSameId) : results?.[0];

    return selectedResult;
  }

  async createCalculation(cadModelUrl?: string, modelName?: string): Promise<void> {
    const name = modelName ?? (await this.calculationName.getNextName());
    const config = createEmptyPartCalculation(name);

    if (cadModelUrl) {
      config.configuration.basics.cadModelDataUri = cadModelUrl;
    }

    this.wizardRef = this.dialog.openWithRef(CalculationWizardComponent, config);
    const result = await this.wizardRef.result;

    if (result.result === DialogResult.Ok) {
      if (!result.payload) {
        return console.error(`Finishing wizard with undefined payload object!`);
      }

      await this.updateCalculation(result.payload);
    }
  }

  async editCalculation(calculation: Storable<PartCalculation>): Promise<void> {
    const copy = cloneDeep(calculation);
    this.wizardRef = this.dialog.openWithRef(CalculationWizardComponent, copy);

    const dialogResult = await this.wizardRef.result;

    if (dialogResult.result === DialogResult.Ok) {
      if (dialogResult.payload === undefined) {
        return console.error(`Finishing wizard with undefined payload object!`);
      }

      await this.updateCalculation(dialogResult.payload);
    }
  }

  async deleteCalculation(calculation: StoredEntity<PartCalculation>) {
    await this.calculationData.delete(calculation.id);
  }

  private sendCalculationUsageTracking(
    calculation: PartCalculation | StoredEntity<PartCalculation>,
  ) {
    const currentCalculatorSettings = this.settings.get();
    const partCalculationResult = this.calculationService.calculate(calculation);

    let partCalculation = calculation;

    // Remove 'id' property from StoredEntity
    if ('id' in calculation) {
      const { id, ...calculationWithoutId } = calculation as StoredEntity<PartCalculation>;
      partCalculation = calculationWithoutId;
    }

    const calculationUsageTracking: CalculationUsageTracking = {
      calculatorSettings: currentCalculatorSettings,
      partCalculation,
      partCalculationResult,
    };

    this.usageTrackingService.send(UsageTrackingMessageType.Calculation, calculationUsageTracking);
  }

  private async selectCalculationRowByName(name: string) {
    const selected = await this.findCalculationRowByName(name);

    if (selected) {
      this.selection.next(selected);
    }
  }

  private async editCalculationByName(name: string) {
    const selected = await this.findCalculationRowByName(name);

    if (selected) {
      this.editCalculation(selected.calculation);
    }
  }

  private async findCalculationRowByName(name: string) {
    const rows = await this.calculationResults$.pipe(take(1)).toPromise();

    if (rows) {
      return rows.find((row) => row.calculation.name === name);
    }
  }
}
