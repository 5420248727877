<table class="table full-width">
  <tr class="table-header" *ngIf="sortOptions$ | async as sortOpts">
    <!-- hint: 3d model viewer is a preview feature, remove ngIf when feature is considered stable -->
    <th *ngIf="flags.enablePreviewFeatures$ | async">
      <div class="container preview-header" data-ngtx="preview-column">
        <span>{{ translations.CALCULATOR.PREVIEW | translate }}</span>
      </div>
    </th>
    <th>
      <div class="container" (click)="onSortClick('name', sortOpts)" data-ngtx="name-column">
        <span
          class="filter"
          [class.filter-asc]="getSortingState('name', sortOpts) === 'asc'"
          [class.filter-desc]="getSortingState('name', sortOpts) === 'desc'"
        ></span>
        <span>{{ translations.CALCULATOR.NAME | translate }}</span>
      </div>
    </th>
    <th>
      <div
        class="container"
        (click)="onSortClick('totalParts', sortOpts)"
        data-ngtx="totalParts-column"
      >
        <span
          class="filter"
          [class.filter-asc]="getSortingState('totalParts', sortOpts) === 'asc'"
          [class.filter-desc]="getSortingState('totalParts', sortOpts) === 'desc'"
        ></span>
        <span>{{ translations.CALCULATOR.TOTAL_PARTS | translate }}</span>
      </div>
    </th>
    <th class="group">
      <div
        class="container"
        (click)="onSortClick('laserCostsPerPart', sortOpts)"
        data-ngtx="laserCostsPerPart-column"
      >
        <span
          class="filter"
          [class.filter-asc]="getSortingState('laserCostsPerPart', sortOpts) === 'asc'"
          [class.filter-desc]="getSortingState('laserCostsPerPart', sortOpts) === 'desc'"
        ></span>
        <span>{{ translations.CALCULATOR.LASER_COSTS_PER_PART | translate }}</span>
      </div>
    </th>
    <th>
      <div
        class="container"
        (click)="onSortClick('arcCostsPerPart', sortOpts)"
        data-ngtx="arcCostsPerPart-column"
      >
        <span
          class="filter"
          [class.filter-asc]="getSortingState('arcCostsPerPart', sortOpts) === 'asc'"
          [class.filter-desc]="getSortingState('arcCostsPerPart', sortOpts) === 'desc'"
        ></span>
        <span>{{ translations.CALCULATOR.ARC_COSTS_PER_PART | translate }}</span>
      </div>
    </th>
    <th class="group">
      <div class="container" (click)="onSortClick('savings', sortOpts)" data-ngtx="savings-column">
        <span
          class="filter"
          [class.filter-asc]="getSortingState('savings', sortOpts) === 'asc'"
          [class.filter-desc]="getSortingState('savings', sortOpts) === 'desc'"
        ></span>
        <span>{{ translations.CALCULATOR.SAVINGS | translate }}</span>
      </div>
    </th>
    <th class="actions-header">&nbsp;</th>
  </tr>
  <tr
    *ngFor="let row of sortedTableRows$ | async"
    class="basic-atom table-entry"
    [class.selected]="row.rowRef.calculation.id === selected?.calculation?.id"
    (click)="selectResult(row.rowRef)"
  >
    <td *ngIf="flags.enablePreviewFeatures$ | async">
      <div
        data-ngtx="row-preview-column"
        class="content-container"
        (click)="edit.emit(row.rowRef.calculation)"
      >
        <img
          class="model-preview non-draggable"
          [src]="row.rowRef.calculation.configuration.basics.cadModelPreview"
        />
      </div>
    </td>
    <td>
      <div class="content-container" data-ngtx="row-name-column">{{ row.name }}</div>
    </td>
    <td>
      <div class="content-container" data-ngtx="row-totalParts-column">{{ row.totalParts }}</div>
    </td>
    <td>
      <div class="content-container">
        <lsb-radio-button
          [lsbRadioGroup]="row.name"
          (selectionChange)="onSelectionChange(row.rowRef.calculation, $event)"
          value="laser"
          [checked]="row.selectedTechnology === 'laser'"
          [label]="laserCostsPerPart.value"
          data-ngtx="row-laserCostsPerPart-column"
        >
          <xui-value #laserCostsPerPart [binding]="row.laserCostsPerPart">
            <xui-number-formatter
              [maxDecimals]="2"
              [unit]="'€' | currencyUnit"
            ></xui-number-formatter>
          </xui-value>
        </lsb-radio-button>
      </div>
    </td>
    <td>
      <div class="content-container">
        <lsb-radio-button
          [lsbRadioGroup]="row.name"
          (selectionChange)="onSelectionChange(row.rowRef.calculation, $event)"
          value="arc"
          [checked]="row.selectedTechnology === 'arc'"
          [label]="arcCostsPerPart.value"
          data-ngtx="row-arcCostsPerPart-column"
        >
          <xui-value #arcCostsPerPart [binding]="row.arcCostsPerPart">
            <xui-number-formatter
              [maxDecimals]="2"
              [unit]="'€' | currencyUnit"
            ></xui-number-formatter>
          </xui-value>
        </lsb-radio-button>
      </div>
    </td>
    <td>
      <div class="content-container" data-ngtx="row-savings-column">
        <xui-value #savings [binding]="row.savings">
          <xui-number-formatter
            [maxDecimals]="2"
            [unit]="'€' | currencyUnit"
          ></xui-number-formatter>
        </xui-value>

        <span [class.positive]="row.savings > 0" [class.negative]="row.savings < 0">
          {{ savings.value }}
        </span>

        <xui-icon
          class="info-flag"
          iconKey="Tooltip.Information"
          [size]="IconSize.S"
          [xuiTooltip]="costTooltip"
        ></xui-icon>

        <xui-tooltip #costTooltip>
          <lsb-number-list>
            <lsb-number-list-item
              *ngFor="let info of row.costsInfo"
              [value]="info.value"
              [unit]="info.unit | currencyUnit"
              [translationKey]="info.translationKey"
            ></lsb-number-list-item>
          </lsb-number-list>
        </xui-tooltip>
      </div>
    </td>
    <td>
      <div class="content-container actions">
        <xui-button
          class="edit-button"
          iconKey="Pencil"
          type="secondary"
          [xuiClickUsageTracking]="uiElementIds.PARTS_CALCULATION_ENTRY_EDIT"
          [specificElementIdentifier]="row.name"
          (click)="edit.emit(row.rowRef.calculation)"
        ></xui-button>
        <xui-button
          class="remove-button"
          iconKey="Paperbasket"
          type="secondary"
          [xuiClickUsageTracking]="uiElementIds.PARTS_CALCULATION_ENTRY_REMOVE"
          [specificElementIdentifier]="row.name"
          (click)="deleteResult(row.rowRef.calculation)"
        ></xui-button>
      </div>
    </td>
  </tr>
</table>
