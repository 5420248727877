import { FixtureCreationStatus } from './fixture-creation-status';
import { BoxDimensions } from './box-dimensions';

export interface FixtureMateDto {
  name: string;
  status: FixtureCreationStatus;
  previewUrl?: string;
  previewFixtureUrl?: string;
  partModelUrl?: string;
  fixtureUrl?: string;
  partStlUrl?: string;
  fixtureStlUrl?: string;
  boxDimensions?: BoxDimensions;
}
