import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalculationBasics } from '@shared/types';
import { TranslationHelper } from '../../../../shared/helpers';
import { PreviewFeaturesService } from '../../../../shared/services/preview-features/preview-features.service';
import { IconSize } from '../../../../shared/types';

/** The first page of the `CalculationWizard` to adjust basic calculation parameters. */
@Component({
  selector: 'lsb-calculation-basics-tab',
  templateUrl: './calculation-basics-tab.component.html',
  styleUrls: ['./calculation-basics-tab.component.scss'],
})
export class CalculationBasicsTabComponent {
  @Input() name: string;
  @Output() nameChange = new EventEmitter<string>();
  @Input() basics: CalculationBasics;
  @Output() basicsChange = new EventEmitter<CalculationBasics>();

  readonly IconSize = IconSize;
  public showModelFullScreen = false;

  constructor(public translations: TranslationHelper, public flags: PreviewFeaturesService) {}

  ngOnInit() {
    if (this.basics === undefined) {
      console.warn('CalculationBasicsTab: Please provide a calculation basics object.');
    }
  }

  toggleFullScreen() {
    this.showModelFullScreen = !this.showModelFullScreen;
  }

  updatePreview(base64Preview: string): void {
    this.basics.cadModelPreview = base64Preview;
    this.notifyUpdate();
  }

  public notifyUpdate(): void {
    this.basicsChange.emit(this.basics);
  }

  public update(property: keyof CalculationBasics, value: any): void {
    const setValue = parseFloat(value);
    (this.basics as any)[property] = setValue;

    this.notifyUpdate();
  }
}
