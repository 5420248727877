import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguratorLayoutModel } from '../../../../../../shared';
import { environment } from '../../../../environments/environment';
import { ConfiguratorModellingServiceInterface } from './configurator-modelling.service.interface';

@Injectable()
export class ConfiguratorModellingService implements ConfiguratorModellingServiceInterface {
  constructor(private http: HttpClient) {}

  getLayoutModels(): Observable<ConfiguratorLayoutModel[]> {
    return this.http.get<ConfiguratorLayoutModel[]>(configApi('layouts'));
  }
}

function configApi(route: string): string {
  return `${environment.apiUrl}/configurator-models/${route}`;
}
