import { merge } from 'merge-anything';

/**
 * Deeply (incl. all sub-objects) copies a calculation object.
 * @param value The calculation object to copy.
 */
export function cloneDeep<T extends object>(value: T): T {
  // creates a deep copy of the given object
  return merge(value, {}) as any;
}
