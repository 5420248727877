<div class="item">
  <h3 (click)="select.emit()">{{ calculationName }}</h3>

  <div class="horizontal-list">
    <lsb-caption-label (click)="select.emit()" [dark]="true" [item]="perPartWithCorrectUnit">
    </lsb-caption-label>
    <lsb-caption-label
      (click)="select.emit()"
      [dark]="true"
      [item]="totalCostsWithCorrectUnit"
      class="space-left"
    >
    </lsb-caption-label>
    <xui-button class="edit" iconKey="Pencil" (click)="edit.emit()"></xui-button>
  </div>
</div>
