import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { UsageTrackingModule } from '@trumpf-xguide/xguide';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { BoxDimensionsTooltipComponent } from './components/box-dimensions-tooltip/box-dimensions-tooltip.component';
import { BoxDimensionsComponent } from './components/box-dimensions/box-dimensions.component';
import { DataPrivacyUploadAgreementComponent } from './components/data-privacy-upload-agreement/data-privacy-upload-agreement.component';
import { FixtureMateDetailsComponent } from './components/fixture-mate-details/fixture-mate-details.component';
import { FixtureMateTableComponent } from './components/fixture-mate-table/fixture-mate-table.component';
import { PartBoxDimensionsTooltipComponent } from './components/part-box-dimensions-tooltip/part-box-dimensions-tooltip.component';
import { FixtureMateRoutingModule } from './fixture-mate-routing.module';
import { SecuredImagePipe } from './pipes/secured-image.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { FixtureMateService } from './services/fixture-mate.service';
import { FixtureMateServiceMock } from './services/fixture-mate.service.mock';
import { FixtureMateViewComponent } from './views/fixture-mate-view/fixture-mate-view.component';

@NgModule({
  imports: [
    CommonModule,
    FixtureMateRoutingModule,
    SharedModule,
    UsageTrackingModule.forChild(),

  ],
  declarations: [
    FixtureMateViewComponent,
    FixtureMateTableComponent,
    StatusPipe,
    SecuredImagePipe,
    PartBoxDimensionsTooltipComponent,
    DataPrivacyUploadAgreementComponent,
    FixtureMateDetailsComponent,
    BoxDimensionsComponent,
    BoxDimensionsTooltipComponent,
  ],
  exports: [],
  providers: [
    {
      provide: FixtureMateService,
      useClass: environment.mock ? FixtureMateServiceMock : FixtureMateService,
    },
  ],
})
export class FixtureMateModule {
  static forRoot(): ModuleWithProviders<FixtureMateModule> {
    return {
      ngModule: FixtureMateModule,
      providers: [],
    };
  }
}
