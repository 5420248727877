import { Injectable } from '@angular/core';
import { FixtureMateServiceInterface } from './fixture-mate.service.interface';
import { BoxDimensions, FixtureMateDto } from '@shared';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

const BASE_ROUTE = 'fixture-mate';
const BOX_DIMENSIONS = 'box-dimensions';

@Injectable({
  providedIn: 'root',
})
export class FixtureMateService implements FixtureMateServiceInterface {
  private errorSubject = new Subject<string | undefined>();
  public backendError$ = this.errorSubject.asObservable();

  constructor(private http: HttpClient) {}

  getAllFixtures(): Observable<FixtureMateDto[] | undefined> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/`;
    this.errorSubject.next();
    return this.http
      .get<FixtureMateDto[]>(url)
      .pipe(catchError((errorResponse) => this.handleError(errorResponse, this.errorSubject)));
  }

  createNewFixture(stepFile: File): Observable<FixtureMateDto[] | undefined> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/`;
    const formData = new FormData();
    formData.append('file', stepFile, stepFile.name);

    this.errorSubject.next();
    return this.http
      .post<FixtureMateDto[]>(url, formData)
      .pipe(catchError((errorResponse) => this.handleError(errorResponse, this.errorSubject)));
  }

  createNewBoxDimensions(boxDimensions: BoxDimensions): Observable<FixtureMateDto[] | undefined> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/${BOX_DIMENSIONS}`;
    this.errorSubject.next();
    return this.http
      .post<FixtureMateDto[]>(url, boxDimensions)
      .pipe(catchError((errorResponse) => this.handleError(errorResponse, this.errorSubject)));
  }

  deleteFixture(partName: string): Observable<FixtureMateDto[] | undefined> {
    const url = `${environment.apiUrl}/${BASE_ROUTE}/${partName}`;
    this.errorSubject.next();
    return this.http
      .delete<FixtureMateDto[]>(url)
      .pipe(catchError((errorResponse) => this.handleError(errorResponse, this.errorSubject)));
  }

  downloadCad(url: string): Observable<Blob> {
    // Check if url is relative, then add the origin location
    if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
      url = `${window.location.origin}${url}`;
    }
    return this.http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(map((res: any) => new Blob([res], { type: 'text/plain;charset=utf-8' })));
  }

  private handleError(error: HttpErrorResponse, errorSubject: Subject<string | undefined>) {
    let errorText = '';
    console.error(error);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      errorText = `An error occurred: '${JSON.stringify(error.message)}'`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorText = `Backend returned code ${error.status}: '${JSON.stringify(error.message)}'`;
    }

    errorSubject.next(errorText);

    return of(undefined);
  }
}
