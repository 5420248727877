<section class="half-panel components-selection" *ngIf="layoutRulesService">
  <div class="heading">
    <h5>{{ translations.CONFIGURATOR.EQUIPMENT | translate }}</h5>
    <hr />
  </div>
  <lsb-configurator-component-option-item
    *ngFor="let componentItem of configuration.componentOptions.componentItems"
    [componentItem]="componentItem"
    (checkedChange)="componentItemCheckedChanged($event)"
    (switcherChange)="componentItemPositionChanged($event)"
  ></lsb-configurator-component-option-item>
</section>
<div class="half-panel image-preview">
  <div class="desk-option">
    <h5>{{ translations.CONFIGURATOR.MECHANICAL_INTERFACE | translate }}</h5>
    <hr />
    <lsb-radio-group (selectionChange)="deskOptionSelectionChanged($event)">
      <lsb-radio-button
        class="demeller-plate"
        [checked]="configuration.componentOptions.deskRef.value === MachineConfigDesk.DemellerPlate"
        [value]="MachineConfigDesk.DemellerPlate"
        [label]="MachineConfigDesk.DemellerPlate | translate"
        [disabled]="!cabinPlateState.demellerPlateEnabled"
      ></lsb-radio-button>
      <lsb-radio-button
        class="zeroPoint-clamping-system"
        [checked]="
          configuration.componentOptions.deskRef.value === MachineConfigDesk.ZeroPointClampingSystem
        "
        [value]="MachineConfigDesk.ZeroPointClampingSystem"
        [label]="MachineConfigDesk.ZeroPointClampingSystem | translate"
        [disabled]="!cabinPlateState.zeroPointClampingSystemEnabled"
      ></lsb-radio-button>
    </lsb-radio-group>
  </div>

  <img class="image non-draggable" [src]="layoutIllustrationUrl | imageUrl" />
</div>
