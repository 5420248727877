import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ConfiguratorLayoutModel } from '../../../../../../shared';
import { CabinInfo } from '../../../../../../shared/types/cabin-info';
import { TranslationHelper, onlyUnique } from '../../../shared/helpers';
import {
  ConfigurationWizardView,
  MachineCabinOptions,
  MachineConfiguration,
  RobotType,
} from '../../types';
import { getLayoutResourceUrl } from '../../utility';

@Component({
  selector: 'lsb-robot-safety-cabin',
  templateUrl: './robot-safety-cabin.component.html',
  styleUrls: ['./robot-safety-cabin.component.scss'],
})
export class RobotSafetyCabinComponent implements OnChanges, ConfigurationWizardView {
  @Input() layoutModels: ConfiguratorLayoutModel[] = [];
  @Input() configuration: MachineConfiguration;
  @Output() configurationChange = new EventEmitter<MachineConfiguration>();
  @Output() cabinLayoutChange = new EventEmitter<CabinInfo>();

  get cabinIllustrationUrl(): string {
    return getLayoutResourceUrl(
      this.configuration?.machineModelName,
      this.configuration?.cabinOptions,
      this.configuration?.componentOptions,
      'image',
    );
  }

  public cabins: CabinInfo[] = [];
  public selectedCabin: MachineCabinOptions;
  public selectedRobot?: RobotType;

  constructor(public translations: TranslationHelper) {}

  ngOnChanges() {
    const cabinOptions = this.configuration?.cabinOptions;

    if (!cabinOptions?.cabinSize || !cabinOptions?.cabinLayout) {
      const firstCabin = this.cabins[0];
      cabinOptions.cabinSize = firstCabin?.size;
      cabinOptions.cabinLayout = firstCabin?.layout;
    }

    this.cabins = this.layoutModels
      .map(toCabinInfo)
      .filter(removeCertainLayouts)
      .filter(onlyUnique('layout'));
    this.selectedCabin = this.configuration?.cabinOptions;
    this.selectedRobot = this.configuration.cabinOptions.robotType;
  }

  public selectCabin(cabin: CabinInfo) {
    if (cabin.layout === '4x3') {
      this.selectRobot('ION');
    }

    this.configuration.cabinOptions.cabinLayout = cabin.layout;
    this.configuration.cabinOptions.cabinSize = cabin.size;

    this.configurationChange.emit({ ...this.configuration });
    this.cabinLayoutChange.emit({ ...cabin });
  }

  public isRobotSelectionDisabled(cabinLayout: string | undefined): boolean {
    return cabinLayout === '4x3';
  }

  public selectRobot(robotType: RobotType) {
    this.configuration.cabinOptions.robotType = robotType;
    this.configurationChange.emit({ ...this.configuration });
  }
}

function toCabinInfo(layout: ConfiguratorLayoutModel): CabinInfo {
  return layout.cabin;
}

function removeCertainLayouts(cabin: CabinInfo): boolean {
  return cabin.layout !== '7x5' && cabin.layout !== '8x4';
}
