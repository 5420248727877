import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export type TextType = 'headline';

@Component({
  selector: 'lsb-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnChanges {
  @Input() type: TextType = 'headline';

  public classes = { [this.type]: true };

  ngOnChanges(changes: SimpleChanges): void {
    const typeKey: keyof this = 'type';

    if (typeKey in changes) {
      this.classes = { [this.type]: true };
    }
  }
}
