<div class="property-list">
  <!-- TODO: move to xui-caption-label-list after punchguide-deadline -->
  <lsb-caption-label-list
    class="properties"
    [items]="layoutOverviewLabels"
  ></lsb-caption-label-list>
  <!-- TODO: move to xui-caption-label-list after punchguide-deadline -->
  <lsb-caption-label-list
    *ngIf="layoutComponentLabels.length > 0"
    class="properties"
    [items]="layoutComponentLabels"
  ></lsb-caption-label-list>
</div>

<div class="configurator-image">
  <div class="buttons-box">
    <xui-button
      class="download-button"
      xuiClickUsageTracking="{{ uiElementIds.CONFIGURATION_PARTS_DOWNLOAD_CAD }}"
      (click)="downloadCad()"
      [type]="'primary'"
      >{{ translations.PARTS.DOWNLOAD_CAD | translate }}</xui-button
    >
  </div>
  <img class="image-preview non-draggable" [src]="resolveResourceUrl('image') | imageUrl" />
</div>
