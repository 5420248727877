import { Component, EventEmitter } from '@angular/core';
import { DialogHostedView, DialogResult, DialogReturnType } from '@trumpf-xguide/xguide';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-data-privacy-upload-agreement',
  templateUrl: './data-privacy-upload-agreement.component.html',
  styleUrls: ['./data-privacy-upload-agreement.component.scss']
})
export class DataPrivacyUploadAgreementComponent implements DialogHostedView<any, any> {
  public readonly uiElementIds = UiElementIds;
  closeDialog = new EventEmitter<DialogReturnType<any>>();

  constructor(public translations: TranslationHelper) { }

  initDialog(_: any): void {}

  ok() {
    this.closeDialog.emit({ result: DialogResult.Ok });
  }

  cancel() {
    this.closeDialog.emit({ result: DialogResult.Cancel });
  }
}
