import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DialogHostedView, DialogResult, DialogReturnType, WizardResult } from '@trumpf-xguide/xguide';
import { startWith, take } from 'rxjs/operators';
import { CabinInfo } from '../../../../../../shared/types/cabin-info';
import { Tab } from '../../../shared/components/tabs/types';
import { TranslationHelper } from '../../../shared/helpers';
import { ConfiguratorModellingService } from '../../services';
import { ConfigurationWizardInfo, MachineConfiguration } from '../../types';
import { getComponentItem } from '../configuration-wizard-step-two/rules-data/component-items-helper';
import { getRulesByCabinLayout } from '../configuration-wizard-step-two/rules-data/rules-factory';

@Component({
  selector: 'lsb-configuration-wizard',
  templateUrl: './configuration-wizard.component.html',
  styleUrls: ['./configuration-wizard.component.scss'],
})
export class ConfigurationWizardComponent
  implements DialogHostedView<ConfigurationWizardInfo, MachineConfiguration>
{
  @Output() closeDialog = new EventEmitter<DialogReturnType<MachineConfiguration>>();

  public readonly TAB_LABEL_1 = this.translations.CONFIGURATOR_WIZARD.ROBOT_SAFETY_CABIN;
  public readonly TAB_LABEL_2 = this.translations.CONFIGURATOR_WIZARD.COMPONENTS_SELECTION;
  public readonly TAB_LABEL_3 = this.translations.CONFIGURATOR_WIZARD.OPTIC_CONFIGURATION;
  public tabs = [
    { label: this.TAB_LABEL_1 },
    { label: this.TAB_LABEL_2 },
    { label: this.TAB_LABEL_3 },
  ];

  public selectedTab: Tab = this.tabs[0];
  public configurationWizardInfo: ConfigurationWizardInfo;
  public configuration: MachineConfiguration;
  public layoutModels$ = this.configuratorModels.getLayoutModels().pipe(take(1), startWith([]));

  constructor(
    private readonly translations: TranslationHelper,
    private readonly configuratorModels: ConfiguratorModellingService,
    private readonly router: Router,
  ) {}

  public initDialog(configurationWizardInfo: ConfigurationWizardInfo) {
    this.configurationWizardInfo = configurationWizardInfo;
    this.configuration = configurationWizardInfo.configuration;
  }

  /*
   * There can be situations, when a user returns from tab 2 to tab 1 and changes the layout.
   * But the componentOptions (from tab 2) still have their state, and for this state and new cabin layout there is no existing image.
   * So, every time when a user changes cabin layout, we should reset the componentOptions to the initial state regarding the new layout.
   */
  public resetConfigurationComponents(cabinLayout = this.configuration.cabinOptions.cabinLayout) {
    this.resetComponentOptions(cabinLayout);
    this.configurationWizardInfo.setComponentOptionsInitialState = true;
  }

  public finishDialog(wizardResult: WizardResult) {
    const result = wizardResult === 'finished' ? DialogResult.Ok : DialogResult.Cancel;
    this.closeDialog.emit({ result, payload: this.configuration });
  }

  public onNavigateTo(target: string) {
    this.closeDialog.emit({ result: DialogResult.Cancel });
    this.router.navigateByUrl(target);
  }

  public onTabChange(tab: Tab) {
    this.selectedTab = tab;
  }

  public onSelectedCabinChange(cabin: CabinInfo) {
    this.configuration.cabinOptions.cabinLayout = cabin.layout;
    this.configuration.cabinOptions.cabinSize = cabin.size;
  }

  private resetComponentOptions(newCabinLayout: string) {
    const rulesFromFactory = getRulesByCabinLayout(
      newCabinLayout,
      this.configuration.cabinOptions.robotType,
    );
    const components = this.configuration.componentOptions.componentItems;

    rulesFromFactory.setDefaultValues(
      getComponentItem('dkp', components),
      getComponentItem('dt', components),
      getComponentItem('rc', components),
      getComponentItem('rw', components),
      getComponentItem('wp', components),
      this.configuration.componentOptions.deskRef,
      {
        demellerPlateEnabled: true,
        zeroPointClampingSystemEnabled: true,
      },
    );
  }
}
