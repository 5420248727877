import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FixtureMateDto } from '@shared';
import { Observable, of } from 'rxjs';
import { FixtureMateService } from '../../services/fixture-mate.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { TranslateService } from '@ngx-translate/core';
import { FileReaderService } from '../../../shared/file-drop/services/file-reader/file-reader.service';
import { mailToLink } from '@trumpf-xguide/xguide';

@Component({
  selector: 'lsb-fixture-mate-details',
  templateUrl: './fixture-mate-details.component.html',
  styleUrls: ['./fixture-mate-details.component.scss'],
})
export class FixtureMateDetailsComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  @Input() public fixture$: Observable<FixtureMateDto | undefined>;

  public partName$: Observable<string | undefined>;
  public partStlModelUrl$: Observable<string | undefined>;
  public fixtureStlModelUrl$: Observable<string | undefined>;
  public modelContainerHeight = 100;

  @ViewChild('header', { read: ElementRef })
  private headerRef: ElementRef<HTMLDivElement>;
  @ViewChild('footer', { read: ElementRef })
  private footerRef: ElementRef<HTMLDivElement>;

  private resizeTimeOutInterval = 200;
  private resizeActivated = false;
  private resizeTimeOut: any;

  constructor(
    private fixtureMateService: FixtureMateService,
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private fileReader: FileReaderService,
    private zone: NgZone,
    private hostElement: ElementRef,
  ) {}

  public get mailLink(): string {
    return mailToLink(
      'teilegestaltung@trumpf.com',
      'Weld.Guide@de.trumpf.com',
      this.translateService.instant(this.translations.WELDING_PRINCIPLES.DETAILS.HELP.MAIL_SUBJECT),
    );
  }

  ngOnInit(): void {
    this.partStlModelUrl$ = this.fixture$.pipe(
      switchMap((fixture) =>
        fixture?.partStlUrl
          ? this.fixtureMateService
              .downloadCad(fixture?.partStlUrl)
              .pipe(switchMap((blob) => this.fileReader.getDataUri(blob)))
          : of(undefined),
      ),
    );

    this.fixtureStlModelUrl$ = this.fixture$.pipe(
      switchMap((fixture) =>
        fixture?.fixtureStlUrl
          ? this.fixtureMateService
              .downloadCad(fixture?.fixtureStlUrl)
              .pipe(switchMap((blob) => this.fileReader.getDataUri(blob)))
          : of(undefined),
      ),
    );

    this.partName$ = this.fixture$.pipe(
      map((fixture) => fixture?.name),
      tap((_) => this.adjustHeightByTimeout()),
    );

    window.addEventListener('resize', (event) => {
      this.adjustHeightByTimeout();
    });
    this.adjustHeightByTimeout();
  }

  private adjustHeightByTimeout() {
    if (this.resizeActivated) {
      return;
    }
    this.resizeActivated = true;
    clearTimeout(this.resizeTimeOut);
    this.resizeTimeOut = setTimeout(
      () => this.zone.run(() => this.calculateHeight()),
      this.resizeTimeOutInterval,
    );
  }

  private calculateHeight() {
    this.resizeActivated = false;
    if (
      !this.hostElement?.nativeElement ||
      !this.headerRef?.nativeElement ||
      !this.footerRef?.nativeElement
    ) {
      return;
    }
    const hostHeight = (this.hostElement.nativeElement as HTMLDivElement)?.clientHeight;
    const hostStyles = getComputedStyle(this.hostElement.nativeElement);
    const hostPaddingTop = this.getValueIfPixelsSet(hostStyles.paddingTop);
    const hostPaddingBottom = this.getValueIfPixelsSet(hostStyles.paddingBottom);

    const headerHeight = this.headerRef.nativeElement.offsetHeight;
    const headerStyles = getComputedStyle(this.headerRef.nativeElement);
    const headerMarginTop = this.getValueIfPixelsSet(headerStyles.marginTop);
    const headerMarginBottom = this.getValueIfPixelsSet(headerStyles.marginBottom);

    const footerHeight = this.footerRef.nativeElement.offsetHeight;
    const footerStyles = getComputedStyle(this.footerRef.nativeElement);
    const footerMarginTop = this.getValueIfPixelsSet(footerStyles.marginTop);
    const footerMarginBottom = this.getValueIfPixelsSet(footerStyles.marginBottom);

    if (hostHeight !== undefined && headerHeight !== undefined && footerHeight !== undefined) {
      this.modelContainerHeight =
        hostHeight -
        hostPaddingTop -
        hostPaddingBottom -
        headerHeight -
        headerMarginTop -
        headerMarginBottom -
        footerHeight -
        footerMarginTop -
        footerMarginBottom -
        10;
    }
  }

  /**
   * Reads css value if unit is pixel, like '100px', otherwise returns 0
   */
  private getValueIfPixelsSet(styleValue: string): number {
    if (styleValue.match(/^\d*px/)) {
      return +styleValue.replace('px', '');
    }
    return 0;
  }
}
