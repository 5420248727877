import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { WeldingPrinciplesService } from '../../services/welding-principles/welding-principles.service';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';
import { CategoryToPrinciples } from '../../types';

const WELDING_PRINCIPLES_PATH = '/welding-principles';
const OVERVIEW_PATH = WELDING_PRINCIPLES_PATH + '/';
const DETAILS_PATH = WELDING_PRINCIPLES_PATH + '/details/';

@Component({
  selector: 'lsb-welding-principles',
  templateUrl: './welding-principles.component.html',
  styleUrls: ['./welding-principles.component.scss'],
})
export class WeldingPrinciplesComponent {
  public readonly uiElementIds = UiElementIds;
  public categoryToPrinciples$: Observable<CategoryToPrinciples[] | undefined> | undefined;
  public selectedId$: Observable<Maybe<string>>;
  private routeChange = new BehaviorSubject<Maybe<string>>(undefined);

  constructor(
    private service: WeldingPrinciplesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const selectionId = of(this.router.url).pipe(
      map((url) =>
        url.startsWith(DETAILS_PATH)
          ? url.substring(DETAILS_PATH.length)
          : url.substring(OVERVIEW_PATH.length),
      ),
    );

    const routeChanges = router.events.pipe(
      filter((routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
      filter((routerEvent) => routerEvent.url.startsWith(OVERVIEW_PATH)),
    );

    const currentPrincipleId = routeChanges.pipe(
      filter((routerEvent) => routerEvent.url.startsWith(DETAILS_PATH)),
      map((routerEvent) => routerEvent.url.substring(DETAILS_PATH.length)),
    );

    const currentCategoryId = routeChanges.pipe(
      filter((routerEvent) => !routerEvent.url.startsWith(DETAILS_PATH)),
      map((routerEvent) => routerEvent.url.substring(OVERVIEW_PATH.length)),
    );

    this.categoryToPrinciples$ = this.service.getCategoryToPrinciples().pipe(
      tap((categoryToPrinciples) => {
        if (this.navigatedToBasePath && categoryToPrinciples.length > 0) {
          const targetId = categoryToPrinciples[0].category.id;

          this.router.navigate([targetId], {
            relativeTo: this.route,
          });
          this.routeChange.next(targetId);
        }
      }),
    );

    this.selectedId$ = merge(
      selectionId,
      currentCategoryId,
      currentPrincipleId,
      this.routeChange,
      /*
         hint: langju:
         apply filter since route changes behavior subject is initially undefined.
         still we need it to be a behavior subject (not only subject) so that it
         keeps it state and renotifies the component on changes.
      */
    ).pipe(filter((v) => v !== undefined));
  }

  private get navigatedToBasePath(): boolean {
    return this.router.url === WELDING_PRINCIPLES_PATH;
  }
}
