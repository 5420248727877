import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslationHelper } from '../../../shared/helpers';
import { IconSize } from '../../../shared/types';
import {
  ConfigurationWizardInfo,
  MachineConfigComponentItem,
  MachineConfigDesk,
  MachineConfiguration,
} from '../../types';
import { getLayoutResourceUrl } from '../../utility';
import { LayoutRulesService } from './layout-rules.service';
import { CabinPlateState } from './rules-data/cabin-plate-state';

/**
 * Component for Wizard step two tab - cabin options
 */
@Component({
  selector: 'lsb-configuration-wizard-step-two',
  templateUrl: './configuration-wizard-step-two.component.html',
  styleUrls: ['./configuration-wizard-step-two.component.scss'],
})
export class ConfigurationWizardStepTwoComponent implements OnInit, OnDestroy {
  @Input() configurationWizardInfo: ConfigurationWizardInfo;
  @Output() configurationChange = new EventEmitter<MachineConfiguration>();

  public configuration: MachineConfiguration;
  public layoutRulesService: LayoutRulesService;
  public cabinPlateState: CabinPlateState = {
    demellerPlateEnabled: true,
    zeroPointClampingSystemEnabled: true,
  };

  public MachineConfigDesk = MachineConfigDesk;
  public IconSize = IconSize;

  constructor(public readonly translations: TranslationHelper) {}

  ngOnInit(): void {
    this.configuration = this.configurationWizardInfo.configuration;
    this.layoutRulesService = new LayoutRulesService(
      this.configuration.cabinOptions.cabinLayout,
      this.configuration.cabinOptions.robotType,
      this.configuration.componentOptions,
      this.cabinPlateState,
      this.configurationWizardInfo.setComponentOptionsInitialState,
    );
  }

  ngOnDestroy(): void {
    // When leaving the component, setting the flag that we should not reset the initial state anymore
    this.configurationWizardInfo.setComponentOptionsInitialState = false;
  }

  public get layoutIllustrationUrl() {
    return (
      getLayoutResourceUrl(
        this.configuration.machineModelName,
        this.configuration.cabinOptions,
        this.configuration.componentOptions,
        'image',
      ) ?? ''
    );
  }

  componentItemCheckedChanged(item: MachineConfigComponentItem) {
    this.layoutRulesService.setOptionChecked(item.tag, item.isChecked);
    this.configurationChange.emit(this.configuration);
  }

  componentItemPositionChanged(item: MachineConfigComponentItem) {
    if (item.position === undefined) {
      throw new Error(`Cannot set undefined item-position.`);
    }

    this.layoutRulesService.setOptionPosition(item.tag, item.position);
    this.configurationChange.emit(this.configuration);
  }

  deskOptionSelectionChanged(selection: MachineConfigDesk) {
    this.configuration.componentOptions.deskRef.value = selection;
    this.configurationChange.emit(this.configuration);
  }
}
