import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { PartDetails, WeldingPrinciple } from '../../../../../shared';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';
import { CadUrlPipe } from '../../shared/pipe/cad-url.pipe';
import { AuthService } from '../../shared/services/auth/auth.service';
import { UiElementIds } from '../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-part-fixture',
  templateUrl: './part-fixture.component.html',
  styleUrls: ['./part-fixture.component.scss'],
})
export class PartFixtureComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  public selectedMediaIndex = 0;
  public relatedWeldingPrinciples$: Observable<WeldingPrinciple[]> | undefined;
  public relatedFixturePrinciplesToShow = 4;
  public updatePrinciples$: Subject<void> = new Subject();

  @Input() partDetails: PartDetails;

  constructor(
    public translations: TranslationHelper,
    private router: Router,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.selectedMediaIndex = 0;

    const principles$ = from([this.partDetails.relatedFixturePrinciples]);

    const initialPrinciples$ = principles$.pipe(
      map((principles) => principles.slice(0, this.relatedFixturePrinciplesToShow)),
    );

    // TODO: langju: same mechanism as in part-detail component -> extract logic and share
    // Wait for update trigger to fetch remaining related principles
    const remainingParts$ = combineLatest([this.updatePrinciples$, principles$]).pipe(
      map(([, principles]) => principles.slice(this.relatedFixturePrinciplesToShow)),
    );

    this.relatedWeldingPrinciples$ = combineLatest([
      initialPrinciples$.pipe(startWith([])),
      remainingParts$.pipe(startWith([])),
    ]).pipe(map(([initialParts, remainingParts]) => [...initialParts, ...remainingParts]));
  }

  public async downloadCAD(): Promise<void> {
    const isLoggedIn = await this.authService.isAuthenticated$.pipe(take(1)).toPromise();

    if (!isLoggedIn) {
      return this.authService.login();
    }

    const cadUrlPipe = new CadUrlPipe();
    const cadUrl = cadUrlPipe.transform(this.partDetails.cadFileUrlFixture);
    const cadFileName = `${this.partDetails.name}-Fixture.STEP`;

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', cadUrl);
    link.setAttribute('download', cadFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public selectRelatedPrinciple(principleId: string) {
    this.router.navigate(['welding-principles/details', principleId]);
  }
}
