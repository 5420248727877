import { Pipe, PipeTransform } from '@angular/core';
import { TranslationKeys } from '../../shared/helpers';
import { RobotType } from '../types';

@Pipe({
  name: 'robotTypeToTranslationKey',
})
export class RobotTypeToTranslationKeyPipe implements PipeTransform {
  transform(value: RobotType | undefined): string {
    if (value == null) {
      return '';
    }

    switch (value) {
      case 'ION':
        return TranslationKeys.CONFIGURATOR.ROBOTS.KR30_HA_IONTEC;
      case 'REGULAR':
        return TranslationKeys.CONFIGURATOR.ROBOTS.KR30_HA;
      default:
        throw new Error(`Unknown robot type "${value}"`);
    }
  }
}
