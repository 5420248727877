import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PartCalculation } from '@shared/types';
import { map, take } from 'rxjs/operators';
import { TranslationHelper } from '../../shared/helpers';
import { CalculationDataService } from './calculation/calculation-data.service';

/**
 * Service that generates sequential calculation names by looking for existing calculations,
 * filtering the highest calculation name number and incrementing this by one.
 *
 * ---
 * **Example:**
 *
 * - Existing calculations: `"My Calc"`, `"Calculation 2"`, `"Calc 4"`
 * - Produces next calculation name: `"Calculation 5"`
 *
 * ~~~ts
 * const name = await calculationNameService.getNextName();
 * // -> Calculation 3
 * ~~~
 */
@Injectable({ providedIn: 'root' })
export class CalculationNameService {
  constructor(
    private calculationData: CalculationDataService,
    private translations: TranslationHelper,
    private translate: TranslateService,
  ) {}

  public async getNextName(): Promise<string> {
    return this.translate
      .get(this.translations.CALCULATOR.WIZARD.TAB_GENERAL.NAME_DEFAULT_VALUE)
      .pipe(
        take(1),
        map((translated) => {
          const snapshot = this.calculationData.snapshot;
          const greatestId = this.findGreatestId(snapshot);
          const name = translated + ' ' + (greatestId + 1);
          return name;
        }),
      )
      .toPromise();
  }

  private findGreatestId(calculations: readonly PartCalculation[]): number {
    const OneOrMoreNumbersRegex = /\d+/g;
    let greatest = 0;

    calculations.forEach((calculations) => {
      const name = calculations.name;
      const numbers = name.match(OneOrMoreNumbersRegex) ?? [];

      // hint @langju: reset regex index, since it has global-flag (and thus is stateful)
      OneOrMoreNumbersRegex.lastIndex = 0;

      for (const numberAsString of numbers) {
        const number = parseInt(numberAsString, 10);
        greatest = Math.max(number, greatest);
      }
    });

    return greatest;
  }
}
