import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PartCalculationResult, WeldingMethodParameters, WeldingTechnology } from '@shared/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { IconSize, NumberItem } from '../../../shared/types';
import { PartCalculationRow } from '../part-calculation-table/part-calculation-table.component';

@Component({
  selector: 'lsb-welding-method-costs-summary-item',
  templateUrl: './welding-method-costs-summary-item.component.html',
  styleUrls: ['./welding-method-costs-summary-item.component.scss'],
})
export class WeldingMethodCostsSummaryItemComponent {
  protected bindablePropertyName = undefined;

  @Input() method: WeldingTechnology = 'laser';
  @Input() title: string;
  @Input() calculationResult: Observable<PartCalculationRow>;
  @Input() checked = false;
  @Input() lineColor = 'black';
  @Input() parameters: WeldingMethodParameters;
  @Output() mainTimeParallelLoadingChange = new EventEmitter<boolean>();

  readonly IconSize = IconSize;

  costsPerPart$: Observable<string>;
  costsTotal$: Observable<string>;

  costsPerPartDetails$: Observable<NumberItem[]>;
  costsTotalDetails$: Observable<NumberItem[]>;

  unit = '€';

  private translate = {
    perPartTooltip: {
      weldingTime: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.WELDING_TIME,
      weldingCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.WELDING_COST,
      setupCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.SETUP_COSTS,
      tackingCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.TACKING_COSTS,
      measureCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.MEASURE_COSTS,
      loadingCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.LOADING_COSTS,
      reworkCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.PER_PART.REWORK_COSTS,
    },
    totalTooltip: {
      fixtureCosts: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.TOTAL.FIXTURE_COSTS,
      programmingCosts:
        this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.TOTAL.PROGRAMMING_COSTS,
      totalPieces: this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.TOTAL.TOTAL_PIECES,
      totalPiecesCosts:
        this.translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.TOTAL.TOTAL_PIECES_COSTS,
    },
  };

  constructor(public translations: TranslationHelper) {}

  ngOnInit() {
    const definedResult$ = this.calculationResult.pipe(filter((result) => !!result));

    this.costsPerPart$ = definedResult$.pipe(map(({ result }) => this.getCostsPerPart(result)));

    this.costsTotal$ = definedResult$.pipe(map(({ result }) => this.getCostsTotal(result)));

    this.costsPerPartDetails$ = definedResult$.pipe(
      map(({ result }) => this.getCostsPerPartDetails(result)),
    );

    this.costsTotalDetails$ = definedResult$.pipe(
      map(({ result }) => this.getCostsTotalDetails(result)),
    );
  }

  private getCostsPerPart(result: PartCalculationResult): string {
    const costsAsNumber = this.method === 'arc' ? result.costsPerPartArc : result.costsPerPartLaser;
    return costsAsNumber.toString();
  }

  private getCostsTotal(result: PartCalculationResult): string {
    const costsAsNumber = this.method === 'arc' ? result.totalCostsArc : result.totalCostsLaser;
    return costsAsNumber.toString();
  }

  private getCostsPerPartDetails(result: PartCalculationResult): NumberItem[] {
    const details = this.method === 'arc' ? result.detailsPerPartArc : result.detailsPerPartLaser;

    return [
      {
        translationKey: this.translate.perPartTooltip.weldingTime,
        value: details.weldingTime,
        unit: 'sec',
      },
      {
        translationKey: this.translate.perPartTooltip.weldingCosts,
        value: details.weldingCosts,
        unit: '€/p',
      },
      {
        translationKey: this.translate.perPartTooltip.setupCosts,
        value: details.setupCosts,
        unit: '€/p',
      },
      {
        translationKey: this.translate.perPartTooltip.tackingCosts,
        value: details.tackingCosts,
        unit: '€/p',
      },
      {
        translationKey: this.translate.perPartTooltip.measureCosts,
        value: details.measureCosts,
        unit: '€/p',
      },
      {
        translationKey: this.translate.perPartTooltip.loadingCosts,
        value: details.loadingCosts,
        unit: '€/p',
      },
      {
        translationKey: this.translate.perPartTooltip.reworkCosts,
        value: details.reworkCosts,
        unit: '€/p',
      },
    ];
  }

  private getCostsTotalDetails(result: PartCalculationResult): NumberItem[] {
    const details = this.method === 'arc' ? result.detailsTotalArc : result.detailsTotalLaser;

    return [
      {
        translationKey: this.translate.totalTooltip.fixtureCosts,
        value: details.fixtureCosts,
        unit: '€',
      },
      {
        translationKey: this.translate.totalTooltip.programmingCosts,
        value: details.programmingCosts,
        unit: '€',
      },
      {
        translationKey: this.translate.totalTooltip.totalPieces,
        value: details.totalPieces,
        unit: ' ',
      },
      {
        translationKey: this.translate.totalTooltip.totalPiecesCosts,
        value: details.totalPiecesCosts,
        unit: '€',
      },
    ];
  }
}
