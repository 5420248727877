import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'securedImage',
})
export class SecuredImagePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(imageUrl: Maybe<string> | null): Observable<SafeUrl | undefined> {
    if (imageUrl === null || imageUrl === undefined) {
      return of(undefined);
    }
    // Check if url is relative, then add the origin location
    if (imageUrl.indexOf('http://') < 0 && imageUrl.indexOf('https://') < 0) {
      imageUrl = `${window.location.origin}${imageUrl}`;
    }

    return this.http
      .get(imageUrl, { responseType: 'blob' })
      .pipe(map((val: any) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
