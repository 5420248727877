import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationHelper, TranslationKeys } from '../../../shared/helpers';
import { NozzleType } from '../../mapper/nozzle-type';
import { OpticalType } from '../../mapper/optical-type';
import { ConfigurationWizardView, MachineConfiguration } from '../../types';
import { getOpticResourceUrl } from '../../utility';

export const OPTIC_KEYS = {
  rotaryModule: TranslationKeys.CONFIGURATOR.DATA.OPTIC_OPTIONS.ROTARY_MODULE,
  teachLine: TranslationKeys.CONFIGURATOR.DATA.OPTIC_OPTIONS.TEACH_LINE,
  fusionLine: TranslationKeys.CONFIGURATOR.DATA.OPTIC_OPTIONS.FUSION_LINE,
  wire: TranslationKeys.CONFIGURATOR.DATA.OPTIC_OPTIONS.WIRE,
};

@Component({
  selector: 'lsb-optic-configuration',
  templateUrl: './optic-configuration.component.html',
  styleUrls: ['./optic-configuration.component.scss'],
})
export class OpticConfigurationComponent implements ConfigurationWizardView {
  @Input() configuration: MachineConfiguration;
  @Output() configurationChange = new EventEmitter<MachineConfiguration>();
  @Output() navigateTo = new EventEmitter<string>();

  public get cabinIllustrationUrl() {
    return getOpticResourceUrl(this.configuration?.opticNozzleOptions, 'image') ?? '';
  }

  public readonly OpticalType = OpticalType;
  public readonly NozzleType = NozzleType;
  public readonly translations = {
    opticalType: this.translate.CONFIGURATOR_WIZARD.OPTIC_CONFIGURATION_CONTENT.OPTICAL_TYPE,
    optics: {
      beoD70: this.translate.CONFIGURATOR.OPTICS.BEO_D70,
      pfoSF20: this.translate.CONFIGURATOR.OPTICS.PFO_SF20,
    },
    options: this.translate.CONFIGURATOR_WIZARD.OPTIC_CONFIGURATION_CONTENT.OPTIONS,
    gasNozzle: this.translate.CONFIGURATOR_WIZARD.OPTIC_CONFIGURATION_CONTENT.GAS_NOZZLE,
    linearNozzle: this.translate.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.LINEAR_NOZZLE,
    perlatorNozzle: this.translate.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.PERLATOR_NOZZLE,
    coaxialNozzle: this.translate.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.COAXIAL_NOZZLE,
    noNozzle: this.translate.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.NONE,
    rotaryModule: this.translate.CONFIGURATOR.DATA.OPTIC_OPTIONS.ROTARY_MODULE,
    teachLine: this.translate.CONFIGURATOR.DATA.OPTIC_OPTIONS.TEACH_LINE,
    fusionLine: this.translate.CONFIGURATOR.DATA.OPTIC_OPTIONS.FUSION_LINE,
    wire: this.translate.CONFIGURATOR.DATA.OPTIC_OPTIONS.WIRE,
  };

  constructor(private translate: TranslationHelper) {}

  onOptionsChange(name: 'teachline' | 'fusionline' | 'wire', checked: boolean): void {
    switch (name) {
      case 'teachline':
        this.configuration.opticNozzleOptions.opticOptions = {
          ...this.configuration.opticNozzleOptions.opticOptions,
          teachLine: checked,
        };
        break;
      case 'fusionline':
        this.configuration.opticNozzleOptions.opticOptions = {
          ...this.configuration.opticNozzleOptions.opticOptions,
          fusionLine: checked,
        };

        if (!checked) {
          this.configuration.opticNozzleOptions.opticOptions.wire = false;
        }
        break;
      case 'wire':
        this.configuration.opticNozzleOptions.opticOptions = {
          ...this.configuration.opticNozzleOptions.opticOptions,
          wire: checked,
        };
        break;
    }

    this.configurationChange.emit(this.configuration);
  }

  onNozzleChange(machineConfigNozzleType: string): void {
    this.configuration.opticNozzleOptions.nozzleType = machineConfigNozzleType;
    this.configuration.opticNozzleOptions.opticOptions.rotaryModule =
      machineConfigNozzleType !== NozzleType.Coaxial;
    this.configurationChange.emit(this.configuration);
  }

  setOptic(opticalType: string) {
    this.configuration.opticNozzleOptions.opticalType = opticalType;
  }
}
