import { LocalStorageService } from '@trumpf-xguide/xguide';
import { Persistable, PersistenceStrategy, StoredEntity } from '../types';

const JSON_EMPTY_ARRAY = '[]';

export class LocalStoragePersistenceStrategy<T extends Persistable>
  implements PersistenceStrategy<T>
{
  constructor(private localStorage: LocalStorageService, private storageKey: string) {}

  async save(data: T[]): Promise<void> {
    this.localStorage.set(this.storageKey, data);
  }

  async load(): Promise<StoredEntity<T>[]> {
    const data = this.localStorage.get(this.storageKey) ?? JSON_EMPTY_ARRAY;
    return JSON.parse(data);
  }
}
