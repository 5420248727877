import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements as defineCustomElementsUxUiCore } from '@trumpf/ux.ui/loader';
import { defineCustomElements as defineCustomElementsUxUiCommunity } from '@trumpf/ux.ui-community/loader';
import { defineCustomElements as defineCustomElementsUxAppFramework } from '@trumpf/ux.application-framework/loader';

if (environment.production) {
  enableProdMode();
}

defineCustomElementsUxUiCore();
defineCustomElementsUxUiCommunity();
defineCustomElementsUxAppFramework();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
