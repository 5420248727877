import { Injectable } from '@angular/core';
import { WeldingTechnology } from '@shared/types';
import { LocalStorageService } from '@trumpf-xguide/xguide';
import { LocalStorageKeys } from '../../../constants';
import { LocalStoragePersistenceStrategy } from '../../../shared/data-persistence/strategies/local-storage.persistence';
import { DataMigrationRule, StoredEntity } from '../../../shared/data-persistence/types';
import { VersionedDataPersistenceService } from '../../../shared/data-persistence/versioned-data-persistence.service';
import { TranslationKeys } from '../../../shared/helpers';
import { MachineConfigComponentItem, MachineConfiguration, WeldingMachineModel } from '../../types';
import { createConfiguration } from '../../utility';

/**
 * Service that reads/saves configurator data from/to Browser local storage
 */
@Injectable({ providedIn: 'root' })
export class ConfiguratorDataAccessService extends VersionedDataPersistenceService<MachineConfiguration> {
  protected migrationRules: DataMigrationRule[] = [
    {
      migrateToVersion: 1,
      migrate: migrateMachineConfigToV1,
    },
    {
      migrateToVersion: 2,
      migrate: migrateMachineConfigToV2,
    },
    {
      migrateToVersion: 3,
      migrate: migrateMachineConfigToV3,
    },
    {
      migrateToVersion: 4,
      migrate: migrateMachineConfigToV4,
    },
  ];

  constructor(localStorageService: LocalStorageService) {
    super(
      new LocalStoragePersistenceStrategy<MachineConfiguration>(
        localStorageService,
        LocalStorageKeys.configurator.database,
      ),
    );
  }

  public override create(value: MachineConfiguration): Promise<StoredEntity<MachineConfiguration>> {
    const configWithCountedUpId = { ...value, id: this.getNextId() };
    return super.create(configWithCountedUpId);
  }

  public createEmpty(
    machineModelName: WeldingMachineModel,
    weldingTechnology: WeldingTechnology,
  ): MachineConfiguration {
    const id = this.getNextId();
    return createConfiguration(machineModelName, weldingTechnology, id);
  }

  private getNextId() {
    if (this._data == undefined || this._data.length === 0) {
      return '1';
    }

    const maxId = Math.max(...this._data.map((c) => parseInt(c.id, 10)));

    if (isNaN(maxId)) {
      return (this._data.length + 1).toString();
    } else {
      return (maxId + 1).toString();
    }
  }
}

//#region migration functions
export function migrateMachineConfigToV4(data: MachineConfiguration): MachineConfiguration {
  if (data.machineModelName !== 'TruLaser Weld 5000') {
    return data;
  }

  removeImagesFromComponents(data);
  insertCompactRotaryTableToComponents(data);

  return data;
}

export function migrateMachineConfigToV3(data: MachineConfiguration): MachineConfiguration {
  if (
    data.machineModelName === 'TruLaser Weld 1000' ||
    data.machineModelName === 'TruArc Weld 1000'
  ) {
    data.cabinOptions.robotType = 'REGULAR';
  }

  return data;
}

export function migrateMachineConfigToV2(data: MachineConfiguration): MachineConfiguration {
  if (!data.cabinOptions.robotType) {
    data.cabinOptions.robotType = 'REGULAR';
  }

  return data;
}

export function migrateMachineConfigToV1(data: MachineConfiguration): MachineConfiguration {
  if (!data.machineModelName) {
    data.machineModelName = 'TruLaser Weld 5000';
  }
  if (!data.weldingTechnology) {
    data.weldingTechnology = 'laser';
  }

  if (data.opticNozzleOptions.opticalType === 'BE0_D70') {
    data.opticNozzleOptions.opticalType = 'BEO_D70';
  }

  return data;
}
//#endregion

//#region migration helper methods
function insertCompactRotaryTableToComponents(data: MachineConfiguration) {
  const componentTags = TranslationKeys.CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM;

  const components = [...data.componentOptions.componentItems];
  const hasCompactRotaryTable =
    components.find((component) => component.tag === componentTags.RC) != null;

  if (!hasCompactRotaryTable) {
    const rotaryTableIndex = components.findIndex(
      (component) => component.tag === componentTags.DT,
    )!;
    const copyOfRotaryTable: MachineConfigComponentItem = { ...components[rotaryTableIndex] };
    const compactRotaryTable: MachineConfigComponentItem = {
      ...copyOfRotaryTable,
      isChecked: false,
      tag: componentTags.RC,
    };

    const indexIncludingRotaryTableComponent = rotaryTableIndex + 1;

    data.componentOptions.componentItems = [
      ...components.slice(0, indexIncludingRotaryTableComponent),
      compactRotaryTable,
      ...components.slice(indexIncludingRotaryTableComponent),
    ];
  }
}

function removeImagesFromComponents(data: MachineConfiguration) {
  data.componentOptions.componentItems = data.componentOptions.componentItems.map((component) => {
    // hint: 'image' property is no longer needed as we statically map components to their images in HTML template; thus removing it:
    if ('image' in component) {
      delete (component as any).image;
    }

    return component;
  });

  return data;
}
//#endregion
