export default {
  XGUIDE: {
    DASHBOARD: {
      GETTING_STARTED: {
        TITLE: 'TITLE',
        TAKE_THE_TOUR: 'TAKE_THE_TOUR',
      },
    },
    MEDIA: {
      VIDEO_NOT_SUPPORTED: 'VIDEO_NOT_SUPPORTED',
    },
    PRIVACY: {
      TITLE: 'TITLE',
      PARAGRAPH1: 'PARAGRAPH1',
      CHANGE_SETTINGS_LINK: 'CHANGE_SETTINGS_LINK',
      PARAGRAPH2: 'PARAGRAPH2',
      PRIVACY_POLICY: 'PRIVACY_POLICY',
      PRIVACY_POLICY_HREF: 'PRIVACY_POLICY_HREF',
      IMPRINT: 'IMPRINT',
      DETAILED: {
        PARAGRAPH1: 'PARAGRAPH1',
        PARAGRAPH2: 'PARAGRAPH2',
        PARAGRAPH3: 'PARAGRAPH3',
        ALLOW_COOKIES_LABEL: 'ALLOW_COOKIES_LABEL',
        FURTHER_DETAILS: 'FURTHER_DETAILS',
      },
    },
    DIALOG: {
      OK: 'OK',
      SAVE: 'SAVE',
      BACK: 'BACK',
      NEXT: 'NEXT',
      CANCEL: 'CANCEL',
      FINISH: 'FINISH',
    },
    MENU: { CONTACT_US_BUTTON: 'CONTACT_US_BUTTON' },
  },
  ENUMS: {
    SYSTEM_OF_UNITS: {
      METRIC: 'METRIC',
      IMPERIAL: 'IMPERIAL',
    },
    CURRENCY: {
      EURO: 'EURO',
      USDOLLAR: 'USDOLLAR',
    },
  },
  TEMPLATES: {
    HINT: 'HINT',
  },
  UNSUPPORTED_BROWSER: {
    TITLE: 'TITLE',
    MESSAGE: 'MESSAGE',
  },
  DIALOG: {
    BACK: 'BACK',
    NEXT: 'NEXT',
    CANCEL: 'CANCEL',
    FINISH: 'FINISH',
    OK: 'OK',
    SAVE: 'SAVE',
  },
  MAIN_MENU: {
    WELDING_PRINCIPLES: 'WELDING_PRINCIPLES',
    PARTS: 'PARTS',
    CONFIGURATOR: 'CONFIGURATOR',
    CALCULATOR: 'CALCULATOR',
    FIXTURE_MATE: 'FIXTURE_MATE',
  },
  CONFIGURATOR: {
    TITLE: 'TITLE',
    DESCRIPTION: 'DESCRIPTION',
    COMING_UP_SOON: 'COMING_UP_SOON',
    CONFIGURE_NEW_MACHINE: 'CONFIGURE_NEW_MACHINE',
    LOAD_CONFIGURATION: 'LOAD_CONFIGURATION',
    CREATE_CONFIGURATION: 'CREATE_CONFIGURATION',
    MACHINES: {
      TRU_ARC_WELD_1000: 'TRU_ARC_WELD_1000',
      TRU_LASER_WELD_1000: 'TRU_LASER_WELD_1000',
      TRU_LASER_WELD_5000: 'TRU_LASER_WELD_5000',
    },
    OPTICS: {
      BEO_D70: 'BEO_D70',
      PFO_SF20: 'PFO_SF20',
    },
    ROBOTS: {
      KR30_HA_IONTEC: 'KR30_HA_IONTEC',
      KR30_HA: 'KR30_HA',
    },
    CONFIGURATION_BUTTON_TOOLTIP: 'CONFIGURATION_BUTTON_TOOLTIP',
    LIST_ITEM: {
      OPTICAL_TYPE: 'OPTICAL_TYPE',
      GAS_NOZZLE: 'GAS_NOZZLE',
      OPTIONS: 'OPTIONS',
      EDIT: 'EDIT',
      DELETE: 'DELETE',
      LAYOUT: 'LAYOUT',
      LAYOUT_SIZE: 'LAYOUT_SIZE',
      TURN_TILT_POSITIONER: 'TURN_TILT_POSITIONER',
      MANUAL_TURN_TABLE: 'MANUAL_TURN_TABLE',
      ROTARY_CHANGER: 'ROTARY_CHANGER',
      TURNING_AXIS: 'TURNING_AXIS',
      LEFT: 'LEFT',
      RIGHT: 'RIGHT',
      NOT_AVAILABLE: '-',
    },
    MECHANICAL_INTERFACE: 'MECHANICAL_INTERFACE',
    EQUIPMENT: 'EQUIPMENT',
    DATA: {
      MACHINE_CONFIG_NOZZLE_TYPE: {
        LINEAR_NOZZLE: 'LINEAR_NOZZLE',
        PERLATOR_NOZZLE: 'PERLATOR_NOZZLE',
        COAXIAL_NOZZLE: 'COAXIAL_NOZZLE',
        NONE: 'NONE',
      },
      MACHINE_CONFIG_DESK: {
        ZERO_POINT_CLAMPING_SYSTEM: 'ZERO_POINT_CLAMPING_SYSTEM',
        DEMELLER_PALLETE: 'DEMELLER_PALLETE',
      },
      MACHINE_CONFIG_COMPONENT_ITEM: {
        DKP: 'DKP',
        DT: 'DT',
        RW: 'RW',
        WP: 'WP',
        RC: 'RC',
      },
      OPTIC_OPTIONS: {
        ROTARY_MODULE: 'ROTARY_MODULE',
        TEACH_LINE: 'TEACH_LINE',
        FUSION_LINE: 'FUSION_LINE',
        WIRE: 'WIRE',
      },
    },
  },
  CONFIGURATOR_WIZARD: {
    ROBOT_SAFETY_CABIN: 'ROBOT_SAFETY_CABIN',
    ROBOT_SAFETY_CABIN_CONTENT: {
      CABIN_LAYOUT: 'CABIN_LAYOUT',
      CABIN_SIZE: 'CABIN_SIZE',
      ROBOT_LABEL: 'ROBOT',
    },
    COMPONENTS_SELECTION: 'COMPONENTS_SELECTION',
    OPTIC_CONFIGURATION: 'OPTIC_CONFIGURATION',
    OPTIC_CONFIGURATION_CONTENT: {
      OPTICAL_TYPE: 'OPTICAL_TYPE',
      OPTIONS: 'OPTIONS',
      GAS_NOZZLE: 'GAS_NOZZLE',
    },
  },
  DASHBOARD: {
    GETTING_STARTED: {
      TOUR_VIDEO_URL: 'TOUR_VIDEO_URL',
    },
    WELCOME: {
      DESCRIPTION: 'DESCRIPTION',
    },
    NEWS: {
      TITLE: 'TITLE',
      NO_NEWS: 'NO_NEWS',
    },
    CALCULATED_PARTS: {
      TITLE_LAST_CALCULATION: 'TITLE_LAST_CALCULATION',
      TITLE_NO_CALCULATION: 'TITLE_NO_CALCULATION',
      ADD_ACTION_LABEL: 'ADD_ACTION_LABEL',
    },
  },
  ABOUT: {
    IMPRINT: 'IMPRINT',
    DATA_PRIVACY: 'DATA_PRIVACY',
    COPYRIGHT: 'COPYRIGHT',
    SETTINGS: 'SETTINGS',
  },
  SETTINGS: {
    LANGUAGE: 'LANGUAGE',
    UNIT_SYSTEM: 'UNIT_SYSTEM',
    CURRENCY: 'CURRENCY',
  },
  IMPRINT: {
    OPERATOR: 'OPERATOR',
    MANAGEMENT: 'MANAGEMENT',
    REGISTER_COURT: 'REGISTER_COURT',
  },
  DATA_PRIVACY: {
    TITLE: 'TITLE',
    PARAGRAPH1: 'PARAGRAPH1',
    PARAGRAPH2: 'PARAGRAPH2',
    PARAGRAPH3: 'PARAGRAPH3',
    LINK_URL: 'LINK_URL',
    LINK_TITLE: 'LINK_TITLE',
    AFTER_LINK_TEXT: 'AFTER_LINK_TEXT',
  },
  COPYRIGHT: {
    TITLE: 'TITLE',
    PARAGRAPH1: 'PARAGRAPH1',
    PARAGRAPH2: 'PARAGRAPH2',
  },
  PARTS: {
    MENU: {
      ALL: 'ALL',
    },
    SPECIFICATION: {
      PARTS_COUNT: 'PARTS_COUNT',
      JOINTS_COUNT: 'JOINTS_COUNT',
      BENDINGS_COUNT: 'BENDINGS_COUNT',
    },
    FILTERS: {
      REQUIREMENTS: 'REQUIREMENTS',
      SEAM_GEOMETRY: 'SEAM_GEOMETRY',
      MATERIAL: 'MATERIAL',
      SHEET_THICKNESS: 'SHEET_THICKNESS',
    },
    CLEAR_ALL_TAGS: 'CLEAR_ALL',
    RELATED_PARTS: 'RELATED_PARTS',
    DOWNLOAD_CAD: 'DOWNLOAD_CAD',
    CALCULATION: 'CALCULATION',
    RELATED_WELDING_PRINCIPLES_TITLE: 'RELATED_WELDING_PRINCIPLES_TITLE',
    FIXTURE: {
      ALTERNATIVE: 'ALTERNATIVE',
      PREBINDERS: 'PREBINDERS',
      FIXTURE_FOR: 'FIXTURE_FOR',
    },
  },
  WELDING_PRINCIPLES: {
    DETAILS: {
      MORE_PARTS: 'MORE_PARTS',
      HELP: {
        TITLE: 'TITLE',
        PARAGRAPH_1: 'PARAGRAPH_1',
        PARAGRAPH_2: 'PARAGRAPH_2',
        LINK_HOMEPAGE: 'LINK_HOMEPAGE',
        LINK_HOMEPAGE_URL: 'LINK_HOMEPAGE_URL',
        MAIL_SUBJECT: 'MAIL_SUBJECT',
      },
    },
  },
  CALCULATOR: {
    CREATE_FROM_EXAMPLE_PART: 'CREATE_FROM_EXAMPLE_PART',
    CREATE_CALCULATION: 'CREATE_CALCULATION',
    CREATE_CALCULATION_HINT: 'CREATE_CALCULATION_HINT',
    DROP_STEP_FILE_HINT: 'DROP_STEP_FILE_HINT',
    PREVIEW: 'PREVIEW',
    NAME: 'NAME',
    TOTAL_PARTS: 'TOTAL_PARTS',
    TOTAL_COSTS: 'TOTAL_COSTS',
    TOTAL_COSTS_ARC: 'TOTAL_COSTS_ARC',
    TOTAL_COSTS_LASER: 'TOTAL_COSTS_LASER',
    LASER_COSTS_PER_PART: 'LASER_COSTS_PER_PART',
    ARC_COSTS_PER_PART: 'ARC_COSTS_PER_PART',
    CALCULATIONS: 'CALCULATIONS',
    HOURLY_RATE: 'HOURLY_RATE',
    ENTRY_EXIT_TIME_PER_SEAM: 'ENTRY_EXIT_TIME_PER_SEAM',
    SAVINGS: 'SAVINGS',
    CREATE_FROM_EXAMPLE_PART_DIALOG: {
      EXAMPLE_PARTS: 'EXAMPLE_PARTS',
    },
    TOOLTIPS: {
      HOURLY_RATE_LASER: 'HOURLY_RATE_LASER',
      HOURLY_RATE_ARC_MANUAL: 'HOURLY_RATE_ARC_MANUAL',
      HOURLY_RATE_ARC_ROBOT: 'HOURLY_RATE_ARC_ROBOT',
      ENTRY_EXIT_TIME_PER_SEAM: 'ENTRY_EXIT_TIME_PER_SEAM',
    },
    CALCULATION_DETAILS: {
      LASER_WELDING: 'LASER_WELDING',
      ARC_WELDING: 'ARC_WELDING',
      ROBOT_WELDING: 'ROBOT_WELDING',
      MAIN_TIME_PARALLEL_LOADING: 'MAIN_TIME_PARALLEL_LOADING',
      LASER_COSTS_PER_PART: 'LASER_COSTS_PER_PART',
      ARC_COSTS_PER_PART: 'ARC_COSTS_PER_PART',
      LASER_COSTS_TOTAL: 'LASER_COSTS_TOTAL',
      ARC_COSTS_TOTAL: 'ARC_COSTS_TOTAL',
      ADVANCED_SETTINGS: 'ADVANCED_SETTINGS',
      TOOLTIP: {
        ROBOT_WELDING: 'ROBOT_WELDING',
        PER_PART: {
          WELDING_TIME: 'WELDING_TIME',
          WELDING_COST: 'WELDING_COST',
          SETUP_COSTS: 'SETUP_COSTS',
          TACKING_COSTS: 'TACKING_COSTS',
          MEASURE_COSTS: 'MEASURE_COSTS',
          LOADING_COSTS: 'LOADING_COSTS',
          REWORK_COSTS: 'REWORK_COSTS',
        },
        TOTAL: {
          FIXTURE_COSTS: 'FIXTURE_COSTS',
          PROGRAMMING_COSTS: 'PROGRAMMING_COSTS',
          TOTAL_PIECES: 'TOTAL_PIECES',
          TOTAL_PIECES_COSTS: 'TOTAL_PIECES_COSTS',
        },
        MAIN_TIME_PARALLEL_LOADING: 'MAIN_TIME_PARALLEL_LOADING',
      },
    },
    WORK_TYPE: {
      MANUAL: 'MANUAL',
      ROBOTER: 'ROBOTER',
    },
    WIZARD: {
      TAB_GENERAL: {
        TITLE: 'TITLE',
        NAME: 'NAME',
        NAME_DEFAULT_VALUE: 'NAME_DEFAULT_VALUE',
        MODEL_PREVIEW_HINT: 'MODEL_PREVIEW_HINT',
        MODEL_FULLSCREEN_HINT: 'MODEL_FULLSCREEN_HINT',
        TOTAL_SEAM_LENGTH: 'TOTAL_SEAM_LENGTH',
        AMOUNT_COMPONENTS: 'AMOUNT_COMPONENTS',
        TOTAL_PIECES: 'TOTAL_PIECES',
        AMOUNT_SEAMS: 'AMOUNT_SEAMS',
        BATCH_SIZE: 'BATCH_SIZE',
        TOOLTIPS: {
          TOTAL_PIECES: 'TOTAL_PIECES',
          BATCH_SIZE: 'BATCH_SIZE',
          TOTAL_SEAM_LENGTH: 'TOTAL_SEAM_LENGTH',
          AMOUNT_SEAMS: 'AMOUNT_SEAMS',
        },
      },
      TAB_LASER: {
        TITLE: 'TITLE',
        FIXTURE_COSTS: 'FIXTURE_COSTS',
        TACKING_TIME: 'TACKING_TIME',
        PROGRAMMING_TIME: 'PROGRAMMING_TIME',
        MEASURE_TIME: 'MEASURE_TIME',
        SETUP_TIME: 'SETUP_TIME',
        WELDING_SPEED: 'WELDING_SPEED',
        REWORKING_TIME: 'REWORKING_TIME',
        LOADING_TIME: 'LOADING_TIME',
        TOOLTIPS: {
          FIXTURE_COSTS: 'FIXTURE_COSTS',
          TACKING_TIME: 'TACKING_TIME',
          PROGRAMMING_TIME: 'PROGRAMMING_TIME',
          MEASURE_TIME: 'MEASURE_TIME',
          SETUP_TIME: 'SETUP_TIME',
          WELDING_SPEED: 'WELDING_SPEED',
          REWORKING_TIME: 'REWORKING_TIME',
          LOADING_TIME: 'LOADING_TIME',
        },
      },
      TAB_ARC: {
        TITLE: 'TITLE',
        FIXTURE_COSTS: 'FIXTURE_COSTS',
        TACKING_TIME: 'TACKING_TIME',
        PROGRAMMING_TIME: 'PROGRAMMING_TIME',
        MEASURE_TIME: 'MEASURE_TIME',
        SETUP_TIME: 'SETUP_TIME',
        WELDING_SPEED: 'WELDING_SPEED',
        REWORKING_TIME: 'REWORKING_TIME',
        LOADING_TIME: 'LOADING_TIME',
        TOOLTIPS: {
          FIXTURE_COSTS: 'FIXTURE_COSTS',
          TACKING_TIME: 'TACKING_TIME',
          PROGRAMMING_TIME: 'PROGRAMMING_TIME',
          MEASURE_TIME: 'MEASURE_TIME',
          SETUP_TIME: 'SETUP_TIME',
          WELDING_SPEED: 'WELDING_SPEED',
          REWORKING_TIME: 'REWORKING_TIME',
          LOADING_TIME: 'LOADING_TIME',
        },
      },
    },
  },
  FIXTURE_MATE: {
    CREATE_FIXTURE: 'CREATE_FIXTURE',
    CREATE_FIXTURE_EXAMPLE: 'CREATE_FIXTURE_EXAMPLE',
    CREATE_FIXTURE_DIMENSIONS: 'CREATE_FIXTURE_DIMENSIONS',
    CREATE_FIXTURE_HINT: 'CREATE_FIXTURE_HINT',
    FIXTURE_HINT_TOOLTIP_LABEL: 'FIXTURE_HINT_TOOLTIP_LABEL',
    FIXTURE_HINT_TOOLTIP_WIDTH: 'FIXTURE_HINT_TOOLTIP_WIDTH',
    FIXTURE_HINT_TOOLTIP_LENGTH: 'FIXTURE_HINT_TOOLTIP_LENGTH',
    FIXTURE_HINT_TOOLTIP_HEIGHT: 'FIXTURE_HINT_TOOLTIP_HEIGHT',
    FIXTURE_HINT_TOOLTIP_MAX_LENGTH: 'FIXTURE_HINT_TOOLTIP_MAX_LENGTH',
    TABLE_COLUMN_HEADER_PART_PREVIEW: 'TABLE_COLUMN_HEADER_PART_PREVIEW',
    TABLE_COLUMN_HEADER_NAME: 'TABLE_COLUMN_HEADER_NAME',
    TABLE_COLUMN_HEADER_CAD_PART: 'TABLE_COLUMN_HEADER_CAD_PART',
    TABLE_COLUMN_HEADER_CAD_STATUS: 'TABLE_COLUMN_HEADER_CAD_STATUS',
    TABLE_COLUMN_HEADER_FIXTURE_PREVIEW: 'TABLE_COLUMN_HEADER_FIXTURE_PREVIEW',
    TABLE_COLUMN_HEADER_CAD_FIXTURE: 'TABLE_COLUMN_HEADER_CAD_FIXTURE',
    DOWNLOAD_BUTTON: 'DOWNLOAD_BUTTON',
    STATUS_AVAILABLE: 'STATUS_AVAILABLE',
    STATUS_IN_PROGRESS: 'STATUS_IN_PROGRESS',
    STATUS_FEEDBACK_REQURED: 'STATUS_FEEDBACK_REQURED',
    STATUS_ERROR: 'STATUS_ERROR',
    VIDEO_CAPTION: 'VIDEO_CAPTION',
    VIDEO_URL: 'VIDEO_URL',
    UPLOAD_AGREEMENT_TITLE: 'UPLOAD_AGREEMENT_TITLE',
    UPLOAD_AGREEMENT_PARAGRAPH_ONE: 'UPLOAD_AGREEMENT_PARAGRAPH_ONE',
    UPLOAD_AGREEMENT_CONDITIONS: 'UPLOAD_AGREEMENT_CONDITIONS',
    UPLOAD_AGREEMENT_CONDITIONS_URL: 'UPLOAD_AGREEMENT_CONDITIONS_URL',
    UPLOAD_AGREEMENT_PARAGRAPH_TWO: 'UPLOAD_AGREEMENT_PARAGRAPH_TWO',
    UPLOAD_AGREEMENT_POLICY: 'UPLOAD_AGREEMENT_POLICY',
    UPLOAD_AGREEMENT_POLICY_URL: 'UPLOAD_AGREEMENT_POLICY_URL',
    UPLOAD_AGREEMENT_PARAGRAPH_THREE: 'UPLOAD_AGREEMENT_PARAGRAPH_THREE',
    UPLOAD_AGREEMENT_BUTTON: 'UPLOAD_AGREEMENT_BUTTON',
    DETAILS_VIEW_EXAMPLE: 'DETAILS_VIEW_EXAMPLE',
    DETAILS_VIEW_PART: 'DETAILS_VIEW_PART',
    DETAILS_VIEW_FIXTURE: 'DETAILS_VIEW_FIXTURE',
    DETAILS_VIEW_FOOTER: 'DETAILS_VIEW_FOOTER',
    DETAILS_VIEW_CONTACT_BUTTON: 'DETAILS_VIEW_CONTACT_BUTTON',
    BOX_DIMENSIONS: {
      TITLE: 'TITLE',
      NAME: 'NAME',
      LENGTH: 'LENGTH',
      WIDTH: 'WIDTH',
      HEIGHT: 'HEIGHT',
      FLANGE_WIDTH_A: 'FLANGE_WIDTH_A',
      FLANGE_WIDTH_B: 'FLANGE_WIDTH_B',
      MATERIAL: 'MATERIAL',
      THICKNESS: 'THICKNESS',
      MATERIAL_VALUE: {
        VA: 'VA',
        ALUMINIUM: 'ALUMINIUM',
        STEEL: 'STEEL',
      },
    },
    BACKEND_ERROR_MESSAGE: 'BACKEND_ERROR_MESSAGE',
  },
};
