import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsageTrackingModule } from '@trumpf-xguide/xguide';
import { PreviewFeaturesService } from '../shared/services/preview-features/preview-features.service';
import { SharedModule } from '../shared/shared.module';
import { CalculatorRoutingModule } from './calculator-routing.module';
import { PartCalculationChartComponent } from './components/part-calculation-chart/part-calculation-chart.component';
import { PartCalculationTableComponent } from './components/part-calculation-table/part-calculation-table.component';
import { WeldingMethodCostsSummaryItemComponent } from './components/welding-method-costs-summary-item/welding-method-costs-summary-item.component';
import { AddExamplePartDialogComponent } from './views/add-example-part-dialog/add-example-part-dialog.component';
import { CalculationBasicsTabComponent } from './views/calculation-wizard/calculation-basics-tab/calculation-basics-tab.component';
import { CalculationParametersTabComponent } from './views/calculation-wizard/calculation-parameters-tab/calculation-parameters-tab.component';
import { CalculationWizardComponent } from './views/calculation-wizard/calculation-wizard.component';
import { CalculationDetailsComponent } from './views/calculator-overview/calculation-details/calculation-details.component';
import { CalculationSettingsSidebarComponent } from './views/calculator-overview/calculation-settings-sidebar/calculation-settings-sidebar.component';
import { CalculatorOverviewComponent } from './views/calculator-overview/calculator-overview.component';

@NgModule({
  imports: [
    // <this comment forces items to be placed below each other>
    CalculatorRoutingModule,
    CommonModule,
    SharedModule,
    UsageTrackingModule.forChild(),
  ],
  declarations: [
    CalculatorOverviewComponent,
    CalculationBasicsTabComponent,
    CalculationWizardComponent,
    CalculationSettingsSidebarComponent,
    CalculationParametersTabComponent,
    PartCalculationTableComponent,
    WeldingMethodCostsSummaryItemComponent,
    CalculationDetailsComponent,
    PartCalculationChartComponent,
    AddExamplePartDialogComponent,
  ],
  providers: [PreviewFeaturesService],
  exports: [PartCalculationChartComponent],
})
export class CalculatorModule {}
