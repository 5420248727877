import { PartCalculation, WeldingTechnology } from '@shared/types';
import { merge } from 'merge-anything';
import { Part } from '../../../../../shared';
import { Storable } from '../../shared/data-persistence/types';
import { createEmptyPartCalculation } from './factories';

export function createCalculationFromPart(part: Part): Storable<PartCalculation> {
  const baseConfig = createEmptyPartCalculation(part.name);

  return merge(baseConfig, {
    configuration: {
      basics: {
        amountSeams: part.amountSeams,
        batchSize: part.batchSize,
        totalPieces: part.totalPieces,
        totalSeamLength: part.totalSeamLengthInMillimeters,
      },
      arcWeldingParameters: {
        fixtureCostsInEur: part.fixtureCostsInEur_arc,
        loadingTimeInSec: part.loadingTimeInSec_arc,
        measureTimeInSec: part.measureTimeInSec_arc,
        programmingTimeInSec: part.programmingTimeInSec_arc,
        reworkTimeInSec: part.reworkTimeInSec_arc,
        setupTimePerBatchInSec: part.setupTimePerBatchInSec_arc,
        tackingTimeInSec: part.tackingTimeInSec_arc,
        weldingSpeedInMillimetersPerSec: part.weldingSpeedInMillimetersPerSec_arc,
      },
      laserWeldingParameters: {
        fixtureCostsInEur: part.fixtureCostsInEur_laser,
        loadingTimeInSec: part.loadingTimeInSec_laser,
        measureTimeInSec: part.measureTimeInSec_laser,
        programmingTimeInSec: part.programmingTimeInSec_laser,
        reworkTimeInSec: part.reworkTimeInSec_laser,
        setupTimePerBatchInSec: part.setupTimePerBatchInSec_laser,
        tackingTimeInSec: part.tackingTimeInSec_laser,
        weldingSpeedInMillimetersPerSec: part.weldingSpeedInMillimetersPerSec_laser,
      },
    },
    parameters: {
      arc: {
        robotWelding: part.robotWelding,
      },
      selectedTechnology: 'arc' as WeldingTechnology,
    },
  });
}
