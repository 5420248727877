import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslationHelper } from '../../shared/helpers';

@Pipe({ name: 'componentTagToImage' })
export class ComponentTagToImagePipe implements PipeTransform {
  public readonly componentTags =
    inject(TranslationHelper).CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM;

  transform(value: string): string | null {
    if (value === this.componentTags.DKP) {
      return '../../assets/img/configurator/01_DKP_Dreh-Kipp-Positionierer_Step2.JPG';
    } else if (value === this.componentTags.DT) {
      return '../../assets/img/configurator/02_DT_Drehtisch_Step2.JPG';
    } else if (value === this.componentTags.RC) {
      return '../../assets/img/configurator/RC_Kompackter_Rotationswechsler_Step2.JPG';
    } else if (value === this.componentTags.RW) {
      return '../../assets/img/configurator/03_RW_Automatischer_Rotationswechsler_Step2.JPG';
    } else if (value === this.componentTags.WP) {
      return '../../assets/img/configurator/04_WP_Wendepositionierer_Step2.JPG';
    }

    return null;
  }
}
