<lsb-text type="headline" class="description">
  {{ translations.CONFIGURATOR.CREATE_CONFIGURATION | translate }}
</lsb-text>

<section class="machines">
  <xui-card
    data-ngtx="create-configuration:taw1000"
    title="TruArc Weld 1000"
    [preview]="'MASCHINES/TAW1000.JPG' | imageUrl"
    [xuiClickUsageTracking]="uiElementIds.CONFIGURATOR_NEW_MACHINE_TAW1000"
    (click)="emitCreateConfiguration('TruArc Weld 1000', 'arc')"
  >
    {{ translations.CONFIGURATOR.MACHINES.TRU_ARC_WELD_1000 | translate }}
  </xui-card>
  <xui-card
    data-ngtx="create-configuration:tlw1000"
    title="TruLaser Weld 1000"
    [preview]="'MASCHINES/TLW1000.JPG' | imageUrl"
    [xuiClickUsageTracking]="uiElementIds.CONFIGURATOR_NEW_MACHINE_TLW1000"
    (click)="emitCreateConfiguration('TruLaser Weld 1000', 'laser')"
  >
    {{ translations.CONFIGURATOR.MACHINES.TRU_LASER_WELD_1000 | translate }}
  </xui-card>
  <xui-card
    data-ngtx="create-configuration:tlw5000"
    title="TruLaser Weld 5000"
    [preview]="'MASCHINES/TLW5000.JPG' | imageUrl"
    [xuiClickUsageTracking]="uiElementIds.CONFIGURATOR_NEW_MACHINE_TLW5000"
    (click)="emitOpenWizard()"
  >
    {{ translations.CONFIGURATOR.MACHINES.TRU_LASER_WELD_5000 | translate }}
  </xui-card>
</section>
