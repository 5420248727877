import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from '../helpers';
import { PersistenceStrategy, StoredEntity } from './types';

/**
 * Encapsulates logic to do `CRUD` (create, read, update, delete) operations with data to a exchangable target-data-storage.
 * This service is not intended to be used directly, subclass it to use.
 *
 * ---
 * **Please note:** Data with an id field of other than type `string` | `undefined` is not supported.
 */
export abstract class DataPersistenceServiceBase<T extends object> {
  protected _data: StoredEntity<T>[] = [];
  protected _dataStream = new BehaviorSubject<StoredEntity<T>[]>([]);

  /** Readonly property providing the data as observable, enabling users to always retrieve the latest data-set. */
  public get data$(): Observable<StoredEntity<T>[]> {
    return this._dataStream.asObservable();
  }

  /** Provides a readonly decoupled snapshot of current data state. */
  public get snapshot(): ReadonlyArray<StoredEntity<T>> {
    return this._dataStream.getValue();
  }

  /**
   * Creates a new instance of the `DataPersistenceService` class.
   * @param strategy The target persistence-storage to write to and read from.
   */
  constructor(protected readonly strategy: PersistenceStrategy<T>) {
    strategy.load().then((data) => {
      this.onInitialDataLoad(data);
    });
  }

  /**
   * Called when the data source is initially loaded. Sets the local state
   * @param data The data that has been loaded from the data source.
   */
  protected onInitialDataLoad(data: StoredEntity<T>[]): void {
    /* 
        when this service is the only one manipulating the source,
        we can assume that every item has an id => is of type StoredEntity<T>:
    */
    this._data = data as StoredEntity<T>[];
    this.applyChanges(false);
  }

  /**
   * Finds and updates the data passed in, or creates it, if not found.
   *
   * ---
   * **Please note:** Data with an id field of other than type `string` | `undefined` is not supported.
   *
   * ---
   * @param data The data to find and update, or create if not found.
   */
  async createOrUpdate(data: T): Promise<StoredEntity<T>> {
    const identifiable = data as StoredEntity<T>;

    if (typeof identifiable.id === 'string') {
      if (this.hasItemWithId(identifiable.id)) {
        return this.update(data);
      } else {
        return this.create(identifiable);
      }
    } else {
      return this.create(data);
    }
  }

  /**
   * Creates the given data. Throws if the data has an id field which is already existing.
   * If you want to update a data entry, consider using `update` or `createOrUpdate` method.
   *
   * ---
   * **Please note:** Data with an id field of other than type `string` | `undefined` is not supported.
   *
   * ---
   * @param value The data entry to create.
   */
  async create(value: T): Promise<StoredEntity<T>> {
    const dataWithId = this.toIdentificable(value);

    if (this.hasItemWithId(dataWithId.id)) {
      throw new Error(
        `Refuse to create item with id "${dataWithId.id}" as the id is already taken. ` +
          'If this is intentional and you try to update the entry, please use either "update" ' +
          'or "createOrUpdate" method instead.',
      );
    }

    this._data.push(dataWithId);
    this.applyChanges();

    return dataWithId;
  }

  /**
   * Finds the given data entry by its id. If you want the whole data set
   * instead, consider using the `data$` observable.
   *
   * ---
   * ~~~ts
   * const entry = await service.read('id-1');
   * ~~~
   * ---
   * **Please note:** This method throws if the item with the given id does not exist.
   *
   * ---
   * @param id The id to find within the data entries and return.
   */
  async read(id: string): Promise<StoredEntity<T>> {
    const entry = this._data.find((d) => d.id === id);

    if (entry == null) {
      throw new Error(`The entry with id "${id}" could not be found.`);
    }

    return entry;
  }

  /**
   * Updates the given data entry in the target data storage.
   * @param data The data entry to update
   */
  async update(data: T | StoredEntity<T>): Promise<StoredEntity<T>> {
    const identifiable = data as StoredEntity<T>;
    const index = this.indexOfItem(identifiable.id);

    if (index >= 0) {
      this._data[index] = this.toIdentificable(data);
      this.applyChanges();
    } else {
      throw new Error(`Cannot update item with id "${identifiable.id}" as there is no such item.`);
    }

    return this._data[index];
  }

  /**
   * Deletes the given data entry from the target-data-storage and returns the deleted element.
   *
   * ---
   * ~~~ts
   * const entry = await service.create({ id: '1' });
   * service.delete('1');
   * ~~~
   *
   * ---
   * @param data The data entry to delete from target-data-storage.
   */
  async delete(id: string): Promise<StoredEntity<T>> {
    const index = this.indexOfItem(id);

    if (index >= 0) {
      const removed = this._data.splice(index, 1);
      this.applyChanges();

      return removed[0];
    } else {
      throw new Error(`Cannot delete item with id "${id}" as there is no such item.`);
    }
  }

  protected applyChanges(persist: boolean = true): void {
    if (persist) {
      this.strategy.save(this._data);
    }

    const clonedData = cloneDeep(this._data);
    this._dataStream.next(clonedData);
  }

  private indexOfItem(id: string): number {
    return this._data.findIndex((d) => d.id === id);
  }

  private toIdentificable(data: T): StoredEntity<T> {
    const identificable = data as StoredEntity<T>;

    switch (typeof identificable.id) {
      case 'string':
      case 'undefined':
        return identificable.id ? identificable : { ...data, id: uuid() };
      default:
        throw new Error(
          `DataPersistentService: Unsupported id type. The id field must be of type string or undefined.`,
        );
    }
  }

  private hasItemWithId(id: string): boolean {
    return this.indexOfItem(id) !== -1;
  }
}
