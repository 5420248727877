import { BoxDimensions, FixtureMateDto } from '../types';

export const FAKE_PART_1: FixtureMateDto = {
  name: 'Part 1',
  previewUrl: 'https://fakeimg.pl/250x200/282828/?text=Part%201&font=lobster',
  partModelUrl: 'https://fake.com/Part01.step',
  status: 'Available',
  fixtureUrl: 'https://fake.com/Part01.Fixture.step',
  previewFixtureUrl: 'https://fakeimg.pl/250x200/1f3b6b/?text=Fixture%201&font=lobster'
};

export const FAKE_PART_2: FixtureMateDto = {
  name: 'Part 4',
  previewUrl: 'https://fakeimg.pl/250x200/3d040e/?text=Part%203&font=lobster',
  partModelUrl: 'https://fake.com/Part03.step',
  status: 'FeedbackRequired',
};

export const FAKE_PART_FROM_BOX: FixtureMateDto = {
  name: 'Part 3',
  status: 'InProgress'
};

export const FAKE_PART_3: FixtureMateDto = {
  name: 'Part 2',
  previewUrl: 'https://fakeimg.pl/250x200/0a1c09/?text=Part%202&font=lobster',
  partModelUrl: 'https://fake.com/Part02.step',
  status: 'InProgress',
};

export const BOX_1: BoxDimensions = {
  name: 'Box 1',
  lengthX: 200,
  widthY: 200,
  heightZ: 100,
  flangeWidthA: 10,
  flangeWidthB: 210,
  material: 'va',
  thickness: 1
};

export const FIXTURE_MATE_PARTS: FixtureMateDto[] = [ FAKE_PART_1, FAKE_PART_2, FAKE_PART_3, FAKE_PART_FROM_BOX ];

