import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from '@trumpf-xguide/xguide';
import { CaptionLabel } from '../../../shared/components/caption-label-list/types';
import { CurrencyUnitPipe } from '../../../shared/pipe/currency-unit.pipe';

@Component({
  selector: 'lsb-calculated-part-item',
  templateUrl: './calculated-part-item.component.html',
  styleUrls: ['./calculated-part-item.component.scss'],
})
export class CalculatedPartItemComponent implements OnChanges {
  @Input() calculationName?: string;
  @Input() costsPerPart?: CaptionLabel;
  @Input() totalCosts?: CaptionLabel;

  @Output() select = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();

  public perPartWithCorrectUnit?: CaptionLabel;
  public totalCostsWithCorrectUnit?: CaptionLabel;

  constructor(private settingsService: SettingsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const perPartKey: keyof this = 'costsPerPart';
    const totalKey: keyof this = 'totalCosts';

    if (changes[perPartKey as string] && this.costsPerPart) {
      this.perPartWithCorrectUnit = {
        ...this.costsPerPart,
        label: new CurrencyUnitPipe(this.settingsService).transform(this.costsPerPart.label),
      };
    }

    if (changes[totalKey as string] && this.totalCosts) {
      this.totalCostsWithCorrectUnit = {
        ...this.totalCosts,
        label: new CurrencyUnitPipe(this.settingsService).transform(this.totalCosts.label),
      };
    }
  }
}
