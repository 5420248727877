import { Injectable } from '@angular/core';
import { PartCalculation, WeldingTechnology } from '@shared/types';
import { LocalStorageService } from '@trumpf-xguide/xguide';
import { merge } from 'merge-anything';
import { LocalStorageKeys } from '../../../constants';
import { LocalStoragePersistenceStrategy } from '../../../shared/data-persistence/strategies/local-storage.persistence';
import { DataMigrationRule } from '../../../shared/data-persistence/types';
import { VersionedDataPersistenceService } from '../../../shared/data-persistence/versioned-data-persistence.service';

/** Service responsible for loading and saving part-calculations. */
@Injectable({ providedIn: 'root' })
export class CalculationDataService extends VersionedDataPersistenceService<PartCalculation> {
  protected migrationRules: DataMigrationRule[] = [
    {
      migrateToVersion: 1,
      migrate: (data: Record<string, any>) => {
        return merge(data, {
          parameters: {
            selectedMethod: undefined, // rename to selectedTechnology
            selectedTechnology: data.parameters.selectedMethod,
          },
        } as PartCalculation & PartCalculationV1);
      },
    },
  ];

  constructor(localStorage: LocalStorageService) {
    super(
      new LocalStoragePersistenceStrategy<PartCalculation>(
        localStorage,
        LocalStorageKeys.calculator.database,
      ),
    );
  }
}

// ------------------------------------
//  Module Internals
// ------------------------------------

type PartCalculationV1 = { parameters: { selectedMethod?: WeldingTechnology } };
