<uxc-app-header
  (uicLogout)="logout($event)"
  (uicMenuChange)="onUicMenuChange($event)"
  [appsNavigation]="guideNavigationData"
  [mainNavigation]="navigationData"
  [userData]="userProfile"
  disable-apps
  disable-notifications
>
  <xui-button
    (click)="login()"
    *ngIf="!(isLoggedIn$ | async)"
    iconKey="Key"
    slot="user-dialog"
    type="primary"
    >Login</xui-button
  >
  <div slot="user-dialog">
    <a class="output-content link" data-ux-cookie-layer="settings" href="#">
      {{ translations.XGUIDE.PRIVACY.TITLE | translate }}
    </a>
  </div>
</uxc-app-header>

<div [class.dialog-active]="isDialogActive$ | async" class="app-content">
  <router-outlet></router-outlet>
</div>

<a #mailAnchor [href]="mailLink" data-ngtx="hidden-anchor" hidden></a>
