import { TranslationKeys } from '../../shared/helpers';

/**
 * Optical types are stored along with machine-configurations in local-storage.
 * The optical-type name must match the translation-keys, as the UI depends on it
 * to correctly translate the optical-type name.
 */
export class OpticalType {
  static readonly BEO_D70 = getIdFromTranslationKey(TranslationKeys.CONFIGURATOR.OPTICS.BEO_D70);
  static readonly PFO_SF20 = getIdFromTranslationKey(TranslationKeys.CONFIGURATOR.OPTICS.PFO_SF20);
}

//#region Module internal helpers
function getIdFromTranslationKey(translationKey: string): string {
  const pathSegments = translationKey.split('.');
  const lastSegment = pathSegments.slice(-1)[0];
  return lastSegment;
}
//#endregion
