<div class="stacked-layout half">
  <label>{{ translations.opticalType | translate }}</label>
  <xui-dropdown
    #opticsDropDown
    [label]="configuration?.opticNozzleOptions.opticalType"
    [flat]="true"
  >
    <xui-dropdown-item
      (activate)="setOptic(OpticalType.BEO_D70); opticsDropDown.close()"
      data-ngtx="opticConfiguration:dropdownItemBeoD70"
    >
      {{ translations.optics.beoD70 | translate }}
    </xui-dropdown-item>
    <xui-dropdown-item
      (activate)="setOptic(OpticalType.PFO_SF20); opticsDropDown.close()"
      data-ngtx="opticConfiguration:dropdownItemPfoSF20"
    >
      {{ translations.optics.pfoSF20 | translate }}
    </xui-dropdown-item>

    <ng-template #toggleTemplate let-selectedOpticType>
      {{ 'CONFIGURATOR.OPTICS.' + selectedOpticType | translate }}
    </ng-template>
  </xui-dropdown>

  <div class="horizontal-layout">
    <lsb-group-container [headline]="translations.options | translate" class="options half">
      <xui-checkbox
        [disabled]="true"
        [checked]="configuration.opticNozzleOptions.opticOptions.rotaryModule"
        data-ngtx="opticConfiguration:rotaryModule"
      >
        {{ translations.rotaryModule | translate }}
      </xui-checkbox>

      <xui-checkbox
        [checked]="configuration.opticNozzleOptions.opticOptions.teachLine"
        (checkedChange)="onOptionsChange('teachline', $event)"
        data-ngtx="opticConfiguration:teachLine"
      >
        {{ translations.teachLine | translate }}
      </xui-checkbox>

      <xui-checkbox
        [checked]="configuration.opticNozzleOptions.opticOptions.fusionLine"
        (checkedChange)="onOptionsChange('fusionline', $event)"
        data-ngtx="opticConfiguration:fusionLine"
      >
        {{ translations.fusionLine | translate }}
      </xui-checkbox>

      <xui-checkbox
        [checked]="configuration.opticNozzleOptions.opticOptions.wire"
        [disabled]="!configuration.opticNozzleOptions.opticOptions.fusionLine"
        (checkedChange)="onOptionsChange('wire', $event)"
        data-ngtx="opticConfiguration:wire"
      >
        {{ translations.wire | translate }}
      </xui-checkbox>
    </lsb-group-container>

    <lsb-group-container [headline]="translations.gasNozzle | translate" class="nozzles half">
      <lsb-radio-group (selectionChange)="onNozzleChange($event)">
        <lsb-radio-button
          [value]="NozzleType.LinearNozzle"
          [label]="translations.linearNozzle | translate"
          [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.LinearNozzle"
          data-ngtx="opticConfiguration:linearNozzle"
        ></lsb-radio-button>

        <lsb-radio-button
          [value]="NozzleType.PerlatorNozzle"
          [label]="translations.perlatorNozzle | translate"
          [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.PerlatorNozzle"
          data-ngtx="opticConfiguration:perlatorNozzle"
        ></lsb-radio-button>

        <lsb-radio-button
          [value]="NozzleType.Coaxial"
          [label]="translations.coaxialNozzle | translate"
          [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.Coaxial"
          data-ngtx="opticConfiguration:coaxialNozzle"
        ></lsb-radio-button>

        <lsb-radio-button
          [value]="NozzleType.None"
          [label]="translations.noNozzle | translate"
          [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.None"
          data-ngtx="opticConfiguration:noNozzle"
        ></lsb-radio-button>
      </lsb-radio-group>
    </lsb-group-container>
  </div>
</div>

<div class="image-preview half">
  <img class="image non-draggable" [src]="cabinIllustrationUrl | imageUrl" />
</div>
