import { RobotType } from '../../../types';
import { CabinRules } from './cabin-rules';
import {
  getDefaultValueSetterForRulesForBigCabins,
  getRulesForBigCabins,
} from './rules-for-big-cabins';
import {
  RULES_FOR_MIDDLE_SIZED_CABINS,
  getDefaultValueSetterForRulesForMiddleSizedCabins,
} from './rules-for-middle-sized-cabins.data';
import {
  getDefaultValueSetterForSmallCabinsWithLongBack,
  getRulesForSmallCabinsWithLongBack,
} from './rules-for-small-cabins-with-long-back.data';
import {
  getDefaultValueSetterForSmallCabinsWithShortBack,
  getRulesForSmallCabinsWithShortBack,
} from './rules-for-small-cabins-with-short-back';
import {
  getDefaultValueSetterForTinyCabinsWithShortBack,
  getRulesForTinyCabins,
} from './rules-for-tiny-sized-cabins.data';

/** Factory method that chooses group of rules by cabin size */
export function getRulesByCabinLayout(cabinSize: string, usedRobotType: RobotType): CabinRules {
  switch (cabinSize) {
    case '4x3':
      return {
        rules: getRulesForTinyCabins(usedRobotType),
        setDefaultValues: getDefaultValueSetterForTinyCabinsWithShortBack(usedRobotType),
      };
    case '4x4':
    case '5x4':
      return {
        rules: getRulesForSmallCabinsWithShortBack(usedRobotType),
        setDefaultValues: getDefaultValueSetterForSmallCabinsWithShortBack(usedRobotType),
      };
    case '4x5':
    case '5x5':
      return {
        rules: getRulesForSmallCabinsWithLongBack(usedRobotType),
        setDefaultValues: getDefaultValueSetterForSmallCabinsWithLongBack(usedRobotType),
      };
    case '6x4':
    case '6x5':
      return {
        rules: RULES_FOR_MIDDLE_SIZED_CABINS,
        setDefaultValues: getDefaultValueSetterForRulesForMiddleSizedCabins,
      };
    case '7x4':
    case '7x5':
    case '8x4':
      return {
        rules: getRulesForBigCabins(usedRobotType),
        setDefaultValues: getDefaultValueSetterForRulesForBigCabins(usedRobotType),
      };
    default:
      throw new Error(`Not implemented: Cabin-rules for cabin-size "${cabinSize}" missing.`);
  }
}
