import {
  CalculationBasics,
  PartCalculation,
  PartCalculationConfiguration,
  PartCalculationParameters,
  WeldingParameters,
} from '@shared/types';
import { CalculatorDefaultValues } from '../constants';

/**
 * Creates a new calculation object with default values set.
 * @param name The calculation name to set for the new calculation.
 */
export function createEmptyPartCalculation(name: string): PartCalculation {
  const configuration: PartCalculationConfiguration = {
    basics: createEmptyCalculationBasics(),
    laserWeldingParameters: createEmptyWeldingParameters('laser'),
    arcWeldingParameters: createEmptyWeldingParameters('arc'),
  };

  return {
    name,
    configuration,
    parameters: createEmptyCalculationParameters(),
  };
}

/** Creates a new calculation parameters object with default values set. */
export function createEmptyCalculationParameters(): PartCalculationParameters {
  return {
    selectedTechnology: 'arc',
    arc: { parallelLoading: false, robotWelding: false },
    laser: { parallelLoading: false, robotWelding: false },
  };
}

/**
 * Creates a new calculation-basics object with default values set.
 * @param name The name to set for the the calculation.
 */
export function createEmptyCalculationBasics(): CalculationBasics {
  return {
    amountComponents: 0,
    amountSeams: 1,
    batchSize: 250,
    totalPieces: 1000,
    totalSeamLength: 1000,
  };
}

/**
 * Creates a new calculation-parameters object with the specified default-values set.
 * @param useDefaultValuesFor Which default values to use in this configuration object.
 */
export function createEmptyWeldingParameters(
  useDefaultValuesFor: 'laser' | 'arc',
): WeldingParameters {
  const defaultValues =
    useDefaultValuesFor === 'laser' ? CalculatorDefaultValues.laser : CalculatorDefaultValues.arc;

  return { ...defaultValues };
}
