<div *ngIf="!(fixtures$ | async)" class="info-box">
  <div *ngIf="backendError$ | async as error else loading" class="error">
    {{ translations.FIXTURE_MATE.BACKEND_ERROR_MESSAGE | translate}}
  </div>
  <ng-template #loading>
  Loading...
  </ng-template>
</div>
<!-- This is a small trick. I we are not using the dropdown, we still need it to be rendered because we are using OpenFile dialog from it-->
<div *ngIf="!ShowDropZone" hidden>
  <lsb-file-drop-zone
    #dropZone
    (fileDrop)="createFromStepModel($event)"
    accept=".stp,.step"
    class="file-drop"
    clickable
  >
    {{ translations.CALCULATOR.DROP_STEP_FILE_HINT | translate }}
  </lsb-file-drop-zone>
</div>
<ng-container *ngIf="fixtures$ | async as fixtures">
  <div *ngIf="fixtures.length === 0" class="start-screen flex-vertical flex-grow">
    <div class="button-note start-screen-header flex-auto">
      {{ translations.FIXTURE_MATE.CREATE_FIXTURE_HINT | translate }}
      <lsb-part-box-dimensions-tooltip></lsb-part-box-dimensions-tooltip>
    </div>
    <div class="start-screen-buttons-area flex-auto">
      <xui-button
        (click)="createNewFixture()"
        [xuiClickUsageTracking]="uiElementIds.CREATE_FIXTURE"
        class="create-button"
        data-ngtx="create-fixture-button"
        type="primary"
      >
        {{ translations.FIXTURE_MATE.CREATE_FIXTURE | translate }}
      </xui-button>
      <xui-button
        (click)="createExampleBox()"
        [xuiClickUsageTracking]="uiElementIds.CREATE_FIXTURE_EXAMPLE"
        class="create-button"
        type="secondary"
      >
        {{ translations.FIXTURE_MATE.CREATE_FIXTURE_DIMENSIONS | translate }}
      </xui-button>
      <div class="additional-content flex-vertical flex-auto">
        <lsb-file-drop-zone
          #dropZone
          (fileDrop)="createFromStepModel($event)"
          *ngIf="ShowDropZone"
          accept=".stp,.step"
          class="file-drop"
          clickable
        >
          {{ translations.CALCULATOR.DROP_STEP_FILE_HINT | translate }}
        </lsb-file-drop-zone>
      </div>
    </div>
    <div class="start-screen-video-area flex-vertical flex-grow">
      <div class="start-screen-video-caption">
        {{translations.FIXTURE_MATE.VIDEO_CAPTION | translate}}
      </div>
      <xui-media [hideControls]="false" [media]="fixtureMateVideo$ | async" class="start-screen-video"></xui-media>
    </div>
  </div>

  <lsb-master-detail-layout *ngIf="fixtures.length > 0">
    <section data-slot="sidebar">
      <xui-button
        (click)="createNewFixture()"
        [xuiClickUsageTracking]="uiElementIds.CREATE_FIXTURE"
        class="create-button"
        data-ngtx="create-fixture-button"
        type="primary"
      >
        {{ translations.FIXTURE_MATE.CREATE_FIXTURE | translate }}
      </xui-button>
      <xui-button
        (click)="createExampleBox()"
        [xuiClickUsageTracking]="uiElementIds.CREATE_FIXTURE_EXAMPLE"
        class="create-button"
        type="secondary"
      >
        {{ translations.FIXTURE_MATE.CREATE_FIXTURE_DIMENSIONS | translate }}
      </xui-button>
      <div class="additional-content">
        <lsb-file-drop-zone
          #dropZone
          (fileDrop)="createFromStepModel($event)"
          *ngIf="ShowDropZone"
          accept=".stp,.step"
          class="file-drop"
          clickable
        >
          {{ translations.CALCULATOR.DROP_STEP_FILE_HINT | translate }}
        </lsb-file-drop-zone>

        <div class="button-note">
          {{ translations.FIXTURE_MATE.CREATE_FIXTURE_HINT | translate }}
          <lsb-part-box-dimensions-tooltip></lsb-part-box-dimensions-tooltip>
        </div>
      </div>
      <div class="side-bar-video-container">
        <xui-media [hideControls]="false" [media]="fixtureMateVideo$ | async" class="side-bar-video"></xui-media>
      </div>
    </section>
    <section class="content" data-slot="main">
      <lsb-fixture-mate-table
        (deleteFixture)='deleteCad($event)'
        (downloadCad)='downloadCad($event)'
        (selectionChange)='fixtureSelected($event)'
        [fixtures]='fixtures'
        class="results"
      ></lsb-fixture-mate-table>
      <lsb-fixture-mate-details
        [fixture$]="selectedFixture$"
        class="details">
      </lsb-fixture-mate-details>
    </section>

  </lsb-master-detail-layout>
</ng-container>

