import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    ButtonModule,
    CaptionLabelModule,
    CardModule,
    CheckboxModule,
    ComboboxModule,
    DataTransformModule,
    DialogModule,
    DropDownModule,
    GalleryModule,
    IconModule,
    InputFieldModule,
    MediaModule,
    MenuModule,
    SettingsModule,
    TagModule,
    TooltipModule,
    WizardModule,
} from '@trumpf-xguide/xguide';
import { environment } from '../../environments/environment';
import { CadViewerModule } from './cad-viewer/cad-viewer.module';
import { CaptionLabelListComponent } from './components/caption-label-list/caption-label-list.component';
import { CaptionLabelComponent } from './components/caption-label-list/caption-label/caption-label.component';
import { ComboboxComponent } from './components/combobox/combobox.component';
import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { GroupContainerComponent } from './components/group-container/group-container.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { LabelComponent } from './components/label/label.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { ListComponent } from './components/list/list.component';
import { MasterDetailLayoutComponent } from './components/master-detail-layout/master-detail-layout.component';
import { NumberListItemComponent } from './components/number-list/number-list-item/number-list-item.component';
import { NumberListComponent } from './components/number-list/number-list.component';
import { PartFilterListComponent } from './components/part-filter-list/part-filter-list.component';
import { PartListItemComponent } from './components/part-list-item/part-list-item.component';
import { PartSpecificationComponent } from './components/part-specification/part-specification.component';
import { RadioButtonComponent } from './components/radio-group/radio-button/radio-button.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TemplateContainerComponent } from './components/template-container/template-container.component';
import { HeadlineAndTextComponent } from './components/templates/headline-and-text/headline-and-text.component';
import { HintComponent } from './components/templates/hint/hint.component';
import { IllustrationComponent } from './components/templates/illustration/illustration.component';
import { SpacerComponent } from './components/templates/spacer/spacer.component';
import { TextComponent } from './components/text/text.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { RadioGroupDirective } from './directives/radio-group/radio-group.directive';
import { FileDropModule } from './file-drop/file-drop.module';
import { TranslationHelper, TranslationKeys } from './helpers/translation.helpers';
import { CadUrlPipe, ImageUrlPipe, LanguagePipe, TextFormatPipe } from './pipe';
import { CurrencyUnitPipe } from './pipe/currency-unit.pipe';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { AuthServiceMock } from './services/auth/auth.service.mock';
import { LanguageService } from './services/language/language.service';
import { PartsService } from './services/parts/parts.service';
import { PartsServiceMock } from './services/parts/parts.service.mock';

const TEMPLATES = [HeadlineAndTextComponent, HintComponent, SpacerComponent, IllustrationComponent];

const EXPOSED_COMPONENTS = [
  CaptionLabelComponent,
  CaptionLabelListComponent,
  ComboboxComponent,
  DropdownComponent,
  DropdownItemComponent,
  GroupContainerComponent,
  InputFieldComponent,
  LabelComponent,
  ListComponent,
  ListItemComponent,
  MasterDetailLayoutComponent,
  NumberListComponent,
  NumberListItemComponent,
  PartFilterListComponent,
  PartListItemComponent,
  PartSpecificationComponent,
  RadioButtonComponent,
  RadioGroupComponent,
  SwitchComponent,
  TabsComponent,
  TextComponent,
  TemplateContainerComponent,
  ...TEMPLATES,
];

const INTERNAL_DIRECTIVES = [
  // <this comment forces items to be placed below each other>
  ClickOutsideDirective,
];
const EXPOSED_DIRECTIVES = [
  // <this comment forces items to be placed below each other>
  RadioGroupDirective,
];

const EXPOSED_PIPES = [
  // <this comment forces items to be placed below each other>
  CadUrlPipe,
  CurrencyUnitPipe,
  ImageUrlPipe,
  LanguagePipe,
  TextFormatPipe,
];

const SHARED_MODULES = [
  CadViewerModule,
  CommonModule,
  DataTransformModule,
  FileDropModule,
  OverlayModule,
  ScrollingModule,
  TranslateModule,
  // XGuide
  ButtonModule,
  CaptionLabelModule,
  CardModule,
  CheckboxModule,
  ComboboxModule,
  DialogModule.forChild(),
  DropDownModule,
  GalleryModule,
  IconModule,
  InputFieldModule,
  MediaModule,
  MenuModule,
  SettingsModule,
  TagModule,
  TooltipModule,
  WizardModule,
];

@NgModule({
  imports: [SHARED_MODULES],
  declarations: [EXPOSED_COMPONENTS, INTERNAL_DIRECTIVES, EXPOSED_DIRECTIVES, EXPOSED_PIPES],
  providers: [
    LanguageService,
    AuthGuardService,
    {
      provide: AuthService,
      useClass: environment.mockLogin ? AuthServiceMock : AuthService,
    },
    { provide: PartsService, useClass: environment.mock ? PartsServiceMock : PartsService },
    { provide: TranslationHelper, useValue: TranslationKeys },
  ],
  exports: [SHARED_MODULES, EXPOSED_COMPONENTS, EXPOSED_DIRECTIVES, EXPOSED_PIPES],
})
export class SharedModule {}
