<xui-wizard
  [tabs]="tabs"
  [translateTabs]="true"
  (selectedTabChange)="setActive($event)"
  (finishWizard)="onFinishWizard($event)"
>
  <lsb-calculation-basics-tab
    *ngIf="active === tabs[0]"
    [(name)]="calculation.name"
    [(basics)]="calculation.configuration.basics"
  ></lsb-calculation-basics-tab>
  <lsb-calculation-parameters-tab
    *ngIf="active === tabs[1]"
    [weldingTechnology]="WeldingTechnologies.laser"
    [(parameters)]="calculation.configuration.laserWeldingParameters"
  ></lsb-calculation-parameters-tab>
  <lsb-calculation-parameters-tab
    *ngIf="active === tabs[2]"
    [weldingTechnology]="WeldingTechnologies.arc"
    [(parameters)]="calculation.configuration.arcWeldingParameters"
    [(weldingMethodParameters)]="calculation.parameters.arc"
  ></lsb-calculation-parameters-tab>
</xui-wizard>
