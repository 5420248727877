import {
  ComponentOptions,
  ComponentShortTag,
  MachineConfigComponentItem,
  MachineConfigDesk,
  MachineConfigPosition,
  RobotType,
} from '../../types';
import { CabinPlateState } from './rules-data/cabin-plate-state';
import { getComponentItem, getShortComponentItemTag } from './rules-data/component-items-helper';
import { OptionActionAffects } from './rules-data/option-action-affects';
import { OptionActionType } from './rules-data/option-action-type';
import { OptionRule } from './rules-data/option-rule';
import { getRulesByCabinLayout } from './rules-data/rules-factory';

/**
 * Service that reads data from rules data constants and applying actions described in that rules to the User Interface
 */
export class LayoutRulesService {
  private dkp: MachineConfigComponentItem;
  private dt: MachineConfigComponentItem;
  private rc: MachineConfigComponentItem;
  private rw: MachineConfigComponentItem;
  private wp: MachineConfigComponentItem;

  private rules: OptionRule[];

  constructor(
    cabinLayout: string,
    robotType: RobotType,
    private componentOptions: ComponentOptions,
    private cabinPlateState: CabinPlateState,
    setWizardInitialState: boolean,
  ) {
    this.dkp = getComponentItem('dkp', componentOptions.componentItems);
    this.dt = getComponentItem('dt', componentOptions.componentItems);
    this.rc = getComponentItem('rc', componentOptions.componentItems);
    this.rw = getComponentItem('rw', componentOptions.componentItems);
    this.wp = getComponentItem('wp', componentOptions.componentItems);
    const rulesFromFactory = getRulesByCabinLayout(cabinLayout, robotType);

    this.rules = rulesFromFactory.rules;

    if (setWizardInitialState) {
      rulesFromFactory.setDefaultValues(
        this.dkp,
        this.dt,
        this.rc,
        this.rw,
        this.wp,
        componentOptions.deskRef,
        cabinPlateState,
      );
    }
  }

  public setOptionChecked(optionTag: string, checked: boolean) {
    const shortTag = getShortComponentItemTag(optionTag);
    const actionOptionType = checked ? OptionActionType.Check : OptionActionType.Uncheck;

    this.provideAction(shortTag, actionOptionType);
  }

  public setOptionPosition(optionTag: string, position: MachineConfigPosition) {
    const shortTag = getShortComponentItemTag(optionTag);
    const actionOptionType: OptionActionType =
      position === MachineConfigPosition.LEFT
        ? OptionActionType.MoveLeft
        : OptionActionType.MoveRight;

    this.provideAction(shortTag, actionOptionType);
  }

  private provideAction(shortTag: string, actionOptionType: OptionActionType) {
    const optionRule = this.rules.find(
      (r) => r.tag === shortTag && r.actions.find((a) => a.action === actionOptionType) !== null,
    );

    if (optionRule === undefined) {
      return;
    }

    const optionActions = optionRule.actions.find((a) => a.action === actionOptionType);

    if (optionActions === undefined) {
      return;
    }

    for (const affects of optionActions.affects) {
      const isActionBlocked = this.checkIfActionBlocked(affects);

      if (isActionBlocked === true) {
        continue;
      }

      this.applyActionOnOption(affects);
      this.applyActionOnRadioButton(affects);
    }
  }

  private applyActionOnRadioButton(affects: OptionActionAffects) {
    if (affects.radioButton !== undefined) {
      switch (affects.radioButton) {
        case 'demeller-plate':
          if (affects.actionToApply === OptionActionType.Disable) {
            // Radio button should be unchecked if it's become disabled
            this.componentOptions.deskRef.value = MachineConfigDesk.ZeroPointClampingSystem;
          }
          this.cabinPlateState.demellerPlateEnabled =
            affects.actionToApply === OptionActionType.Enable;
          break;
        case 'zeroPoint-clamping-system':
          if (affects.actionToApply === OptionActionType.Disable) {
            // Radio button should be unchecked if it's become disabled
            this.componentOptions.deskRef.value = MachineConfigDesk.DemellerPlate;
          }
          this.cabinPlateState.zeroPointClampingSystemEnabled =
            affects.actionToApply === OptionActionType.Enable;
          break;
      }
    }
  }

  private applyActionOnOption(affects: OptionActionAffects) {
    if (affects.tag !== undefined) {
      const option = this.getOptionByTag(affects.tag);
      this.applyAction(option, affects.actionToApply);
    }
  }

  private checkIfActionBlocked(affects: OptionActionAffects): boolean {
    let isActionBlocked = false;

    if (affects.blockedBy !== undefined) {
      for (const blocker of affects.blockedBy) {
        if ('tag' in blocker) {
          const blockerOption = this.getOptionByTag(blocker.tag);
          isActionBlocked =
            isActionBlocked ||
            this.checkIfOptionMatchActionType(blockerOption, blocker.optionState);
        } else {
          isActionBlocked = isActionBlocked || blocker.condition();
        }
      }
    }

    return isActionBlocked;
  }

  private getOptionByTag(tag: ComponentShortTag): MachineConfigComponentItem {
    switch (tag) {
      case 'dkp':
        return this.dkp;
      case 'dt':
        return this.dt;
      case 'rw':
        return this.rw;
      case 'wp':
        return this.wp;
      case 'rc':
        return this.rc;
    }
  }

  private applyAction(item: MachineConfigComponentItem, action: OptionActionType) {
    switch (action) {
      case OptionActionType.Check:
        item.isChecked = true;
        break;
      case OptionActionType.Uncheck:
        item.isChecked = false;
        break;
      case OptionActionType.Enable:
        item.isEnabled = true;
        break;
      case OptionActionType.Disable:
        item.isEnabled = false;
        break;
      case OptionActionType.MoveLeft:
        item.position = MachineConfigPosition.LEFT;
        break;
      case OptionActionType.MoveRight:
        item.position = MachineConfigPosition.RIGHT;
        break;
    }
  }

  private checkIfOptionMatchActionType(
    item: MachineConfigComponentItem,
    action: OptionActionType,
  ): boolean {
    return (
      (item.isChecked && action === OptionActionType.Check) ||
      (!item.isChecked && action === OptionActionType.Uncheck) ||
      (item.position === MachineConfigPosition.LEFT && action === OptionActionType.MoveLeft) ||
      (item.position === MachineConfigPosition.RIGHT && action === OptionActionType.MoveRight)
    );
  }
}
