import { TranslationKeys } from '../../shared/helpers';

export class NozzleType {
  static readonly LinearNozzle =
    TranslationKeys.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.LINEAR_NOZZLE;
  static readonly PerlatorNozzle =
    TranslationKeys.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.PERLATOR_NOZZLE;
  static readonly Coaxial =
    TranslationKeys.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.COAXIAL_NOZZLE;
  static readonly None = TranslationKeys.CONFIGURATOR.DATA.MACHINE_CONFIG_NOZZLE_TYPE.NONE;
}
