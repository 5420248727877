<xui-icon
  class="info-flag per-part"
  iconKey="Tooltip.Information"
  [size]="IconSize.S"
  [xuiTooltip]="createFixtureHintTooltip"
></xui-icon>

<xui-tooltip #createFixtureHintTooltip>
  <div>
    <p>{{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_LABEL | translate}}:</p>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_WIDTH | translate}}: 150 – 450 mm<br>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_LENGTH | translate}}: 210 – 450 mm<br>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_HEIGHT | translate}}: 40 – 200 mm<br>
    {{translations.FIXTURE_MATE.FIXTURE_HINT_TOOLTIP_MAX_LENGTH | translate}} = 8,8 – 30 mm<br>
  </div>
</xui-tooltip>
