import { PartCalculation, WeldingTechnology } from '@shared/types/calculation-types';
import { NumberItem } from '../shared/types/number-item';
import { PartCalculationRow } from './components/part-calculation-table/part-calculation-table.component';

export interface TableRow {
  rowRef: PartCalculationRow;
  name: string;
  totalParts: number;
  laserCostsPerPart: number;
  arcCostsPerPart: number;
  selectedTechnology: WeldingTechnology;
  costsInfo: [NumberItem, NumberItem];
  savings: number;
}

export interface TableSortOptions {
  sortBy: keyof TableRow;
  direction: SortDirection;
}

export type SortDirection = 'asc' | 'desc';

export interface CalculationUpdateNotification {
  calculation: PartCalculation;
  updatedField: UpdatedCalculationField;
}

export type UpdatedCalculationField =
  | 'All'
  | 'SelectedWeldingTechnologyAndAmount'
  | 'MtpLoading'
  | 'RobotWelding';
