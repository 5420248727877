import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { CalculatorRoutingModule } from './calculator/calculator-routing.module';
import { ConfiguratorRoutingModule } from './configurator/configurator-routing.module';
import { DashboardComponent } from './dashboard/views/dashboard/dashboard.component';
import { AboutComponent } from './views/about/about.component';
import { CopyrightComponent } from './views/copyright/copyright.component';
import { PartDetailComponent } from './views/part-detail/part-detail.component';
import { PartsComponent } from './views/parts/parts.component';
import { SettingsComponent } from './views/settings/settings.component';
import { WeldingPrincipleDetailsComponent } from './views/welding-principle-details/welding-principle-details.component';
import { WeldingPrincipleOverviewComponent } from './views/welding-principle-overview/welding-principle-overview.component';
import { WeldingPrinciplesComponent } from './views/welding-principles/welding-principles.component';

const ROUTES: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  {
    path: 'welding-principles',
    component: WeldingPrinciplesComponent,
    children: [
      {
        path: ':categoryId',
        component: WeldingPrincipleOverviewComponent,
      },
      {
        path: 'details/:id',
        component: WeldingPrincipleDetailsComponent,
      },
    ],
  },
  {
    path: 'about',
    component: AboutComponent,
    children: [
      {
        path: '',
        redirectTo: 'conditions-of-use',
        pathMatch: 'full',
      },
      { path: 'conditions-of-use', component: CopyrightComponent },
      { path: 'settings', component: SettingsComponent },
    ],
  },
  {
    path: 'parts',
    component: PartsComponent,
  },
  {
    path: 'parts/:id',
    component: PartDetailComponent,
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  // `initialNavigation` needs to be disabled since otherwise the query parameter
  // from OIDC flow will be removed before they are processed
  // TODO: mf: when authentication fully implemented, set initalNavigation to false and
  // store a login credential set in the environment to test behavior with cypress
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      initialNavigation: environment.mockLogin ? 'enabledNonBlocking' : 'disabled',
    }),
    CalculatorRoutingModule,
    ConfiguratorRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
