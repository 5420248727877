import { Component, Input, OnInit } from '@angular/core';
import { BoxDimensions } from '@shared';
import { IconSize } from '../../../shared/types';
import { TranslationHelper } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lsb-box-dimensions-tooltip',
  templateUrl: './box-dimensions-tooltip.component.html',
  styleUrls: ['./box-dimensions-tooltip.component.scss'],
})
export class BoxDimensionsTooltipComponent implements OnInit {
  readonly IconSize = IconSize;

  @Input() boxDimensions: BoxDimensions;
  material: string;

  constructor(public translations: TranslationHelper, private translateService: TranslateService) {}

  ngOnInit(): void {
    switch (this.boxDimensions.material) {
      case 'va':
        this.material = this.translateService.instant(
          this.translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL_VALUE.VA,
        );
        break;
      case 'steel':
        this.material = this.translateService.instant(
          this.translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL_VALUE.STEEL,
        );
        break;
      case 'aluminum':
        this.material = this.translateService.instant(
          this.translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL_VALUE.ALUMINIUM,
        );
        break;
    }
  }
}
