import { Injectable } from '@angular/core';
import { BoxDimensions, FIXTURE_MATE_PARTS, FixtureMateDto } from '@shared';
import { FixtureMateServiceInterface } from './fixture-mate.service.interface';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FixtureMateServiceMock implements FixtureMateServiceInterface {
  getAllFixtures(): Observable<FixtureMateDto[]> {
    return of(FIXTURE_MATE_PARTS);
  }

  createNewFixture(stepFile: File): Observable<FixtureMateDto[]> {
    return of(FIXTURE_MATE_PARTS);
  }

  deleteFixture(partName: string): Observable<FixtureMateDto[]> {
    return of(FIXTURE_MATE_PARTS);
  }

  downloadCad(url: string): Observable<Blob> {
    return of(new Blob());
  }

  createNewBoxDimensions(boxDimensions: BoxDimensions): Observable<FixtureMateDto[]> {
    return of(FIXTURE_MATE_PARTS);
  }
}
