import { Pipe, PipeTransform } from '@angular/core';
import { FixtureCreationStatus } from '@shared';
import { TranslationHelper } from '../../shared/helpers';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  constructor(
    private translations: TranslationHelper) {}

  transform(value: FixtureCreationStatus): string {
    switch (value) {
      case 'Available':
        return this.translations.FIXTURE_MATE.STATUS_AVAILABLE;
      case 'InProgress':
        return this.translations.FIXTURE_MATE.STATUS_IN_PROGRESS;
      case 'FeedbackRequired':
        return this.translations.FIXTURE_MATE.STATUS_FEEDBACK_REQURED;
      default:
        return this.translations.FIXTURE_MATE.STATUS_ERROR;
    }
  }

}
