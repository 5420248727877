import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationHelper } from '../../../../shared/helpers';
import { IconSize } from '../../../../shared/types';
import { MachineConfigComponentItem, MachineConfigPosition } from '../../../types';

/**
 * This component represents a row with checkbox, image, and left or right switcher
 */
@Component({
  selector: 'lsb-configurator-component-option-item',
  templateUrl: './configurator-component-option-item.component.html',
  styleUrls: ['./configurator-component-option-item.component.scss'],
})
export class ConfiguratorComponentOptionItemComponent {
  public IconSize = IconSize;
  public MachineConfigPosition = MachineConfigPosition;

  @Input()
  componentItem: MachineConfigComponentItem;

  @Output()
  checkedChange = new EventEmitter<MachineConfigComponentItem>();

  @Output()
  switcherChange = new EventEmitter<MachineConfigComponentItem>();

  constructor(public translations: TranslationHelper) {}

  /**
   * Event handler of checkbox checked or unchecked
   */
  componentItemCheckedChanged(value: boolean) {
    if (value !== this.componentItem.isChecked) {
      this.componentItem.isChecked = value;
      this.checkedChange.emit(this.componentItem);
    }
  }

  /**
   * Event handler of switcher position changed
   */
  switcherCheckedChanged(value: boolean) {
    // true => Right
    this.componentItem.position = value ? MachineConfigPosition.RIGHT : MachineConfigPosition.LEFT;
    this.switcherChange.emit(this.componentItem);
  }

  // TODO: shabanovMik: emit correct router targets/links
  navigateOption(e: Event) {
    // e.preventDefault();
    // this.navigateTo.emit('/welding-principles');
  }
}
