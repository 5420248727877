import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as FileSaver from 'file-saver';
import { CaptionLabel } from '../../../../shared/components/caption-label-list/types';
import { TranslationHelper } from '../../../../shared/helpers';
import { ImageUrlPipe } from '../../../../shared/pipe';
import { UiElementIds } from '../../../../shared/usage-tracking/ui-element-ids';
import { ResourceType } from '../../../types';

/** Defines the common item-template parts of the `Layout`- and `Optic-`configurations. */
@Component({
  selector: 'lsb-configurator-list-item-container',
  templateUrl: './configurator-list-item-container.component.html',
  styleUrls: ['./configurator-list-item-container.component.scss'],
})
export class ConfiguratorListItemContainerComponent {
  public readonly uiElementIds = UiElementIds;

  @Input() isConfirmedCustomer = false;
  @Input() isAuthenticated = false;
  @Input() captionLabels: CaptionLabel[] = [];
  @Input() resolveResourceUrl: (type: ResourceType) => string = () => '';
  @Output() requireAuthentication = new EventEmitter<void>();

  private get layoutLabelsWithValue() {
    return this.captionLabels.filter(
      (captionLabel) =>
        captionLabel.label !== this.translations.CONFIGURATOR.LIST_ITEM.NOT_AVAILABLE &&
        captionLabel.label !== '',
    );
  }

  public get layoutOverviewLabels() {
    return this.layoutLabelsWithValue?.slice(0, 3);
  }

  public get layoutComponentLabels() {
    return this.layoutLabelsWithValue?.slice(3);
  }

  constructor(public translations: TranslationHelper) {}

  public downloadCad() {
    if (!this.isAuthenticated) {
      return this.requireAuthentication.emit();
    }

    const filePath = this.resolveResourceUrl('cad-model');

    if (filePath) {
      const pathEndIndex = filePath.lastIndexOf('/');
      const fileName = filePath.substring(pathEndIndex + 1);

      const imageUrl = new ImageUrlPipe();
      const url = imageUrl.transform(filePath);

      FileSaver.saveAs(url, fileName);
    }
  }
}
