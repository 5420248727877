import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FixtureMateDto } from '@shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslationHelper, typeOf } from '../../../shared/helpers';
import { FixtureTableSortOptions, SortDirection } from '../../types';
import { map } from 'rxjs/operators';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-fixture-mate-table',
  templateUrl: './fixture-mate-table.component.html',
  styleUrls: ['./fixture-mate-table.component.scss']
})
export class FixtureMateTableComponent implements OnInit {

  @Input() fixtures: FixtureMateDto[];
  @Output() selectionChange = new EventEmitter<FixtureMateDto>();
  @Output() downloadCad = new EventEmitter<string>();
  @Output() deleteFixture = new EventEmitter<string>();

  public sortOptions$ = new BehaviorSubject<FixtureTableSortOptions>({ sortBy: 'name', direction: 'asc' });

  public sortedTableRows$: Observable<FixtureMateDto[]>;

  public selected?: FixtureMateDto;

  public readonly uiElementIds = UiElementIds;

  constructor(public translations: TranslationHelper) {}

  ngOnInit(): void {
    this.sortedTableRows$ = this.sortOptions$.pipe(
      map((sorting) => this.sortTableRows(sorting)),
    );
  }

  public onSortClick(propertyName: keyof FixtureMateDto, sortOpts: FixtureTableSortOptions): void {
    sortOpts.sortBy !== propertyName ? this.setSortBy(propertyName) : this.toggleSortDirection();
  }

  private setSortBy(propertyName: keyof FixtureMateDto): void {
    this.sortOptions$.next({ sortBy: propertyName, direction: 'asc' });
  }

  public getSortingState(
    propertyName: keyof FixtureMateDto,
    sortOpts: FixtureTableSortOptions,
  ): Maybe<SortDirection> {
    if (sortOpts.sortBy !== propertyName) {
      return undefined;
    }
    return sortOpts.direction;
  }

  public selectResult(row: FixtureMateDto): void {
    this.selected = row;
    this.selectionChange.emit(row);
  }

  private toggleSortDirection(): void {
    const current = this.sortOptions$.getValue();
    this.sortOptions$.next({ ...current, direction: current.direction === 'asc' ? 'desc' : 'asc' });
  }

  private sortTableRows(sortOpts: FixtureTableSortOptions): FixtureMateDto[] {
    return this.fixtures.sort((rowA: FixtureMateDto, rowB: FixtureMateDto) => {
      if (!sortOpts.sortBy) {
        return 0;
      }

      const propertyA = rowA[sortOpts.sortBy];
      const propertyB = rowB[sortOpts.sortBy];

      let comparisonResult = 0;

      if (typeOf(propertyA, 'number') && typeOf(propertyB, 'number')) {
        comparisonResult = propertyA - propertyB;
      }

      if (typeOf(propertyA, 'string') && typeOf(propertyB, 'string')) {
        comparisonResult = propertyA.localeCompare(propertyB);
      }

      return sortOpts.direction === 'asc' ? comparisonResult : -comparisonResult;
    });
  }

}
