import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClickedUsageTracking } from '@shared';
import { PartCalculation, UsageTrackingMessageType } from '@shared/types';
import { UsageTrackingService } from '@trumpf-xguide/xguide';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CalculationDataService, CalculationService } from '../../../calculator';
import { CalculatorOverviewComponent } from '../../../calculator/views/calculator-overview/calculator-overview.component';
import { CaptionLabel } from '../../../shared/components/caption-label-list/types';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';

@Component({
  selector: 'lsb-calculated-parts-tile',
  templateUrl: './calculated-parts-tile.component.html',
  styleUrls: ['./calculated-parts-tile.component.scss'],
})
export class CalculatedPartsTileComponent implements OnInit {
  public calculation$: Observable<PartCalculation>;
  public calculationName$: Observable<string>;
  public hasCalculations$: Observable<boolean>;
  public costPerPart$: Observable<CaptionLabel>;
  public totalCosts$: Observable<CaptionLabel>;
  public readonly uiElementIds = UiElementIds;

  constructor(
    private router: Router,
    public translations: TranslationHelper,
    private calcService: CalculationService,
    private calculationsService: CalculationDataService,
    private usageTrackingService: UsageTrackingService,
  ) {}

  ngOnInit() {
    const calculations$ = this.calculationsService.data$;

    this.hasCalculations$ = calculations$.pipe(map((items) => items != null && items.length > 0));
    this.calculation$ = calculations$.pipe(
      filter((items) => items != null && items.length > 0),
      map((items) => items[items.length - 1]),
    );

    const calcResult$ = this.calculation$.pipe(map((calc) => this.calcService.calculate(calc)));
    this.calculationName$ = this.calculation$.pipe(map((calc) => calc.name));

    this.costPerPart$ = combineLatest([this.calculation$, calcResult$]).pipe(
      map(([calculation, calculationResult]) => {
        const isLaser = calculation.parameters.selectedTechnology === 'laser';
        return isLaser
          ? {
              caption: this.translations.CALCULATOR.LASER_COSTS_PER_PART,
              label: calculationResult.costsPerPartLaser.toString(),
              translate: 'caption',
            }
          : {
              caption: this.translations.CALCULATOR.ARC_COSTS_PER_PART,
              label: calculationResult.costsPerPartArc.toString(),
              translate: 'caption',
            };
      }),
    );

    this.totalCosts$ = combineLatest([this.calculation$, calcResult$]).pipe(
      map(([calculation, calculationResult]) => {
        const isLaser = calculation.parameters.selectedTechnology === 'laser';
        return isLaser
          ? {
              caption: this.translations.CALCULATOR.TOTAL_COSTS_LASER,
              label: calculationResult.totalCostsLaser.toString(),
              translate: 'caption',
            }
          : {
              caption: this.translations.CALCULATOR.TOTAL_COSTS_ARC,
              label: calculationResult.totalCostsArc.toString(),
              translate: 'caption',
            };
      }),
    );
  }

  public gotoCalculation(calculation: PartCalculation, edit = false) {
    const actions = CalculatorOverviewComponent.ActionQueryParams;
    const action = edit ? actions.editCalculation : actions.selectCalculation;
    const param = action(calculation.name);

    const usageTracking: ClickedUsageTracking = {
      currentUrl: this.router.url,
      clickedElement: edit
        ? this.uiElementIds.DASHBOARD_CALCULATION_EDIT
        : this.uiElementIds.DASHBOARD_CALCULATION_SELECT,
    };
    this.usageTrackingService.send(UsageTrackingMessageType.ElementClicked, usageTracking);

    this.router.navigate(['/', 'calculator'], {
      queryParams: { action: param },
    });
  }

  public onCreatePartCalculation() {
    const usageTracking: ClickedUsageTracking = {
      currentUrl: this.router.url,
      clickedElement: this.uiElementIds.DASHBOARD_CALCULATION_NEW,
    };
    this.usageTrackingService.send(UsageTrackingMessageType.ElementClicked, usageTracking);
    this.router.navigate(['/', 'calculator'], {
      queryParams: { action: CalculatorOverviewComponent.ActionQueryParams.addCalculation() },
    });
  }
}
