/**
 * Wizard Interface actions
 */
export enum OptionActionType {
  Check,
  Uncheck,
  MoveLeft,
  MoveRight,
  Disable,
  Enable,
}
