import { Injectable } from '@angular/core';
import { UsageTrackingSendImplementationService } from '@trumpf-xguide/xguide';
import { UsageTrackingMessageType, UsageTrackingTypes } from '../../../../../../../shared';
import { ReferrerTrackingService } from '../../../referrer-tracking/referrer-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class UsageTrackingServiceMock implements UsageTrackingSendImplementationService {
  constructor(private urlTracking: ReferrerTrackingService) {}
  public send(
    messageType: UsageTrackingMessageType,
    usageTracking: UsageTrackingTypes,
    anonymousGuid: string,
  ): void {
    // hint: this method will not be called by xguide UsageTrackingService if not allowed.
    usageTracking.anonymousGuid = anonymousGuid;
    usageTracking.referrer = this.urlTracking.getReferrer();
    console.log(`[Simulate UsageTracking] MessageType: ${messageType}`, usageTracking);
  }
}
