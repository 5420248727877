<div #header class="header">
{{ partName$ | async }}
</div>
<div class="details">
  <div [style.height.px]="modelContainerHeight" class="model-container">
    <div *ngIf="partStlModelUrl$ | async as partsStlModelUrl" class="model-box-container">
    {{ translations.FIXTURE_MATE.DETAILS_VIEW_PART | translate }}
    <lsb-cad-model-viewer
      [autoRotate]="false"
      [class.model-at-front]="true"
      [modelDataUri]="partsStlModelUrl"
      [rotatable]="true"
      [rotationSpeed]="1"
      [toolbar]="true"
      [zoomable]="true"
      class="model"

    ></lsb-cad-model-viewer>
    </div>
  </div>
  <div [style.height.px]="modelContainerHeight" class="model-container">
    <div *ngIf="fixtureStlModelUrl$ | async as fixtureStlModelUrl" class="model-box-container">
    {{ translations.FIXTURE_MATE.DETAILS_VIEW_FIXTURE | translate }}
    <lsb-cad-model-viewer
      [autoRotate]="false"
      [class.model-at-front]="true"
      [modelDataUri]="fixtureStlModelUrl"
      [rotatable]="true"
      [rotationSpeed]="1"
      [toolbar]="true"
      [zoomable]="true"
      class="model"
    ></lsb-cad-model-viewer>
    </div>
  </div>
</div>
<div #footer class="contact-footer">
  {{ translations.FIXTURE_MATE.DETAILS_VIEW_FOOTER | translate }}
  <a [href]="mailLink">
    <xui-button
      class="send-mail"
      type="trumpf-homepage"
      xuiClickUsageTracking="{{ uiElementIds.WELDING_PRINCIPLES_MAIL_TO }}"
    >{{ translations.FIXTURE_MATE.DETAILS_VIEW_CONTACT_BUTTON | translate }}</xui-button
    >
  </a>
</div>
